import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import PostLogin from '../../../layout/post-login'
import { ROUTES, COMMON } from '../../../constants'
import { useMuiSnackbar } from '../../../hooks'
import TaskService from '../../../services/task.service'
import { Title, TableWrapper, MuiChip } from '../../../components'
import { ListItemTextStyled } from './style'

const TaskViewPage = () => {
  const [taskData, setTaskData] = useState({})
  const [gridLoader, setGridLoader] = useState(true)
  const { id } = useParams()
  const { SnackbarError } = useMuiSnackbar()

  const tableData = {
    title: 'Task view',
    rows: taskData
  }

  useEffect(() => {
    TaskService.getOneTask(id)
      .then((resp) => {
        setTaskData(resp?.data)
        // enqueueSnackbar('Tutorial fetched successfully', {
        //   variant: 'success'
        // })
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }, [])

  return (
    <PostLogin heading='Task View' fixed>
      <Title
        primaryButton={{
          to: ROUTES.ROUTE_TASK_LIST,
          title: 'back',
          size: 'small'
        }}
      />

      <TableWrapper data={tableData} loader={gridLoader}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <ListItemTextStyled
              primary='task Name'
              secondary={taskData?.taskName}
            />
          </Grid>
          <Grid item md={6}>
            <ListItemTextStyled
              primary='description'
              secondary={taskData?.description}
            />
          </Grid>
          <Grid item md={4}>
            <ListItemTextStyled
              primary='assigned To'
              secondary={taskData?.assignedTo?.name || taskData?.assignedTo?.firstName}
            />
          </Grid>
          <Grid item md={4}>
            <ListItemTextStyled
              primary='due Date'
              secondary={COMMON?.FULL_DATE(taskData?.dueDate)}
            />
          </Grid>
          <Grid item md={4}>
            <ListItemTextStyled
              primary='due Time'
              secondary={
                taskData?.dueTime
              }
            />
          </Grid>
          <Grid item md={4}>
            <ListItemTextStyled
              primary='Remind Before'
              secondary={
                COMMON?.FULL_DATE(taskData?.remindBefore)
              }
            />
          </Grid>
          <Grid item md={4}>
            <ListItemTextStyled
              primary='status'
              secondary={
                <MuiChip
                  size='small'
                  color={taskData?.status === 'completed' ? 'success' : taskData?.status === 'open' ? 'error' : taskData?.status === 'inProgress' ? 'warning' : 'primary'}
                  label={COMMON?.VAL_TO_LABEL('TASK_STATUS', taskData, 'status')}
                />
              }
            />
          </Grid>
          <Grid item md={4}>
            <ListItemTextStyled
              primary='related To'
              secondary={taskData?.relatedTo?.name || taskData?.relatedTo?.firstName}
            />
          </Grid>
        </Grid>
      </TableWrapper>

    </PostLogin>
  )
}

export default TaskViewPage
