import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMuiSnackbar } from '../../../hooks'
import PostLogin from '../../../layout/post-login'
import StageService from '../../../services/stage.service'
import { ROUTES } from '../../../constants'
import { TableCell, TableRow } from '@mui/material'
import { Title, TableAction, Loader } from '../../../components/'
import { StyledAdd, TableWrapperStyled } from './style'
import departmentService from '../../../services/department.service'

const StageListPage = () => {
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()
  const [gridLoader, setGridLoader] = useState(true)
  const [selectedRow, setSelectedRow] = useState({})
  const [stageData, setStageData] = useState([])
  const [departmentFilter, setDepartmentFilter] = useState(localStorage.getItem('stage-department-filter'))
  const [departmentTabs, setDepartmentTabs] = useState([])

  const filterFunc = (arr) => {
    const filtered = arr.filter(f => departmentFilter !== 'all' ? f?.department?._id === departmentFilter : true)
    return filtered
  }

  const tableData = {
    title: 'Stage list',
    view: ROUTES.ROUTE_STAGE_VIEW,
    edit: ROUTES.ROUTE_STAGE_FORM,
    cols: [
      { title: 'name' },
      { title: 'order' },
      // { title: 'department' },
      { title: 'description' }
    ],
    rows: filterFunc(stageData),
    srNo: true
  }

  const departmentFilterHandler = (id) => {
    setDepartmentFilter(id)
    setDepartmentTabs(
      prev => prev.map((d) => {
        return {
          ...d,
          active: !!(id === (d?.value || 'all'))
        }
      })
    )
    localStorage.setItem('stage-department-filter', id)
  }

  const loadTutorialData = () => {
    StageService?.getStageList()
      .then(response => {
        setStageData(response?.data)
      })
      .catch(err => {
        SnackbarError(err)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }

  const confirmationDeleteSubmit = () => {
    StageService?.deleteStage(selectedRow?._id)
      .then(response => {
        loadTutorialData()
        SnackbarSuccess(selectedRow?.firstName || selectedRow?.name, 'deleted')
      })
      .catch((err) => {
        SnackbarError(err)
      })
  }

  useEffect(() => {
    departmentService.getDepartmentList()
      .then((resp) => {
        if (resp.data.length === 0) {
          SnackbarError('There are no Departments')
          return
        }
        const menu = resp.data.map((d, i) => {
          return {
            label: d.name,
            value: d._id,
            onClick: () => { departmentFilterHandler(d._id) }
          }
        })
        setDepartmentTabs(menu)
        departmentFilterHandler(!departmentFilter ? resp.data[0]?._id : departmentFilter)
      })
      .catch((error) => {
        SnackbarError(error)
      })
  }, [])

  useEffect(() => {
    loadTutorialData()
  }, [])

  return (
    <PostLogin heading='Stage' fixed>
      <Title
        tabs={departmentTabs}
        primaryButton={{
          startAdornment: <StyledAdd />,
          title: 'add',
          to: ROUTES.ROUTE_STAGE_FORM,
          size: 'small'
        }}
      />

      <Loader showLoader={gridLoader} animate/>
      <TableWrapperStyled data={tableData} loader={gridLoader}>
        {tableData.rows.map((row, i) => {
          return (
            <TableRow hover role='checkbox' key={row?._id}>
              <TableCell align='center'>{i + 1}</TableCell>
              <TableCell>{row?.name}</TableCell>
              <TableCell>{row?.order}</TableCell>
              {/* <TableCell>{row?.department?.name || '-'}</TableCell> */}
              <TableCell>{row?.description || '-'}</TableCell>
              <TableCell align='center'>
                <TableAction size='small' to={`${tableData?.view}/${row._id}`} title='View' type='view' />
                <TableAction size='small' to={`${tableData?.edit}/${row._id}`} title='Edit' type='edit' />
                <TableAction
                  size='small'
                  title='Delete'
                  message={`Are you sure you want to delete this Stage : ${selectedRow?.firstName || selectedRow?.name}?`}
                  row={row}
                  onClick={() => { setSelectedRow(row) }}
                  onSuccess={confirmationDeleteSubmit}
                  type='delete'
                />
              </TableCell>
            </TableRow>
          )
        })}
      </TableWrapperStyled>
    </PostLogin>
  )
}

StageListPage.propTypes = {
  setAgentDataPage: PropTypes.func,
  setAgentDataLimit: PropTypes.func,
  agentDataPage: PropTypes.func,
  agentDataLimit: PropTypes.func
}

StageListPage.displayName = 'ListPage'

export default StageListPage
