import React from 'react'
import { TodoList } from './list'
import { AddTodo } from './add'
import PostLogin from '../../layout/post-login'
import { useGlobalState } from '../../hooks'

const TodoIndex = () => {
  const { user } = useGlobalState()
  return (
    <PostLogin heading={'Todo page'} fixed>
      name - {user?.name}
      <AddTodo />
      <TodoList />
    </PostLogin>
  )
}

export default TodoIndex
