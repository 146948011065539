import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const PostLoginOuterWrapper = styled(Box)(({ theme }) => `
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;
  justify-content: space-between;
`)

export const LhsWrapper = styled(Box)(props => `
  display: flex;
  transition: ease-in-out .5s;
  width: ${props?.theme?.components?.MuiSideBar?.width};
  min-height: 100vh;
  overflow: unset;
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    z-index: 1200;
  }
`)

export const RhsWrapper = styled(Box)(({ theme }) => `
  // height: 100vh;
  transition: ease-in-out .5s;
  display: flex;
  width: calc(100% - ${theme?.components?.MuiSideBar?.width});
  flex-direction: column;
`)

export const ContentWrapper = styled(Box)(({ theme }) => `
  display: flex;
  // overflow-y: scroll;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  background: ${theme.palette.background.index};
  padding: 20px 36px;
  padding-top: 0;
`)
