import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import CandidateService from '../../../services/candidate.service'
import { useMuiSnackbar } from '../../../hooks'
import StageService from '../../../services/stage.service'
// import { Dialog as Modal } from '@mui/material'
import { TextDropdown, MuiModal, MuiButton, MuiTextField } from '../../../components'
import { DescriptionWrapper } from './style'

const Stages = ({ departmentQuery, onChange, row }) => {
  const { SnackbarError, SnackbarSuccess } = useMuiSnackbar()
  const [value, setValue] = useState(row?.stage?._id || row?.stage)
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(true)
  const [show, setShow] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [stageId, setStageId] = useState()
  const candidateId = (row?._id)
  // const [errors, setErrors] = useState({})
  const form = useRef()
  const oldData = row?.stage?._id || row?.stage

  const stageChange = (e) => {
    setShow(true)
    setValue(e)
    setStageId(e)
  }

  const closeModal = () => {
    setShow(false)
    setValue(oldData)
  }

  const stageLoad = () => {
    StageService.getAllStages({ departmentId: departmentQuery })
      .then((resp) => {
        const data = resp.data.map((d) => {
          return {
            label: d?.name,
            value: d?._id,
            isSubStage: d?.isSubStage,
            options: d?.options.map((da) => {
              return {
                parentId: da?.parentId,
                label: da?.name,
                isSubStage: true,
                value: da?._id
              }
            })
          }
        })
        setData(data)
      })
      .catch((error) => {
        SnackbarError(error)
      })
  }

  const handlerFeedbackEvent = (e) => {
    setFeedback(e.target.value)
  }

  const handlerSubmit = async (e) => {
    e.preventDefault()

    setLoader(true)
    const data = {
      feedback,
      stageId,
      emailDate: new Date(),
      type: 'stage'
    }
    try {
      const resp = await CandidateService.dropdownUpdate(data, candidateId)
      SnackbarSuccess('Stage', 'updated')
      onChange(resp?.data)
    } catch (err) {
      SnackbarError(err)
    } finally {
      setLoader(false)
      setShow(false)
    }
  }

  useEffect(() => {
    setLoader(false)
  }, [row])

  useEffect(() => {
    stageLoad()
  }, [location?.search])

  return (
    <>
      <TextDropdown
        value={value}
        placeholder='Stage'
        disabled={loader}
        data={data}
        onChange={(e) => { stageChange(e) }}
      />

        <MuiModal
          title='Stage changed feedback form'
          onClose={closeModal}
          open={show}
        >
          <form onSubmit={handlerSubmit} ref={form}>
            <DescriptionWrapper>
              <MuiTextField
                multiline={true}
                rows={4}
                maxRows={6}
                size='small'
                required
                margin='normal'
                name='feedback'
                placeholder='Feedback'
                onChange={handlerFeedbackEvent}
                type='text'
                value={feedback}
                variant='outlined'
              />
            </DescriptionWrapper>
            <MuiButton style={{ marginRight: '16px' }} size='small' color='primary' variant='outlined' type='button' onClick={closeModal}>Cancel</MuiButton>
            <MuiButton size='small' color='primary' variant='contained' type='submit'>Submit</MuiButton>
          </form>
        </MuiModal>
    </>
  )
}

Stages.propTypes = {
  row: PropTypes.object,
  onChange: PropTypes.func,
  loader: PropTypes.bool,
  departmentQuery: PropTypes.string
}

export default Stages
