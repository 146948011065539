import React, { useState, useRef, useEffect } from 'react'
import { useFormik } from 'formik'
import { Grid } from '@mui/material'
import * as Yup from 'yup'
import PostLogin from '../../../layout/post-login'
import CompanyService from '../../../services/company.service'
import { useMuiSnackbar } from '../../../hooks'
import { MuiTextField, MuiButton } from '../../../components/'
import { Form, GridWrapper } from './style'
import { ButtonWrapper } from '../../common/style'

const CompanyAddPage = () => {
  const form = useRef()
  const [isLoading, setIsLoading] = useState(true)
  const [initialVal, setInitialVal] = useState(
    {
      name: '',
      phone: '',
      email: '',
      website: '',
      linkedin: '',
      location: '',
      description: '',
      jobWebsite: ''
    }
  )
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()

  const validation = (values) => {
    const errors = {}
    // if (!values.title) {
    //   errors.title = 'Kindly Enter Your - title'
    // }
    return errors
  }

  const onSubmits = (data) => {
    setIsLoading(true)
    CompanyService.updateCompany(data)
      .then((resp) => {
        SnackbarSuccess('Company details', 'Updated')
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: initialVal,
    validationSchema: Yup.object({
      name: Yup.string().max(50),
      phone: Yup.string().max(25),
      email: Yup.string().max(50),
      website: Yup.string().max(150),
      linkedin: Yup.string().max(150),
      location: Yup.string().max(25),
      description: Yup.string().max(250),
      jobWebsite: Yup.string().max(250)
    }),
    validate: values => { validation(values) },
    onSubmit: values => {
      const updatedData = { ...values }
      onSubmits(updatedData)
    }
  })

  useEffect(() => {
    CompanyService.getOneCompany()
      .then((resp) => {
        setInitialVal(
          {
            name: resp.data.companyName,
            phone: resp.data.phone,
            email: resp.data.email,
            website: resp.data.website,
            linkedin: resp.data.linkedin,
            location: resp.data.location,
            description: resp.data.description,
            jobWebsite: resp.data.jobWebsite
          }
        )
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <PostLogin heading='Company Information' fixed>

      <Form onSubmit={formik.handleSubmit} ref={form}>
        <GridWrapper container>
          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.name && formik.errors.name
              )}
              fullWidth
              size='small'
              helperText={formik.touched.name && formik.errors.name}
              margin='normal'
              name='name'
              placeholder='Company Name'
              title='Company Name'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.name}
              variant='outlined'
            />
          </Grid>
          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.phone && formik.errors.phone
              )}
              fullWidth
              size='small'
              helperText={formik.touched.phone && formik.errors.phone}
              margin='normal'
              name='phone'
              placeholder='phone'
              title='phone'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.phone}
              variant='outlined'
            />
          </Grid>
          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.email && formik.errors.email
              )}
              fullWidth
              size='small'
              helperText={formik.touched.email && formik.errors.email}
              margin='normal'
              name='email'
              placeholder='email'
              title='email'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.email}
              variant='outlined'
            />
          </Grid>
          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.website && formik.errors.website
              )}
              fullWidth
              size='small'
              helperText={formik.touched.website && formik.errors.website}
              margin='normal'
              name='website'
              placeholder='website'
              title='website'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.website}
              variant='outlined'
            />
          </Grid>
          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.linkedin && formik.errors.linkedin
              )}
              fullWidth
              size='small'
              helperText={formik.touched.linkedin && formik.errors.linkedin}
              margin='normal'
              name='linkedin'
              placeholder='linkedin'
              title='linkedin'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.linkedin}
              variant='outlined'
            />
          </Grid>
          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.location && formik.errors.location
              )}
              fullWidth
              size='small'
              helperText={formik.touched.location && formik.errors.location}
              margin='normal'
              name='location'
              placeholder='location'
              title='location'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.location}
              variant='outlined'
            />
          </Grid>
          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.description && formik.errors.description
              )}
              fullWidth
              size='small'
              helperText={formik.touched.description && formik.errors.description}
              margin='normal'
              name='description'
              placeholder='description'
              title='description'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.description}
              variant='outlined'
            />
          </Grid>
          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.jobWebsite && formik.errors.jobWebsite
              )}
              fullWidth
              size='small'
              helperText={formik.touched.jobWebsite && formik.errors.jobWebsite}
              margin='normal'
              name='jobWebsite'
              placeholder='Job Website'
              title='Job Website'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.jobWebsite}
              variant='outlined'
            />
          </Grid>

          {/* <Grid item md={4}>
            <UploadFile
              fullWidth
              size='small'
              margin='normal'
              name='resume'
              placeholder='Kindly upload a PDF file, first browse it and then upload the file to complete the process'
              onChange={(d) => onChangeResume(d)}
              type='text'
              error={
                formik.touched.resume && 'Resume is required!'
              }
              value={formik.values.resume}
              variant='outlined'
            />
          </Grid> */}

        </GridWrapper>
        <ButtonWrapper>
          <MuiButton
            variant='contained'
            size='small'
            type='submit'
            isSubmitting={isLoading}
            onClick={() => formik.handleSubmit()}
          >
            Update
          </MuiButton>
        </ButtonWrapper>
      </Form>

    </PostLogin>
  )
}

export default CompanyAddPage
