import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ReasonService from '../../../services/reason.service'
import { useMuiSnackbar } from '../../../hooks'
import {
  MuiTextField,
  MuiButton
} from '../../../components/'
import { Grid } from '@mui/material'
import { Form, GridWrapper } from './style'

const ReasonAddPage = ({ reasonType, onSuccess, editData, onCancel }) => {
  const form = useRef()
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()

  const [isLoading, setIsLoading] = useState(false)
  const [mode, setMode] = useState('add')
  const [initialVal, setInitialVal] = useState({
    type: reasonType,
    description: editData?.description || ''
  })

  const validation = (values) => {
    const errors = {}
    if (!values.type) {
      errors.type = 'Kindly Enter Your - type'
    }
    return errors
  }

  const onSubmits = (data) => {
    setIsLoading(true)
    const api = mode === 'add' ? ReasonService.addReason(data) : ReasonService.updateReason(data, editData._id)
    api
      .then((resp) => {
        SnackbarSuccess('Reason', mode)
        onSuccess()
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setIsLoading(false)
        resetForm()
      })
  }

  const resetForm = () => {
    setInitialVal(prev => { return { ...prev, description: '' } })
    setMode('add')
    onCancel()
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: initialVal,
    validationSchema: Yup.object({
      type: Yup.string().max(25).required(),
      description: Yup.string().max(250).required()
    }),
    validate: values => validation(values),
    onSubmit: values => {
      const updatedData = { ...values }
      onSubmits(updatedData)
    }
  })

  const fieldStyle = {
    xs: mode === 'edit' ? 10 : 11
  }
  const buttonStyle = {
    xs: mode === 'edit' ? 2 : 1
  }

  useEffect(() => {
    setInitialVal(prev => {
      return {
        ...prev,
        description: ''
      }
    })
    setMode('add')
    resetForm()
  }, [reasonType])

  useEffect(() => {
    if (editData?._id) {
      setMode('edit')
    }
  }, [editData])

  useEffect(() => {
    setInitialVal(prev => {
      return {
        ...prev,
        description: editData?.description
      }
    })
  }, [editData])

  return (
    <Form onSubmit={formik.handleSubmit} ref={form}>
      <GridWrapper container>
        <Grid item {...fieldStyle}>
          {/*
            <MuiTextField
              error={Boolean(
                formik.touched.type && formik.errors.type
              )}
              size='small'
              required
              helperText={formik.touched.type && formik.errors.type}
              margin='normal'
              name='type'
              placeholder='type'
              title='type'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.type}
              variant='outlined'
            />
          */}
          <MuiTextField
            sx={{ marginBottom: 0 }}
            error={Boolean(
              formik.touched.description && formik.errors.description
            )}
            size='small'
            required
            autoFocus={mode === 'edit'}
            fullWidth
            helperText={formik.touched.description && formik.errors.description}
            margin='normal'
            name='description'
            placeholder='description'
            // title='description'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type='text'
            value={formik.values.description}
            variant='outlined'
          />
        </Grid>
        <Grid item {...buttonStyle}>
          {mode === 'edit' && <MuiButton
            variant='outlined'
            size='small'
            type='button'
            onClick={resetForm}
          >
            Cancel
          </MuiButton>}
          <MuiButton
            style={{ marginLeft: '8px' }}
            variant='contained'
            // fullWidth
            size='small'
            type='submit'
            disabled={isLoading}
            onClick={() => formik.handleSubmit()}
          >
            {mode === 'edit' ? 'Update' : 'Add'}
          </MuiButton>
        </Grid>
      </GridWrapper>
    </Form>
  )
}

ReasonAddPage.propTypes = {
  editData: PropTypes.object,
  reasonType: PropTypes.string,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func
}

ReasonAddPage.displayName = 'AddPage'

export default ReasonAddPage
