import React, { useEffect } from 'react'
// import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ROUTES } from '../constants'
import AuthService from '../services/auth.service'
import EventBus from '../common/EventBus'
import {
  Login,
  Signup,
  ForgotPassword,
  NotFound,
  InternalServer,

  Dashboard,
  Tutorial,
  Employee,
  Task,
  Department,
  Stage,
  Candidate,
  Location,
  TodoIndex,
  Job,
  Inbox,
  Company,
  Reason
} from '../pages'

const BackOfficeRouter = props => {
  // const [showModeratorBoard, setShowModeratorBoard] = useState(false)
  // const [showAdminBoard, setShowAdminBoard] = useState(false)
  // const [currentUser, setCurrentUser] = useState(undefined)

  useEffect(() => {
    const user = AuthService.getCurrentUser()
    if (user) {
      // setCurrentUser(user)
      // setShowModeratorBoard(user.roles.includes('ROLE_MODERATOR'))
      // setShowAdminBoard(user.roles.includes('ROLE_ADMIN'))
    }
    const logOut = () => {
      AuthService.logout()
      // setShowModeratorBoard(false)
      // setShowAdminBoard(false)
      // setCurrentUser(undefined)
    }

    EventBus.on('logout', () => {
      logOut()
    })

    return () => {
      EventBus.remove('logout')
    }
  }, [])

  return (
    <Router>
      <Routes>
        <Route path={ROUTES.ROUTE_INDEX} element={<Login />} />
        <Route path={ROUTES.ROUTE_LOGIN} element={<Login />} />
        <Route path={ROUTES.ROUTE_SIGNUP} element={<Signup />} />
        <Route path={ROUTES.ROUTE_FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.ROUTE_NOTFOUND} element={<NotFound />} />
        <Route path={ROUTES.ROUTE_SERVER} element={<InternalServer />} />

        <Route path={ROUTES.ROUTE_DASHBOARD} element={<Dashboard />} />

        <Route path={ROUTES.ROUTE_EMPLOYEE_LIST} element={<Employee.EmployeeListPage />} />
        <Route path={ROUTES.ROUTE_EMPLOYEE_FORM} element={<Employee.EmployeeAddPage />} />
        <Route path={`${ROUTES.ROUTE_EMPLOYEE_FORM}/:id`} element={<Employee.EmployeeAddPage />} />
        <Route path={`${ROUTES.ROUTE_EMPLOYEE_VIEW}/:id`} element={<Employee.EmployeeViewPage />} />

        <Route path={ROUTES.ROUTE_TUTORIAL_LIST} element={<Tutorial.TutorialListPage />} />
        <Route path={ROUTES.ROUTE_TUTORIAL_FORM} element={<Tutorial.TutorialAddPage />} />
        <Route path={`${ROUTES.ROUTE_TUTORIAL_FORM}/:id`} element={<Tutorial.TutorialAddPage />} />
        <Route path={`${ROUTES.ROUTE_TUTORIAL_VIEW}/:id`} element={<Tutorial.TutorialViewPage />} />

        <Route path={ROUTES.ROUTE_TODO_LIST} element={<TodoIndex />} />
        {/* <Route path={ROUTES.ROUTE_TODO_LIST} element={<Todo.TodoList />} />
        <Route path={ROUTES.ROUTE_TODO_FORM} element={<Todo.AddTodo />} />
        <Route path={`${ROUTES.ROUTE_TODO_FORM}/:id`} element={<Todo.AddTodo />} /> */}

        <Route path={ROUTES.ROUTE_TASK_LIST} element={<Task.TaskListPage />} />
        <Route path={ROUTES.ROUTE_TASK_FORM} element={<Task.TaskAddPage />} />
        <Route path={`${ROUTES.ROUTE_TASK_FORM}/:id`} element={<Task.TaskAddPage />} />
        <Route path={`${ROUTES.ROUTE_TASK_VIEW}/:id`} element={<Task.TaskViewPage />} />

        <Route path={ROUTES.ROUTE_DEPARTMENT_LIST} element={<Department.DepartmentListPage />} />
        <Route path={ROUTES.ROUTE_DEPARTMENT_FORM} element={<Department.DepartmentAddPage />} />
        <Route path={`${ROUTES.ROUTE_DEPARTMENT_FORM}/:id`} element={<Department.DepartmentAddPage />} />
        <Route path={`${ROUTES.ROUTE_DEPARTMENT_VIEW}/:id`} element={<Department.DepartmentViewPage />} />

        <Route path={ROUTES.ROUTE_STAGE_LIST} element={<Stage.StageListPage />} />
        <Route path={ROUTES.ROUTE_STAGE_FORM} element={<Stage.StageAddPage />} />
        <Route path={`${ROUTES.ROUTE_STAGE_FORM}/:id`} element={<Stage.StageAddPage />} />
        <Route path={`${ROUTES.ROUTE_STAGE_VIEW}/:id`} element={<Stage.StageViewPage />} />

        <Route path={ROUTES.ROUTE_LOCATION_LIST} element={<Location.LocationListPage />} />
        <Route path={ROUTES.ROUTE_LOCATION_FORM} element={<Location.LocationAddPage />} />
        <Route path={`${ROUTES.ROUTE_LOCATION_FORM}/:id`} element={<Location.LocationAddPage />} />
        <Route path={`${ROUTES.ROUTE_LOCATION_VIEW}/:id`} element={<Location.LocationViewPage />} />

        <Route path={ROUTES.ROUTE_JOB_LIST} element={<Job.JobListPage />} />
        <Route path={ROUTES.ROUTE_JOB_FORM} element={<Job.JobAddPage />} />
        <Route path={`${ROUTES.ROUTE_JOB_FORM}/:id`} element={<Job.JobAddPage />} />
        <Route path={`${ROUTES.ROUTE_JOB_VIEW}/:id`} element={<Job.JobViewPage />} />

        <Route path={ROUTES.ROUTE_CANDIDATE_LIST} element={<Candidate.CandidateListPage />} />
        <Route path={ROUTES.ROUTE_CANDIDATE_FORM} element={<Candidate.CandidateAddPage />} />
        <Route path={`${ROUTES.ROUTE_CANDIDATE_FORM}/:id`} element={<Candidate.CandidateAddPage />} />
        <Route path={`${ROUTES.ROUTE_CANDIDATE_FORM}?:id`} element={<Candidate.CandidateAddPage />} />
        <Route path={`${ROUTES.ROUTE_CANDIDATE_VIEW}/:id`} element={<Candidate.CandidateViewPage />} />
        <Route path={`${ROUTES.ROUTE_CANDIDATE_VIEW}?:id`} element={<Candidate.CandidateViewPage />} />

        <Route path={ROUTES.ROUTE_INBOX_LIST} element={<Inbox.InboxListPage />} />
        <Route path={ROUTES.ROUTE_INBOX_FORM} element={<Inbox.InboxAddPage />} />
        <Route path={`${ROUTES.ROUTE_INBOX_FORM}/:id`} element={<Inbox.InboxAddPage />} />
        <Route path={`${ROUTES.ROUTE_INBOX_VIEW}/:id`} element={<Inbox.InboxViewPage />} />

        <Route path={ROUTES.ROUTE_COMPANY_FORM} element={<Company.CompanyAddPage />} />

        <Route path={ROUTES.ROUTE_REASON_LIST} element={<Reason.ReasonListPage />} />
        <Route path={ROUTES.ROUTE_NOTFOUND_404} element={<NotFound />} />
      </Routes>
    </Router>
  )
}

export default BackOfficeRouter
