import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { Editor } from '@tinymce/tinymce-react'
import { LabelWrapper, FormLabel } from '../common/style'
import { RteWrapper, FormError } from './style'

const Rte = ({ title, error, value, customToolbar, onChange, contentStyle, height = 250, pastAsText = false, ...props }) => {
  const ref = useRef(value)
  const handleEditorChange = (newValue) => {
    onChange(newValue)
  }
  // const toolbar = customToolbar || 'h4 | bold italic underline strikethrough | hr | subscript superscript | bullist indent outdent | removeformat undo redo | link '

  return (
    <Box sx={{ px: '8px' }}>
      {title && <LabelWrapper><FormLabel>{title}</FormLabel></LabelWrapper>}
      <RteWrapper errorflag={error}>
        <Editor
          apiKey='p3nbk0l216z1hnrgrff12wjhce2q80g3vda5k5g5zyr0lx84'
          initialValue={ref.current}
          value={/* ref?.current ||  */value}
          init={{
            height,
            menubar: false,
            content_style: contentStyle,
            paste_as_text: pastAsText
          }}
          onEditorChange={handleEditorChange}
          outputFormat='html'
          selector={'textarea'}
          plugins={'lists'}
          // toolbar={toolbar}
          toolbar={`
            h2 h3 h4 h5 |
            bullist numlist indent outdent |
            bold italic underline strikethrough |
            alignleft aligncenter alignright alignjustify |
            superscript subscript |
            removeformat undo redo |
            link
          `}
          default_link_target='_blank'
        />
      </RteWrapper>
      {error && <FormError>{error}</FormError>}
    </Box>
  )
}

Rte.propTypes = {
  title: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  customToolbar: PropTypes.string,
  onChange: PropTypes.func,
  contentStyle: PropTypes.string,
  height: PropTypes.number,
  pastAsText: PropTypes.bool
}

export default Rte
