import React, { useState } from 'react'
import { Switch, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { styled } from '@mui/system'
import { FormLabel, FormError, FormTypo } from './style'

const MuiSwitch = (props) => {
  return (
    <>
      {props?.title && <FormLabel>{props.title}</FormLabel>}
      {props?.label && <SwitchButton {...props} />}
      {props?.error && <FormError>{props?.error}</FormError>}
    </>
  )
}

export default MuiSwitch

const SwitchButton = (props) => {
  const {
    label,
    labelRight = null,
    value,
    name,
    onChange,
    size = 'small',
    direction = 'row',
    type = 'default'
  } = props

  const [val, setVal] = useState(value)
  const sz = (size === 'small')

  const handleChange = () => {
    setVal(!value)
    onChange(!value)
  }

  const IOSSwitch = styled(Switch)(({ theme }) => ({
    width: sz ? 28 : 42,
    height: sz ? 16 : 26,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)'
      }
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
        }
      }
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: sz ? 12 : 22,
      height: sz ? 12 : 22,
      borderRadius: '50%',
      transition: theme.transitions.create(['width'], {
        duration: 200
      })
    },
    '& .MuiSwitch-track': {
      borderRadius: (sz ? 16 : 26) / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box'
    }
  }))

  return (
    <>
      <Stack direction={direction} spacing={1} alignItems='center'>
        {label && <FormTypo>{label}</FormTypo>}
        {type === 'default' && <Switch onChange={handleChange} name={name} size={size} checked={val} inputProps={{ 'aria-label': 'default design' }} />}
        {type === 'ios' && <IOSSwitch onChange={handleChange} name={name} size={size} checked={val} inputProps={{ 'aria-label': 'ant design' }} />}
        {labelRight && <FormTypo>{labelRight}</FormTypo>}
      </Stack>
    </>
  )
}

MuiSwitch.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string
}

SwitchButton.propTypes = {
  label: PropTypes.string,
  labelRight: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  direction: PropTypes.string,
  type: PropTypes.bool
}
