import React from 'react'
import { useGlobalState } from '../../hooks'

export const AddTodo = () => {
  const { addTodoItem } = useGlobalState()
  const [inputValue, setInputValue] = React.useState('')

  const addTodoItemFunc = () => {
    addTodoItem({
      id: new Date().valueOf(),
      label: inputValue,
      completed: false
    })
    setInputValue('')
  }

  return (
      <>
        <input type="text" value={inputValue} placeholder={'Type and add todo item'} onChange={(e) => setInputValue(e?.target?.value)} />
        <button onClick={addTodoItemFunc}>Add</button>
      </>
  )
}
