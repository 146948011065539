import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Box } from '@mui/material'
import PostLogin from '../../../layout/post-login'
import { ROUTES, COMMON } from '../../../constants'

import { useMuiSnackbar } from '../../../hooks'
import JobService from '../../../services/job.service'
import { Loader, Title, MuiButton } from '../../../components'
import { Heading, ListItemTextStyled, ViewBox, ViewWrapper } from './style'
import { ButtonWrapper } from '../../common/style'

const JobViewPage = () => {
  const [jobData, setJobData] = useState({})
  const [gridLoader, setGridLoader] = useState(true)
  const { id } = useParams()
  const { SnackbarError } = useMuiSnackbar()

  useEffect(() => {
    JobService.getOneJob(id)
      .then((resp) => {
        setJobData(resp?.data)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }, [])

  return (
    <PostLogin heading='Job View'>
      <Title
        primaryButton={{
          to: ROUTES.ROUTE_JOB_LIST,
          title: 'back',
          size: 'small'
        }}
      />

      <ViewWrapper container spacing={2}>
        <Grid item md={8}>
          <ViewBox>
            <Loader showLoader={gridLoader} showMessage={(jobData?.length === 0 && !gridLoader) && true} message='No data found!' />
            <Heading color='primary'>
              {jobData?.heading}
            </Heading>
            <ListItemTextStyled
              sx={{ flex: 'none' }}
              primary='job Description'
            />
            <Box
              dangerouslySetInnerHTML={{ __html: jobData?.jobDescription || '-' }}
            />
          </ViewBox>
        </Grid>
        <Grid item md={4}>
          <ViewBox>
            <Loader showLoader={gridLoader} showMessage={(jobData?.length === 0 && !gridLoader) && true} message='No data found!' />
            <ListItemTextStyled
              primary='department Name'
              secondary={jobData?.department?.name || '-'}
            />
            <ListItemTextStyled
              primary='job Type'
              secondary={COMMON.VAL_TO_LABEL('JOB_TYPE', jobData, 'jobType') || '-'}
            />
            <ListItemTextStyled
              primary='job Openings'
              secondary={jobData?.jobOpenings || '-'}
            />
            <ListItemTextStyled
              primary='location Name'
              secondary={jobData?.location?.name || '-'}
            />
            <ListItemTextStyled
              primary='currency'
              secondary={COMMON.VAL_TO_LABEL('JOB_CURRENCY', jobData, 'currency')?.label || '-'}
            />
            <ListItemTextStyled
              primary='min Salary'
              secondary={jobData?.minSalary || '-'}
            />
            <ListItemTextStyled
              primary='max Salary'
              secondary={jobData?.maxSalary || '-'}
            />
            <ListItemTextStyled
              primary='publish Type'
              secondary={COMMON.VAL_TO_LABEL('JOB_PUBLISH', jobData, 'publishType') || '-'}
            />
            <ListItemTextStyled
              primary='skills'
              secondary={jobData?.skills || '-'}
            />
            <ListItemTextStyled
              primary='experience'
              secondary={jobData?.experience || '-'}
            />
          </ViewBox>
        </Grid>
        <Grid item md={12}>
          <ViewBox>
            <ButtonWrapper>
              <MuiButton
                size='small'
                type='button'
                variant='outlined'
                style={{ marginRight: '16px' }}
                to={ROUTES.ROUTE_JOB_LIST}
              >
                Back
              </MuiButton>
              <MuiButton
                size='small'
                type='button'
                isSubmitting={gridLoader}
                variant='contained'
                to={`${ROUTES.ROUTE_JOB_FORM}/${id}`}
              >
                Edit
              </MuiButton>
            </ButtonWrapper>
          </ViewBox>
        </Grid>
      </ViewWrapper>
    </PostLogin>
  )
}

export default JobViewPage
