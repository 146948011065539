import { styled, Box, ListItemText } from '@mui/material'

export const DescriptionWrapper = styled(Box)(`
  width: 100%;
  min-width:450px;
  & > div {
    padding:0;
  }
`)

export const ButtonWrapper = styled(Box)(props => `
`)

export const CardTableContent = styled(ListItemText)(props => `
  text-transform: capitalize;
  padding-bottom:20px;
  display:flex;
  flex-direction:column;
  > span {
    position: relative;
    width: fit-content;
    color: ${props?.theme?.palette?.primary?.main};
    font-size: 14px;
    font-weight: 400;
  }
  > p {
    font-size: 14px;
    color: #6c757d;
    font-weight: 400;
    line-height: 1.5;
  }
`)

export const ScheduleAnInterviewWrapper = styled(Box)(props => `
  & > div {
    padding-left:0;
    padding-right:0;
    margin:0;
    width:100%;
  }
`)

export const InterviewerList = styled(Box)(props => `
  padding:0 8px;
  margin:0 0 20px 0;
  & > div {
    margin-right: 6px;
  }
`)
