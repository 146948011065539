import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import CandidateService from '../../../services/candidate.service'
import ReasonService from '../../../services/reason.service'
import { useMuiSnackbar } from '../../../hooks'
// import StageService from '../../../services/stage.service'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { MuiModal, MuiButton, MuiSelect, MuiTextField } from '../../../components'
import { DescriptionWrapper } from './style'
import { CANDIDATE_TYPE } from '../../../constants/common'

const CandidateType = ({ onClose, onChange, row }) => {
  const { SnackbarError, SnackbarSuccess } = useMuiSnackbar()
  const [isLoading, setIsLoading] = useState(true)
  // const [show, setShow] = useState(false)
  const [departmentData, setDepartmentData] = useState([])
  const [jobData, setJobData] = useState([])
  const candidateId = (row?._id)
  const form = useRef()
  const [initialVal, setInitialVal] = useState({
    candidateRejected: '',
    offerDeclined: '',
    feedback: ''
  })

  const stageChange = (e) => {
    // setShow(true)
  }

  const validation = (values) => {
    const errors = {}
    return errors
  }

  const onSubmits = async (data) => {
    setIsLoading(true)
    const finalData = {
      candidateRejected: data.candidateRejected,
      offerDeclined: data.offerDeclined,
      feedback: data.feedback,
      candidateId,
      type: 'candidateType'
    }
    await CandidateService.dropdownUpdate(finalData, candidateId)
      .then((resp) => {
        SnackbarSuccess('Candidate Type', 'updated')
        onChange(resp?.data)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: initialVal,
    validationSchema: Yup.object({
      feedback: Yup.string().required()
    }),
    validate: values => validation(values),
    onSubmit: values => {
      const updatedData = values
      onSubmits(updatedData)
    }
  })

  const getLoadData = async () => {
    setIsLoading(true)
    ReasonService?.getReasonList()
      .then(resp => {
        const typeArr = []
        const menu = []
        for (let m = 0; m < resp?.data?.length; m++) {
          if (typeArr.indexOf(resp?.data[m].type) === -1) {
            typeArr.push(resp?.data[m].type.trim())
          }
        }
        menu[0] = resp?.data.filter((m1) => m1.type === typeArr[0])
        menu[1] = resp?.data.filter((m1) => m1.type === typeArr[1])

        setDepartmentData(menu[0])
        setJobData(menu[1])

        setInitialVal(
          {
            candidateRejected: menu[0],
            offerDeclined: menu[1],
            feedback: ''
          }
        )
      })
      .catch(err => {
        SnackbarError(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onSelectChange = (e) => {
    // console.log('onSelectChange------------')
  }

  useEffect(() => {
    setIsLoading(false)
  }, [row])

  useEffect(() => {
    if (departmentData?.length === 0 && jobData?.length === 0 && row.candidateType === 'active') {
      getLoadData()
    }
  }, [])

  return (
    <>
      <MuiButton onClick={stageChange}>
        {row?.candidateType === CANDIDATE_TYPE[0].value ? CANDIDATE_TYPE[1].label : CANDIDATE_TYPE[0].label}
      </MuiButton>

      <MuiModal
        title='Candidate changed feedback form'
        onClose={onClose}
        open={true}
      >
        <form onSubmit={formik.handleSubmit} ref={form}>
          {row.candidateType === 'active' && departmentData && <DescriptionWrapper>
            <MuiSelect
              name='candidateRejected'
              placeholder='Candidate Rejected'
              title='Candidate Rejected'
              onChange={formik.handleChange}
              fullWidth
              onSelectChange={onSelectChange}
              autoFocus
              size='small'
              data={departmentData}
              selectlabel='description'
              selectvalue='_id'
              error={Boolean(
                formik.touched.candidateRejected && formik.errors.candidateRejected
              )}
              value={formik?.values?.candidateRejected || ''}
            />
          </DescriptionWrapper>}

          {row.candidateType === 'active' && jobData && <DescriptionWrapper>
            <MuiSelect
              name='offerDeclined'
              placeholder='Offer Declined'
              title='Offer Declined'
              onChange={formik.handleChange}
              fullWidth
              size='small'
              data={jobData}
              selectlabel='description'
              selectvalue='_id'
              error={Boolean(
                formik.touched.offerDeclined && formik.errors.offerDeclined
              )}
              value={formik?.values?.offerDeclined || ''}
            />
          </DescriptionWrapper>}

          <DescriptionWrapper>
            <MuiTextField
              multiline={true}
              rows={4}
              maxRows={6}
              size='small'
              required
              margin='normal'
              name='feedback'
              placeholder='Feedback'
              title='Feedback'
              onChange={formik.handleChange}
              type='text'
              value={formik?.values?.feedback || ''}
              variant='outlined'
            />
          </DescriptionWrapper>

          <MuiButton style={{ marginRight: '16px' }} size='small' color='primary' variant='outlined' type='button' onClick={onClose}>Cancel</MuiButton>
          <MuiButton variant='contained' size='small' type='submit' isSubmitting={isLoading} onClick={() => formik.handleSubmit()}>Submit</MuiButton>
        </form>
      </MuiModal>
    </>
  )
}

CandidateType.propTypes = {
  row: PropTypes.object,
  onChange: PropTypes.func,
  loader: PropTypes.bool,
  onClose: PropTypes.func,
  departmentQuery: PropTypes.string
}

export default CandidateType
