import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Grid, Chip } from '@mui/material'
import CandidateService from '../../../services/candidate.service'
import EmployeeService from '../../../services/employee.service'
import TaskService from '../../../services/task.service'
import { useMuiSnackbar, useGlobalState } from '../../../hooks'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { MuiSelect, MuiModal, MuiButton, Rte, MuiDatePicker } from '../../../components'
import { timePickerArray } from '../../../constants/common'
import { CardTableContent, ScheduleAnInterviewWrapper, InterviewerList } from './style'

const ScheduleAnInterview = ({ onClose, onChange, row }) => {
  const { SnackbarError, SnackbarSuccess } = useMuiSnackbar()
  // const [value, setValue] = useState(row?.stage?._id || row?.stage)
  // const [data, setData] = useState([])
  const form = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [interviewers, setInterviewer] = useState([])
  const [mailContent, setMailContent] = useState()
  const [interviewerList, setInterviewerList] = useState([])
  const { user } = useGlobalState()

  const initialVal = {
    interviewDate: '',
    interviewTime: '',
    // mailContent: '',
    interviewers: ''
  }
  const candidateId = (row?._id)

  const validation = (values) => {
    const errors = {}
    return errors
  }

  const onSubmits = (data) => {
    setIsLoading(true)
    CandidateService.scheduleInterview(data, candidateId)
      .then((resp) => {
        SnackbarSuccess('Interview Schedured', 'updated')
        onChange(resp?.data)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: initialVal,
    validationSchema: Yup.object({
      interviewDate: Yup.string().max(30).required(),
      interviewTime: Yup.string().max(30).required(),
      mailContent: Yup.string().max(30).required()
      // interviewers: Yup.string().max(30).required()
    }),
    validate: values => validation(values),
    onSubmit: values => {
      const updatedData = { ...values, mailContent, stage: row.stage._id, interviewers: interviewerList.map(v => v.value) }
      onSubmits(updatedData)
    }
  })

  const onInterviewersChange = (d) => {
    setInterviewerList(d)
  }

  useEffect(() => {
    EmployeeService.getOneEmployee(user?.id)
      .then((resp) => {
        setMailContent(`Hi ${row?.firstName} ${row?.lastName},<br/><br/><br/>${resp.data?.signature}`)
      })
      .catch((error) => {
        SnackbarError(error)
      })
  }, [])

  useEffect(() => {
    TaskService.getEmployees()
      .then((resp) => {
        setInterviewer(
          resp?.data?.map((d) => {
            return {
              label: d?.firstName || d?.name,
              value: d?._id
            }
          })
        )
      })
      .catch((error) => {
        SnackbarError(error)
      })
  }, [])

  return (
    <>
        <MuiModal
          title='Schedule an Interview'
          onClose={onClose}
          open={true}
          width={'800px'}
        >
          <form onSubmit={formik.handleSubmit} ref={form}>
            <ScheduleAnInterviewWrapper>
              <Grid container spacing={2}>
                <Grid md={12}>
                  <CardTableContent primary={`Candidate Name: ${row?.firstName} ${row?.lastName}`} secondary={`Position: ${row?.jobId?.heading}`}/>
                </Grid>
                <Grid md={6}>
                  <MuiDatePicker
                    error={Boolean(
                      formik.touched.interviewDate && formik.errors.interviewDate
                    )}
                    pickerType='date'
                    fullWidth
                    name='interviewDate'
                    value={formik?.values?.interviewDate ? new Date(formik.values.interviewDate) : ''}
                    // onChange={formik.handleChange}
                    onChange={(value) => formik.setFieldValue('interviewDate', value.toISOString())}
                    placeholder='Interview Date'
                    title='Interview Date'
                    label=''
                    helperText={formik.touched.interviewDate && formik.errors.interviewDate}
                    margin='normal'
                    onBlur={formik.handleBlur}
                    type='text'
                    variant='outlined'
                  />
                </Grid>
                <Grid md={6}>
                  <MuiSelect
                    data={timePickerArray(30, [8, 20])}
                    fullWidth
                    margin='normal'
                    type='text'
                    variant='outlined'
                    selectlabel='label'
                    selectvalue='value'
                    required
                    error={Boolean(formik.touched.interviewTime && formik.errors.interviewTime)}
                    helperText={formik.touched.interviewTime && formik.errors.interviewTime}
                    onChange={formik.handleChange}
                    placeholder='Interview Time'
                    title='Interview Time'
                    name='interviewTime'
                    value={formik.values.interviewTime}
                    size='small'
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid md={12}>
                  {interviewers && <AddInterviewers interviewers={interviewers} interviewerList={interviewerList} onChange={(list) => onInterviewersChange(list)} />}
                </Grid>
                <Grid md={12}>
                  <Rte
                    required
                    fullWidth
                    size='small'
                    margin='normal'
                    name='mailContent'
                    placeholder='Additional mail content'
                    title='Additional mail content'
                    onBlur={formik.handleBlur}
                    onChange={e => setMailContent(e) }
                    type='text'
                    value={mailContent}
                  />
                </Grid>
              </Grid>
            </ScheduleAnInterviewWrapper>

            <MuiButton style={{ marginRight: '16px' }} size='small' color='primary' variant='outlined' type='button' onClick={() => onClose()}>Cancel</MuiButton>
            <MuiButton color='primary' variant='contained' size='small' type='submit' disabled={isLoading} onClick={() => formik.handleSubmit()}>Submit</MuiButton>
          </form>
        </MuiModal>
    </>
  )
}

ScheduleAnInterview.propTypes = {
  row: PropTypes.object,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  loader: PropTypes.bool,
  departmentQuery: PropTypes.string
}

export default ScheduleAnInterview

const AddInterviewers = ({ interviewers, interviewerList, onChange }) => {
  const addInterviewerFunc = (rowId) => {
    if (rowId !== '' && interviewerList.filter(i => i.value === rowId).length === 0) {
      onChange([...interviewerList, interviewers.find(i => i.value === rowId)])
    }
  }

  const handleDelete = (rowId) => {
    onChange(interviewerList.filter(i => i.value !== rowId))
  }

  return (
    <>
      <MuiSelect
        name='interviewers1'
        placeholder='Interviewer'
        title='Interviewer'
        onChange={(e, row) => addInterviewerFunc(row?.value || '')}
        fullWidth
        size='small'
        data={interviewers || []}
        selectlabel='label'
        selectvalue='value'
        value={'Select'}
      />
      <InterviewerList>
          {interviewerList?.map((list, i) => {
            return (
              <Chip key={i} label={list.label} variant="outlined" onDelete={() => handleDelete(list.value)} />
            )
          })}
      </InterviewerList>
    </>
  )
}

AddInterviewers.propTypes = {
  formik: PropTypes.func,
  interviewers: PropTypes.object,
  interviewerList: PropTypes.object,
  onChange: PropTypes.func
}
