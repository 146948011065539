import React from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'
import PropTypes from 'prop-types'
import { FormError, CheckBoxWrapper } from './style'
import { FormLabel } from '../common/style'

const MuiCheckbox = (props) => {
  return (
    <CheckBoxWrapper>
      {props?.title && <FormLabel>{props.title}</FormLabel>}
      <CheckBoxButton {...props} />
      {props?.error && <FormError>{props?.error}</FormError>}
    </CheckBoxWrapper>
  )
}

export default MuiCheckbox

const CheckBoxButton = (props) => {
  const {
    name,
    label,
    onChange,
    size,
    value
  } = props

  const handleChange = (event) => {
    const selectedData = { value: event.target.value, label: event.target.name }
    onChange(event, selectedData)
  }

  return (
    <FormControlLabel {...props} name={name} onChange={handleChange} size={size} control={<Checkbox defaultValue={value} />} label={label} />
  )
}

MuiCheckbox.propTypes = {
  title: PropTypes.string,
  value: PropTypes.bool,
  error: PropTypes.string
}

CheckBoxButton.propTypes = {
  name: PropTypes.string, // need to complete
  label: PropTypes.string, // need to complete
  onChange: PropTypes.func, // need to complete
  size: PropTypes.string, // need to complete
  value: PropTypes.bool || PropTypes.string // need to complete
}
