import React, { useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import PropTypes from 'prop-types'
import MuiTextField from '../muiTextField'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import {
  Box
  // Checkbox,
  // FormControlLabel
} from '@mui/material'
// import { LabelWrapper, FormLabel } from '../common/style'

const IconCalendar = () => {
  return (
    <Box sx={{ position: 'absolute', right: '15px' }}>
      {/* <MuiImage src={CalendarMonthIcon} /> */}
      <CalendarMonthIcon />
    </Box>
  )
}

const MuiDatePicker = ({ title, label, value, placeholder, name, error, format = 'DD MMMM YYYY', ...rest }) => {
  const [open, setOpen] = useState(false)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        label={label}
        open={open}
        onClose={() => setOpen(false)}
        inputFormat={format}
        ignoreInvalidInputs={true}
        placeholder={placeholder}
        value={value || null}
        renderInput={(params) => {
          delete params.label
          return <MuiTextField
            {...params}
            error={error}
            helperText={error}
            title={title}
            name={name}
            onClick={() => setOpen(true)}
            inputProps={{
              ...params.inputProps,
              placeholder,
              readOnly: true,
              endAdornment: <IconCalendar />
            }}
          />
        }}
        {...rest}
      />
    </LocalizationProvider>
  )
}

MuiDatePicker.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.string,
  format: PropTypes.string
}

export default MuiDatePicker
