import React, { useRef, useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import AuthService from '../../services/auth.service'
import * as Yup from 'yup'
import PreLogin from '../../layout/pre-login'
import { Box, Checkbox } from '@mui/material'
import { MuiTextField, MuiButton } from '../../components'
import { LinkStyleSmall } from './style'
import { LinkStyled, FormControlLabelStyled } from '../../layout/pre-login/style'

import { ROUTES } from '../../constants'
import Cookies from 'universal-cookie'
import { useGlobalState } from '../../hooks'
import { getRememberUser, rememberMe, removeRememberMe, removeUserDetails } from '../../util'

const Login = (props) => {
  const history = useNavigate()
  const form = useRef()
  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuery()
  const cookies = new Cookies()
  const { enqueueSnackbar } = useSnackbar()
  const { setUser, setToken } = useGlobalState()
  const [isLoading, setIsLoading] = useState(false)

  // const rememberMe = () => {
  //   formik.values.remember = !formik.values.remember
  //   formik.values.remember
  //     ? cookies.set('login', formik.values, { path: '/' })
  //     : cookies.remove('login', formik.values)
  // }

  const onSubmits = (data, isRemember) => {
    setIsLoading(true)
    AuthService.loginApi(data)
      .then((resp) => {
        setUser(resp)
        setToken(resp?.accessToken)
        enqueueSnackbar('Logged in Successfully', {
          variant: 'success'
        })
        // formik.values.remember
        //   ? cookies.set('login', formik.values, { path: '/' })
        //   : cookies.remove('login', formik.values)

        if (isRemember) {
          rememberMe({ email: data.email, password: data.password })
        } else {
          removeRememberMe()
        }

        // setTimeout(() => {
        history(
          query.get('to') || `${ROUTES.ROUTE_DASHBOARD}`
        )
        // }, 1000)
      })
      .catch((error) => {
        console.error(`ERROR- Login:  ${error}`)
        enqueueSnackbar(`Error: ${error?.response?.data?.message || error?.response?.data?.error || 'Connection timed out'}`, { variant: 'error' })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const validation = (values) => {
    const errors = {}
    if (!values.password) {
      // errors.password = 'Kindly Enter Your - password'
    }
    return errors
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      email: cookies?.get('userEmail') || '',
      password: cookies?.get('userPassword') || '',
      // remember: cookies?.get('login') !== undefined,
      rememberMe: false
    },
    validationSchema: Yup.object({
      email: Yup.string().max(30).required(),
      // companyName: Yup.string().max(25).required(),
      password: Yup.string().max(25).required()
    }),
    validate: (values) => validation(values),
    onSubmit: (values) => {
      const updatedValues = { ...values }
      const isRemember = updatedValues.rememberMe
      delete updatedValues.rememberMe
      onSubmits(updatedValues, isRemember)
    }
  })

  useEffect(() => {
    removeUserDetails()
    const rememberData = getRememberUser()
    if (rememberData?.userName && rememberData?.password) {
      formik.setValues({
        email: rememberData.userName,
        password: rememberData.password,
        rememberMe: true
      })
    }
  }, [])

  const Description = () => {
    return <Box style={{ display: 'none' }}>Dont have account yet? <LinkStyled to='/signup'>Sign up</LinkStyled></Box>
  }

  return (
    <PreLogin
      logo
      heading1={'Login to Your Account'}
      belowDescription={<Description />}
    >
      <form onSubmit={formik.handleSubmit} ref={form}>
        <Box sx={{ mx: '-8px' }}>
          <MuiTextField
            sx={{ margin: '10px 0px' }}
            fullWidth
            error={Boolean(
              formik.touched.email && formik.errors.email
            )}
            helperText={formik.touched.email && formik.errors.email}
            onChange={formik.handleChange}
            bold
            autoFocus
            placeholder='Email'
            title='Email'
            name='email'
            type='email'
            size='small'
            value={formik.values.email}
            onBlur={formik.handleBlur}
          />

          <MuiTextField
            sx={{ margin: '10px 0px ' }}
            fullWidth
            error={Boolean(formik.touched.password && formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            onChange={formik.handleChange}
            bold
            placeholder='Password'
            title='Password'
            name='password'
            type='password'
            size='small'
            value={formik.values.password}
            onBlur={formik.handleBlur}
            endAdornment={
              <LinkStyleSmall to='/forgotpassword'>I forgot password</LinkStyleSmall>
            }
          />
        </Box>
        <FormControlLabelStyled
          margin='dense'
          value='end'
          control={
            <Checkbox
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values.rememberMe}
              onClick={rememberMe}
            />
          }
          label='remember me'
          labelPlacement='end'
        />

        <MuiButton
          variant='contained'
          fullWidth
          size='xSmall'
          type='submit'
          isSubmitting={isLoading}
          onClick={() => formik.handleSubmit()}
        >
          click to login
        </MuiButton>
      </form>
    </PreLogin>
  )
}

export default Login
