// import axios from 'axios'
import { postLogin } from '../util/axios'

const API_NAME = 'employees'
const API_URL = `${process.env.REACT_APP_API_URL}`

const getEmployeeList = (id) => {
  const url = `${API_URL}${API_NAME}`
  return postLogin.get(url)
}

const getOneEmployee = (id) => {
  const url = `${API_URL}${API_NAME}/${id}`
  return postLogin.get(url)
}

const addEmployee = (data) => {
  const url = `${API_URL}${API_NAME}/`
  return postLogin.post(url, data)
}

const updateEmployee = (data, id) => {
  const url = `${API_URL}${API_NAME}/${id}`
  return postLogin.put(url, data)
}

const deleteEmployee = (id) => {
  const url = `${API_URL}${API_NAME}/${id}`
  return postLogin.delete(url)
}

export default {
  getEmployeeList,
  addEmployee,
  getOneEmployee,
  updateEmployee,
  deleteEmployee
}
