import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PostLogin from '../../../layout/post-login'
import EmployeeService from '../../../services/employee.service'
import { ROUTES, COMMON } from '../../../constants'
import { TableCell, TableRow } from '@mui/material'
import { Title, Stats, TableWrapper, TableAction, Loader } from '../../../components/'
import { useMuiSnackbar } from '../../../hooks'
import { StyledAdd } from './style'
import { Wrapper } from '../../common/style'

const EmployeeListPage = () => {
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()
  const [selectedRow, setSelectedRow] = useState({})
  const [gridLoader, setGridLoader] = useState(true)
  const [employeeData, setEmployeeData] = useState([])

  const statsData = [
    {
      title: 'total employees',
      count: employeeData?.length
    },
    {
      title: 'admin employees',
      count: employeeData.filter(d => d.role === 'admin').length
    },
    {
      title: 'Male Employees',
      count: 120
    },
    {
      title: 'Female Employees',
      count: 120
    }
  ]

  const tableData = {
    title: 'employee list',
    view: ROUTES.ROUTE_EMPLOYEE_VIEW,
    edit: ROUTES.ROUTE_EMPLOYEE_FORM,
    cols: [
      { title: 'first name' },
      { title: 'last name' },
      { title: 'Email' },
      { title: 'role' }
    ],
    rows: employeeData,
    srNo: true
  }

  const loadTutorialData = () => {
    EmployeeService?.getEmployeeList()
      .then(response => {
        setEmployeeData(response?.data?.reverse())
      })
      .catch(err => {
        SnackbarError(err)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }

  const confirmationDeleteSubmit = (id) => {
    EmployeeService?.deleteEmployee(selectedRow?._id)
      .then(response => {
        loadTutorialData()
        SnackbarSuccess(selectedRow?.firstName || selectedRow?.name, 'deleted')
      })
      .catch((err) => {
        SnackbarError(err)
      })
  }

  useEffect(() => {
    loadTutorialData()
  }, [])

  return (
    <PostLogin heading='Employee' fixed>
      <Title
        // tabs={[
        //   { label: 'List View', active: true },
        //   { label: 'Grid View' }
        // ]}
        primaryButton={{
          startAdornment: <StyledAdd />,
          title: 'add',
          to: ROUTES.ROUTE_EMPLOYEE_FORM,
          size: 'small'
        }}
      />
      <Loader showLoader={gridLoader} animate/>
      <Wrapper loader={gridLoader}>
        {!!statsData?.length && <Stats data={statsData} loader={gridLoader} style={{ display: 'none' }} />}

        <TableWrapper data={tableData} loader={gridLoader}>
          {tableData.rows.map((row, i) => {
            return (
              <TableRow hover role='checkbox' key={row._id}>
                <TableCell align='center'>{i + 1}</TableCell>
                <TableCell>{row.firstName || row.name}</TableCell>
                <TableCell>{row.lastName || '-'}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{COMMON?.VAL_TO_LABEL('ROLE', row, 'role')}</TableCell>
                <TableCell align='right'>
                  <TableAction size='small' to={`${tableData?.view}/${row._id}`} title='View' type='view' />
                  <TableAction size='small' to={`${tableData?.edit}/${row._id}`} title='Edit' type='edit' />
                  <TableAction
                    size='small'
                    title='Delete'
                    message={`Are you sure you want to delete this Employee : ${selectedRow?.firstName || selectedRow?.name}?`}
                    row={row}
                    onClick={() => { setSelectedRow(row) }}
                    onSuccess={confirmationDeleteSubmit}
                    type='delete'
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableWrapper>
      </Wrapper>
    </PostLogin>
  )
}

EmployeeListPage.propTypes = {
  setAgentDataPage: PropTypes.func,
  setAgentDataLimit: PropTypes.func,
  agentDataPage: PropTypes.func,
  agentDataLimit: PropTypes.func
}

EmployeeListPage.displayName = 'ListPage'

export default EmployeeListPage
