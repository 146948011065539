import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableRow } from '@mui/material'
import PostLogin from '../../../layout/post-login'
import LocationService from '../../../services/location.service'
import { ROUTES } from '../../../constants'
import { useMuiSnackbar } from '../../../hooks'
import { Title, TableWrapper, TableAction, Loader } from '../../../components/'
import { StyledAdd } from './style'
import { Wrapper } from '../../common/style'

const LocationListPage = () => {
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()
  const [gridLoader, setGridLoader] = useState(true)
  const [selectedRow, setSelectedRow] = useState({})
  // const [openConfirmation, setConfirmation] = useState(false)
  const [locationData, setLocationData] = useState([])

  const tableData = {
    title: 'location list',
    view: ROUTES.ROUTE_LOCATION_VIEW,
    edit: ROUTES.ROUTE_LOCATION_FORM,
    cols: [
      { title: 'name' },
      { title: 'description' }
    ],
    rows: locationData,
    srNo: true
  }

  const loadTutorialData = () => {
    LocationService?.getLocationList()
      .then(response => {
        setLocationData(response?.data?.reverse())
      })
      .catch(err => {
        SnackbarError(err)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }

  const confirmationDeleteSubmit = () => {
    LocationService?.deleteLocation(selectedRow?._id)
      .then(response => {
        loadTutorialData()
        SnackbarSuccess(selectedRow?.firstName || selectedRow?.name, 'deleted')
      })
      .catch((err) => {
        SnackbarError(err)
      })
  }

  useEffect(() => {
    loadTutorialData()
  }, [])

  return (
    <PostLogin heading='Location' fixed>
      <Title
        primaryButton={{
          startAdornment: <StyledAdd />,
          title: 'add',
          to: ROUTES.ROUTE_LOCATION_FORM,
          size: 'small'
        }}
      />

      <Loader showLoader={gridLoader} animate/>
      <Wrapper loader={gridLoader}>
        <TableWrapper data={tableData} loader={gridLoader}>
          {tableData.rows.map((row, i) => {
            return (
              <TableRow hover role='checkbox' key={row._id}>
                <TableCell align='center'>{i + 1}</TableCell>
                <TableCell>{row?.name}</TableCell>
                <TableCell>{row?.description}</TableCell>
                <TableCell align='right'>
                  <TableAction size='small' to={`${tableData?.view}/${row._id}`} />
                  <TableAction size='small' to={`${tableData?.edit}/${row._id}`} title='Edit' type='edit' />
                  <TableAction
                    size='small'
                    title='Delete'
                    message={`Are you sure you want to delete this Location : ${selectedRow?.firstName || selectedRow?.name}?`}
                    row={row}
                    onClick={() => { setSelectedRow(row) }}
                    onSuccess={confirmationDeleteSubmit}
                    type='delete'
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableWrapper>
      </Wrapper>
    </PostLogin>
  )
}

LocationListPage.propTypes = {
  setAgentDataPage: PropTypes.func,
  setAgentDataLimit: PropTypes.func,
  agentDataPage: PropTypes.func,
  agentDataLimit: PropTypes.func
}

LocationListPage.displayName = 'ListPage'

export default LocationListPage
