import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PostLogin from '../../../layout/post-login'
import EmployeeService from '../../../services/employee.service'
import { Grid } from '@mui/material'
import { useMuiSnackbar } from '../../../hooks'
import { ROUTES } from '../../../constants'
import { MuiSelect, MuiTextField, Title, MuiButton, Rte } from '../../../components/'

import { Form, GridWrapper } from './style'
import { ButtonWrapper } from '../../common/style'

const EmployeeAddPage = () => {
  // const form = useRef()
  const { SnackbarError } = useMuiSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [mode, setMode] = useState('add')
  const [showTab, setShowTab] = useState(0)
  const [employeeData, setEmployeeData] = useState(null)
  const { id } = useParams()

  useEffect(() => {
    if (id?.length) {
      setMode('edit')
      setIsLoading(true)
      EmployeeService.getOneEmployee(id)
        .then((resp) => {
          const resData = resp.data
          delete resData._id
          delete resData.name
          delete resData.createdAt
          delete resData.updatedAt
          delete resData.updatedBy
          delete resData.__v

          setEmployeeData(resData)
        })
        .catch((error) => {
          SnackbarError(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [])

  return (
    <PostLogin heading={`Employee ${id?.length ? 'Edit' : 'Add'}`} fixed>
      <Title
        tabs={ id?.length
          ? [
              {
                label: 'General Details',
                active: showTab === 0,
                onClick: () => {
                  setShowTab(0)
                },
                to: ''
              },
              {
                label: 'Signature',
                active: showTab === 1,
                onClick: () => {
                  setShowTab(1)
                },
                to: ''
              }
            ]
          : []}
        primaryButton={{
          title: 'back',
          to: ROUTES.ROUTE_EMPLOYEE_LIST,
          size: 'small'
        }}
      />

      {!isLoading && showTab === 0 && <EmployeeForm1 data={{ ...employeeData }} mode={mode} id={id} />}
      {!isLoading && showTab === 1 && id?.length && <EmployeeForm2 data={{ ...employeeData }} mode={mode} id={id} />}

    </PostLogin>
  )
}

export default EmployeeAddPage

const EmployeeForm1 = ({ data = null, mode, id }) => {
  const history = useNavigate()
  const form = useRef()
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [initialVal, setInitialVal] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: ''
  })

  const roles = [
    { label: 'Admin', value: 'admin' },
    { label: 'Employee', value: 'employee' },
    { label: 'User', value: 'user' }
  ]

  const validation = (values) => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = 'Kindly Enter Your - firstName'
    }
    return errors
  }

  const onSubmits = (data) => {
    setIsLoading(true)
    const api = mode === 'add'
      ? EmployeeService.addEmployee(data)
      : EmployeeService.updateEmployee(data, id)
    api
      .then((resp) => {
        history(ROUTES.ROUTE_EMPLOYEE_LIST)
        SnackbarSuccess('Employee', mode)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: initialVal,
    validationSchema: Yup.object({
      firstName: Yup.string().max(25).required(),
      lastName: Yup.string().max(25),
      email: Yup.string().max(30).required(),
      password: !id?.length ? Yup.string().max(30).required() : Yup.string(),
      role: Yup.string().max(30).required()
    }),
    validate: values => validation(values),
    onSubmit: values => {
      const updatedData = { ...data, ...values }
      onSubmits(updatedData)
    }
  })

  useEffect(() => {
    // setEmployeeData(data)
    setInitialVal({
      firstName: data?.firstName || data?.name,
      lastName: data?.lastName,
      email: data?.email,
      role: data?.role
    })
  }, [data])

  return (
    <Form onSubmit={formik.handleSubmit} ref={form}>
      <GridWrapper container>
        <Grid item xs={12}>
          <MuiTextField
            error={Boolean(
              formik.touched.firstName && formik.errors.firstName
            )}
            fullWidth
            size='small'
            required
            helperText={formik.touched.firstName && formik.errors.firstName}
            margin='normal'
            name='firstName'
            placeholder='First Name'
            title='First Name'
            autoFocus
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type='text'
            value={formik.values.firstName}
            variant='outlined'
          />
        </Grid>

        <Grid item xs={6}>
          <MuiTextField
            error={Boolean(
              formik.touched.lastName && formik.errors.lastName
            )}
            fullWidth
            size='small'
            helperText={formik.touched.lastName && formik.errors.lastName}
            margin='normal'
            name='lastName'
            placeholder='Last Name'
            title='Last Name'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type='text'
            value={formik.values.lastName}
            variant='outlined'
          />
        </Grid >

        <Grid item xs={6}>
          <MuiTextField
            error={Boolean(
              formik.touched.email && formik.errors.email
            )}
            fullWidth
            size='small'
            required
            helperText={formik.touched.email && formik.errors.email}
            margin='normal'
            name='email'
            placeholder='Official Email'
            title='Official Email'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type='text'
            value={formik.values.email}
            variant='outlined'
          />
        </Grid>

        {!id?.length &&
          <Grid item xs={4}>
            <MuiTextField
              fullWidth
              error={Boolean(formik.touched.password && formik.errors.password)}
              required
              helperText={formik.touched.password && formik.errors.password}
              onChange={formik.handleChange}
              placeholder='Password'
              title='Password'
              name='password'
              type='password'
              size='small'
              value={formik.values.password}
              onBlur={formik.handleBlur}
            />
          </Grid >
        }

        <Grid item xs={4}>
          <MuiSelect
            name='role'
            title='role'
            placeholder='Role'
            onChange={formik.handleChange}
            fullWidth
            size='small'
            error={Boolean(
              formik.touched.role && formik.errors.role
            )}
            data={roles}
            selectlabel='label'
            selectvalue='value'
            required
            helperText={formik.touched.role && formik.errors.role}
            value={formik.values.role}
            onBlur={formik.handleBlur}
          />
        </Grid>
      </GridWrapper>
      <ButtonWrapper>
        <MuiButton
          variant='contained'
          size='small'
          type='submit'
          disabled={isLoading}
          onClick={() => formik.handleSubmit()}
        >
          {id?.length ? 'Update' : 'Add'}
        </MuiButton>
      </ButtonWrapper>
    </Form>
  )
}

EmployeeForm1.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.string,
  id: PropTypes.string
}

const EmployeeForm2 = ({ data = null, mode, id }) => {
  const history = useNavigate()
  const form = useRef()
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [signatureData, setSignatureData] = useState()
  const [initialVal, setInitialVal] = useState({ signature: '' })

  const validation = (values) => {
    const errors = {}
    return errors
  }

  const onSubmits = (data) => {
    setIsLoading(true)
    const api = mode === 'add'
      ? EmployeeService.addEmployee(data)
      : EmployeeService.updateEmployee(data, id)
    api
      .then((resp) => {
        history(ROUTES.ROUTE_EMPLOYEE_LIST)
        SnackbarSuccess('Employee', mode)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: initialVal,
    validationSchema: Yup.object({
      signature: Yup.string()
    }),
    validate: values => validation(values),
    onSubmit: values => {
      const updatedData = { ...data, signature: signatureData }
      onSubmits(updatedData)
    }
  })

  useEffect(() => {
    setSignatureData(data?.signature)
  }, [])

  useEffect(() => {
    setInitialVal({
      signature: data?.signature
    })
  }, [data])

  return (
    <Form onSubmit={formik.handleSubmit} ref={form}>
      <GridWrapper container>
        <Grid item xs={12}>
          <Rte
            required
            error={formik.errors.signature}
            title='Signature'
            placeholder='Signature'
            name='signature'
            onBlur={formik.handleBlur}
            value={signatureData}
            onChange={e => setSignatureData(e)}
            height={250}
          />
        </Grid>
      </GridWrapper>
      <ButtonWrapper>
        <MuiButton
          variant='contained'
          size='small'
          type='submit'
          disabled={isLoading}
          onClick={() => formik.handleSubmit()}
        >
          {id?.length ? 'Update' : 'Add'}
        </MuiButton>
      </ButtonWrapper>
    </Form>
  )
}

EmployeeForm2.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.string,
  id: PropTypes.string
}
