import {
  styled,
  Typography,
  Grid
  // Box
} from '@mui/material'

export const Upside = styled(Typography)(`
  text-transform: capitaliz;
  display: flex;
  align-items: center;
`)

export const Form = styled('form')(`
  background: white;
  padding: 20px;
  border-radius: 10px;
`)

export const GridWrapper = styled(Grid)(`
  width: calc(100% + 16px);
  margin: 0 -8px;
`)
