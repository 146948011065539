import { styled, ListItemText } from '@mui/material'

export const ListItemTextStyled = styled(ListItemText)(`
  > span {
    text-transform: capitalize;
    position: relative;
    width: fit-content;
    &:before{
      content:': ';
      position: absolute;
      right: -5px;
      top: 0;
    }
  }
`)
