import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ROUTES } from '../../../constants'
import { TableCell, TableRow, Tooltip, IconButton, Badge } from '@mui/material'
import CandidateService from '../../../services/candidate.service'
import StageService from '../../../services/stage.service'
import { useMuiSnackbar, useGlobalState } from '../../../hooks'
import PostLogin from '../../../layout/post-login'
import { Title, TableWrapper, TableAction, Rating, MuiSwitch, Dropdown } from '../../../components/'
import Stages from '../components/stage'
import EmployeeService from '../../../services/employee.service'
import OwnedBy from '../components/ownedBy'
import CandidateType from '../components/candidateType'
import ScheduleAnInterview from '../components/scheduleAnInterview'
import { CANDIDATE_TYPE } from '../../../constants/common'
import InboxAddPage from '../../inbox/add'
import JobDetail from './jobDetails'
import { FileDownloadOutlined, MoreVertOutlined } from '@mui/icons-material/'

import {
  LinkStyled,
  StyledAdd,
  MuiSwitchWrapper,
  DropDownLink
} from './style'

const CandidateListPage = () => {
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()
  const location = useLocation()
  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuery()
  const history = useNavigate()
  const [candidateData, setCandidateData] = useState([])
  const [departmentQuery, setDepartmentQuery] = useState(query.get('departmentId'))
  const [jobQuery, setJobQuery] = useState(query.get('jobId'))
  const [gridLoader, setGridLoader] = useState(true)
  const [stageFilter, setStageFilter] = useState(/* localStorage.getItem('candidate-stage-filter') || */ location?.hash.slice(1) || '')
  const [bread, setBread] = useState({})
  const [candidateType, setCandidateType] = useState(true)
  const [stageLoader, setStageLoader] = useState(true)
  const [ownerLoader, setOwnerLoader] = useState(true)
  const [stagesTabs, setStagesTabs] = useState([])
  const [dropBox, setDropBox] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [renderList, setRenderList] = useState()
  const [tableData, setTableData] = useState()
  const [eventType, setEventType] = useState()
  const [signature, setSignature] = useState()
  const { user } = useGlobalState()
  const titleSecondaryButton = (departmentQuery || jobQuery)
    ? { title: 'back', size: 'small', onClick: () => { history(-1) } }
    : null

  const active = CANDIDATE_TYPE[0]
  const archived = CANDIDATE_TYPE[1]

  const filterFunc = (arr) => {
    const filtered = arr
    // ?.filter(f => departmentQuery ? f?.departmentId?._id === departmentQuery : true)
    // ?.filter(f => jobQuery ? f?.jobId?._id === jobQuery : true)
    const finalData = []
    if (stageFilter !== 'all') {
      for (let i = 0; i < filtered.length; i++) {
        const selectedStage = stagesTabs.filter(s => s.value === stageFilter)
        const selectedStageOptions = [selectedStage[0].value, ...selectedStage[0]?.options.map(o => o.value)]
        if (selectedStageOptions.includes(filtered[i].stage?._id)) {
          finalData.push(filtered[i])
        }
      }
    }

    return finalData
  }

  const loadData = (type = active?.value) => {
    const data = { candidateType: type, department: departmentQuery, job: jobQuery }
    CandidateService.getCandidateList(data)
      .then(res => {
        const data = res?.data?.reverse()
        setCandidateData(data)
        setBread(data)
      })
      .catch(err => {
        SnackbarError(err)
      })
      .finally(() => {
        setGridLoader(false)
        setStageLoader(false)
        setOwnerLoader(false)
      })
  }

  const stageFilterHandler = (id) => {
    setStageFilter(id)
    const holder = prev => prev.map((d) => {
      return {
        ...d,
        active: !!(id === (d?.value))
      }
    })
    setStagesTabs(prev =>
      holder(prev)
    )
  }

  const handleRatingClicked = async (value, index, row) => {
    try {
      const data = { rating: value, type: 'rating' }
      await CandidateService.dropdownUpdate(data, row?._id)
      SnackbarSuccess('Rating', 'updated')
    } catch (err) {
      SnackbarError(err)
    }
  }

  const loadStages = () => {
    StageService.getAllStages({ departmentId: departmentQuery })
      .then((resp) => {
        const data = resp.data.map((d) => {
          const GetTabCount = () => {
            const allObjIds = [d._id, ...d.options.map(m => m._id)]
            const count = candidateData?.filter(f => allObjIds?.includes(f?.stage?._id))
            return (
              <>{d?.name} <Badge className='badge' badgeContent={`${count?.length}`} color='info' /></>
            )
          }
          return {
            label: <GetTabCount />,
            value: d?._id,
            isSubStage: d?.isSubStage,
            active: stageFilter === d?._id,
            onClick: () => { stageFilterHandler(d?._id) },
            options: d?.options.map((da) => {
              return {
                parentId: da?.parentId,
                label: da?.name,
                isSubStage: true,
                value: da?._id
              }
            })
          }
        })
        setStagesTabs(prev => {
          return [
            ...data
          ]
        })
        !stageFilter && stageFilterHandler(data[0].value)
      })
      .catch((error) => {
        SnackbarError(error)
      })
  }

  const breadcrumbs = [
    {
      title: 'Job',
      to: ROUTES.ROUTE_JOB_LIST
    },
    {
      title: 'Candidates',
      to: ROUTES.ROUTE_CANDIDATE_LIST
    },
    {
      title: (departmentQuery && bread?.[0]?.departmentId?.name) && bread?.[0]?.departmentId?.name,
      to: `${ROUTES.ROUTE_CANDIDATE_LIST}?departmentId=${departmentQuery}`
    },
    {
      title: (jobQuery && bread?.[0]?.jobId?.heading) && bread?.[0]?.jobId?.heading,
      to: `${ROUTES.ROUTE_CANDIDATE_LIST}?jobId=${jobQuery}`
    }
  ]

  const candidateTypeChanged = () => {
    loadData((!candidateType)
      ? active?.value
      : archived?.value
    )
    setCandidateType(!candidateType)
  }

  const dropdownEvent = (row, event) => {
    !dropBox ? setDropBox({ row: row?._id, pos: event?.currentTarget }) : setDropBox(null)
  }

  const ActiveButton = () => {
    return (
      <MuiSwitchWrapper>
        <MuiSwitch size='medium' label={candidateType ? active?.label : archived?.label} name='candidateType' onChange={candidateTypeChanged} value={candidateType} />
      </MuiSwitchWrapper>
    )
  }

  const activateAction = (e, row) => {
    e.preventDefault()
    setSelectedRow(row)
    setEventType('archiving')
  }

  const sendMail = (e, row) => {
    e.preventDefault()
    setSelectedRow(row)
    setEventType('sendMail')
  }

  const candidateTypeSubmit = (data) => {
    setSelectedRow(null)
    setCandidateData(data)
    setBread(data)

    loadData(active?.value)
    setCandidateType(active?.value)
  }

  const scheduleAnInterview = (e, row) => {
    e.preventDefault()
    setSelectedRow(row)
    setEventType('scheduleAnInterview')
  }

  const scheduleAnInterviewSubmit = (data) => {
    setSelectedRow(null)
    // setCandidateData(data)
    // setBread(data)
  }

  useEffect(() => {
    if (departmentQuery && jobQuery && candidateType && candidateData.length > 0 && stageFilter.length > 0) {
      const tableData1 = {
        title: '',
        view: ROUTES.ROUTE_CANDIDATE_VIEW,
        edit: ROUTES.ROUTE_CANDIDATE_FORM,
        cols: [
          { title: 'full Name' },
          { title: 'email' },
          { title: 'stage' },
          { title: 'owned By' },
          { title: 'department' },
          { title: 'rating' },
          { title: 'job opening' },
          { title: 'resume' }
        ],
        srNo: true,
        rows: filterFunc(candidateData)
      }

      departmentQuery && tableData1.cols.find((f, i) => {
        return f.title === 'department' && tableData1.cols.splice(i, 1)
      })

      jobQuery && tableData1.cols.find((f, i) => {
        return f.title === 'job opening' && tableData1.cols.splice(i, 1)
      })

      // const renderList = candidateType ? tableData1?.rows : candidateData
      setRenderList(candidateType ? tableData1?.rows : candidateData)
      setTableData(tableData1)
    }
  }, [candidateData, stagesTabs, candidateType, stageFilter, departmentQuery, jobQuery])

  useEffect(() => {
    loadStages()
  }, [candidateData])

  useEffect(() => {
    EmployeeService.getOneEmployee(user?.id)
      .then((resp) => {
        setSignature(resp.data?.signature)
      })
      .catch((error) => {
        SnackbarError(error)
      })
  }, [])

  useEffect(() => {
    setDepartmentQuery(query.get('departmentId'))
    setJobQuery(query.get('jobId'))
  }, [location?.search])

  useEffect(() => {
    loadData()
  }, [])

  // const StageDropDown = ({ row }) => {
  //   // console.log('StageDropDown --------- NEED TO WORK ON THIS AND STOP MULTIPLE RE-RENDERING')
  //   return <Stages reload row={row} loader={stageLoader} onChange={data => { loadData() }} departmentQuery={departmentQuery} />
  // }

  return (
    <PostLogin
      heading='Candidate'
      breadcrumbs={breadcrumbs}
      fixed
    >
      <Title
        tabs={candidateType && stagesTabs}
        primaryButton={{
          startAdornment: <StyledAdd />,
          title: 'add',
          to: `${ROUTES.ROUTE_CANDIDATE_FORM}?${departmentQuery ? `departmentId=${departmentQuery}` : ''}${(jobQuery && departmentQuery) ? `&jobId=${jobQuery}` : ''}`,
          size: 'small'
        }}
        secondaryButton={titleSecondaryButton}
        restbutton={<ActiveButton />}
      />

      {(!gridLoader && jobQuery) && <JobDetail jobId={jobQuery} />}

      {/* {!!renderList?.length && <TableWrapper data={candidateType ? tableData : { ...tableData, rows: candidateData }} loader={gridLoader} tableStyling='candidateStyle'> */}
      <TableWrapper data={tableData} loader={gridLoader} tableStyling='candidateStyle'>
        {renderList?.map((row, i) => {
          return (
            <TableRow hover role='checkbox' key={row?._id}>
              <TableCell align='center'>{i + 1}</TableCell>
              <TableCell>
                <LinkStyled border to={`${tableData?.view}/${row._id}`}>
                  {row?.firstName} {row?.lastName}
                </LinkStyled>
              </TableCell>
              <TableCell>{row?.email}</TableCell>
              <TableCell>
                {candidateType
                  ? <Stages reload row={row} loader={stageLoader} onChange={data => { loadData() }} departmentQuery={departmentQuery} />
                  : row?.stage?.name
                }
              </TableCell>

              <TableCell>
                {candidateType
                  ? <OwnedBy row={row} loader={ownerLoader} />
                  : row?.ownedBy?.name || row?.ownedBy?.firstName
                }
              </TableCell>

              {!departmentQuery &&
                <TableCell>
                  <LinkStyled border to={`${location?.pathname}${location?.search}&departmentId=${row?.departmentId?._id}`}>
                    {row?.departmentId?.name || '-'}
                  </LinkStyled>
                </TableCell>
              }
              <TableCell>
                <Rating
                  initialValue={row?.rating}
                  onClick={candidateType && ((value, index) => handleRatingClicked(value, index, row))}
                  readonly={!candidateType}
                />
              </TableCell>
              {!jobQuery &&
                <TableCell>
                  <LinkStyled border to={`${location?.pathname}${location?.search}&jobId=${row?.jobId?._id}`}>
                    {row?.jobId?.heading || row?.jobId?._id || '-'}
                  </LinkStyled>
                </TableCell>
              }
              <TableCell>
                <Tooltip title='Download'>
                  <a
                    target='_blank'
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_PDF_URL}${row?.resume?.fileName}`}
                    download={`${process.env.REACT_APP_PDF_URL}${row?.resume?.fileName}`}
                  >
                    <IconButton>
                      <FileDownloadOutlined color='primary' sx={{ fontSize: '18px' }} />
                    </IconButton>
                  </a>
                </Tooltip>
              </TableCell>
              <TableCell align='right'>
                <TableAction size='small' to={`${tableData?.view}/${row._id}`} title='View' type='view' />
                {candidateType && <TableAction size='small' to={`${tableData?.edit}/${row._id}`} title='Edit' type='edit' />}
                <TableAction
                  size='small'
                  // title='More Options'
                  onClick={e => dropdownEvent(row, e)}
                  type={<MoreVertOutlined sx={{ fontSize: '15px' }} />}
                >
                  {candidateType && <Dropdown pos={row?._id === dropBox?.row && dropBox?.pos} onClose={() => setDropBox(null)}>
                    <DropDownLink onClick={e => scheduleAnInterview(e, row)}>Schedule an Interview</DropDownLink>
                    <DropDownLink onClick={e => activateAction(e, row)}>{CANDIDATE_TYPE[1].label}</DropDownLink>
                    <DropDownLink onClick={e => sendMail(e, row)}>Send Mail</DropDownLink>
                  </Dropdown>}
                  {!candidateType && <Dropdown pos={row?._id === dropBox?.row && dropBox?.pos} onClose={() => setDropBox(null)}>
                    <DropDownLink onClick={e => activateAction(e, row)}>{CANDIDATE_TYPE[0].label}</DropDownLink>
                  </Dropdown>}
                </TableAction>
              </TableCell>
            </TableRow>
          )
        })}
      </TableWrapper>
      {/* {!!renderList?.length && tableData && } */}

      {eventType === 'archiving' && selectedRow && <CandidateType row={selectedRow} onChange={candidateTypeSubmit} onClose={() => setSelectedRow(null)} />}
      {eventType === 'scheduleAnInterview' && selectedRow && <ScheduleAnInterview row={selectedRow} onChange={scheduleAnInterviewSubmit} onClose={() => setSelectedRow(null)} />}
      {eventType === 'sendMail' && selectedRow && <InboxAddPage data={{ signature, ...selectedRow }} showOnload={true} onClose={() => setSelectedRow(null)} />}

    </PostLogin>
  )
}

CandidateListPage.propTypes = {
  setAgentDataPage: PropTypes.func,
  setAgentDataLimit: PropTypes.func,
  agentDataPage: PropTypes.func,
  agentDataLimit: PropTypes.func
}

CandidateListPage.displayName = 'Candidate List'

export default CandidateListPage
