import React, { useRef, useState } from 'react'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { Box, Checkbox } from '@mui/material'
import { MuiButton, MuiTextField } from '../../components'
import AuthService from '../../services/auth.service'
import PreLogin from '../../layout/pre-login'

import { LinkStyled, FormControlLabelStyled, BelowButton } from '../../layout/pre-login/style'

const title = 'Sign Up'

const Signup = () => {
  const history = useNavigate()
  const form = useRef()
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = (data) => {
    setIsLoading(true)
    AuthService.registerApi(data)
      .then((resp) => {
        enqueueSnackbar('Registered Successfully', {
          variant: 'success'
        })
        history('/login')
      })
      .catch((error) => {
        console.error('ERROR: ', error)
        enqueueSnackbar(`Error: ${error?.response?.data?.message !== undefined ? error?.response?.data?.message : 'Connection timed out'}`, { variant: 'error' })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const validation = (values) => {
    const errors = {}
    if (!values.password) {
      errors.password = 'Kindly Enter Your password'
    }
    return errors
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: '',
      email: '',
      password: '',
      companyName: ''
      // phone: ''
    },
    validationSchema: Yup.object({
      companyName: Yup
        .string()
        .max(25)
        .required('Company Name is required'),
      email: Yup
        .string()
        .email('Must be a valid email')
        .max(30)
        .required('Email is required'),
      name: Yup
        .string()
        .max(25)
        .required('Name is required'),
      password: Yup
        .string()
        .max(25)
        .required('Password is required')
    }),
    validate: (values) => validation(values),
    onSubmit: (values) => {
      const updatedValues = { ...values }
      onSubmit(updatedValues)
    }
  })
  return (
    <PreLogin
      heading1='Create New Account'
      logo
    >
      <form ref={form} onSubmit={formik.handleSubmit}>

        <Box sx={{ mx: '-8px' }}>
          <MuiTextField
            fullWidth
            error={Boolean(formik.touched.name && formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            placeholder='enter name'
            title='Name'
            name='name'
            type='text'
            bold
            size='small'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name}
          />

          <MuiTextField
            helperText={formik.touched.email && formik.errors.email}
            error={Boolean(formik.touched.email && formik.errors.email)}
            fullWidth
            bold
            placeholder='enter email'
            title='Email Address'
            name='email'
            type='email'
            size='small'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
          />

          {/* <MuiTextField
          helperText={formik.touched.phone && formik.errors.phone}
          error={Boolean(formik.touched.phone && formik.errors.phone)}
          fullWidth
          placeholder='enter phone'
          title='phone'
          name='phone'
          type='tel'
          size='small'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.phone}
        /> */}

          <MuiTextField
            fullWidth
            error={Boolean(formik.touched.password && formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            placeholder='Password'
            title='Password'
            name='password'
            type='password'
            bold
            size='small'
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password}
          />

          <MuiTextField
            fullWidth
            error={Boolean(formik.touched.companyName && formik.errors.companyName)}
            helperText={formik.touched.companyName && formik.errors.companyName}
            placeholder='Company Name'
            title='Company Name'
            name='companyName'
            type='text'
            size='small'
            bold
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.companyName}
          />
        </Box>

        <FormControlLabelStyled
          margin='dense'
          value='end'
          label='Agree the terms and policy'
          labelPlacement='end'
          control={<Checkbox />}
        />

        <MuiButton isSubmitting={isLoading} onClick={() => formik.handleSubmit()} fullWidth variant='contained' type='submit' size='xSmall' > Click to {title} </MuiButton>
      </form>
      <BelowButton><p>Already Have Account? <LinkStyled to={'/'}>Log In</LinkStyled></p></BelowButton>
    </PreLogin >
  )
}

export default Signup
