import {
  styled,
  Grid
// Box
} from '@mui/material'

export const Form = styled('form')(`
  background: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom:20px;
`)

export const GridWrapper = styled(Grid)(`
  width: calc(100% + 16px);
  margin: 0 -8px;
`)
