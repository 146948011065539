import {
  styled,
  Grid
  // Box
} from '@mui/material'

export const Form = styled('form')(`
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`)

export const GridWrapper = styled(Grid)(`
  width: 100%;
`)
