// import axios from 'axios'
import { postLogin } from '../util/axios'

const API_URL = `${process.env.REACT_APP_API_URL}reasons`

const getReasonList = (id) => {
  const url = `${API_URL}`
  return postLogin.get(url)
}

const getOneReason = (id) => {
  const url = `${API_URL}/${id}`
  return postLogin.get(url)
}

const addReason = (data) => {
  const url = `${API_URL}/`
  return postLogin.post(url, data)
}

const updateReason = (data, id) => {
  const url = `${API_URL}/${id}`
  return postLogin.put(url, data)
}

const deleteReason = (id) => {
  const url = `${API_URL}/${id}`
  return postLogin.delete(url)
}

export default {
  getReasonList,
  addReason,
  getOneReason,
  updateReason,
  deleteReason
}
