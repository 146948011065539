import { styled, Grid } from '@mui/material'

export const Form = styled('form')(`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  border-radius: 10px;
`)

export const GridWrapper = styled(Grid)(`
  width: calc(100% + 16px);
  margin: 0 -8px;
`)
