import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import PostLogin from '../../../layout/post-login'
import { ROUTES } from '../../../constants'
import { useMuiSnackbar } from '../../../hooks'
import DepartmentService from '../../../services/department.service'
import { Title, TableWrapper } from '../../../components'
import { ListItemTextStyled } from './style'

const DepartmentViewPage = () => {
  const [departmentData, setDepartmentData] = useState({})
  const { id } = useParams()
  const [gridLoader, setGridLoader] = useState(true)
  const { SnackbarError } = useMuiSnackbar()

  const tableData = {
    title: 'Department view',
    rows: departmentData
  }

  useEffect(() => {
    DepartmentService.getOneDepartment(id)
      .then((resp) => {
        setDepartmentData(resp?.data)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }, [])

  return (
    <PostLogin heading='Department View' fixed>
      <Title
        primaryButton={{
          to: ROUTES.ROUTE_DEPARTMENT_LIST,
          title: 'back',
          size: 'small'
        }}
      />

      <TableWrapper data={tableData} loader={gridLoader}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <ListItemTextStyled
              primary='name'
              secondary={departmentData?.name}
            />
          </Grid>
          <Grid item md={4}>
            <ListItemTextStyled
              primary='description'
              secondary={departmentData?.description}
            />
          </Grid>
        </Grid>
      </TableWrapper>

    </PostLogin>
  )
}

export default DepartmentViewPage
