import React from 'react'
import { Box } from '@mui/material'
import { MuiButton, MuiTextField } from '../../components'
import PreLogin from '../../layout/pre-login'
import { LinkStyled } from '../../layout/pre-login/style'

const ForgotPassword = () => {
  const Description = () => {
    return <>Forget it <LinkStyled to='/'>Send me Back</LinkStyled> to the Log In screen.</>
  }

  return (
    <PreLogin heading1={'FORGOT PASSWORD'} logo contentp={'Enter your email address and your password will be reset and emailed to you.'} belowDescription={<Description />}>
      <Box
        sx={{ margin: '5px -8px 30px' }}
      >
        <MuiTextField
          placeholder='Enter email'
          title='Enter address'
          name='Enter email'
          bold
          type='Enter email'
          size='small'
        />
      </Box>
      <MuiButton fullWidth size='xSmall' variant='contained' type='submit'> Send me new password </MuiButton>
    </PreLogin>
  )
}

export default ForgotPassword
