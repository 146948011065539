// import axios from 'axios'
import { postLogin } from '../util/axios'

const API_URL = `${process.env.REACT_APP_API_URL}inboxs`

const getInboxList = (id) => {
  const url = `${API_URL}`
  return postLogin.get(url)
}

const getAllInboxs = (data) => {
  const url = `${API_URL}/findAllInboxs`
  return postLogin.get(url, { params: data })
}

const getOneInbox = (id) => {
  const url = `${API_URL}/${id}`
  return postLogin.get(url)
}

const addInbox = (data) => {
  const url = `${API_URL}/`
  return postLogin.post(url, data)
}

const updateInbox = (data, id) => {
  const url = `${API_URL}/${id}`
  return postLogin.put(url, data)
}

const deleteInbox = (id) => {
  const url = `${API_URL}/${id}`
  return postLogin.delete(url)
}

export default {
  getInboxList,
  getAllInboxs,
  addInbox,
  getOneInbox,
  updateInbox,
  deleteInbox
}
