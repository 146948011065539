// import axios from 'axios'
import { postLogin } from '../util/axios'

const API_URL = `${process.env.REACT_APP_API_URL}locations`

const getLocationList = (id) => {
  const url = `${API_URL}`
  return postLogin.get(url)
}

const getOneLocation = (id) => {
  const url = `${API_URL}/${id}`
  return postLogin.get(url)
}

const addLocation = (data) => {
  const url = `${API_URL}/`
  return postLogin.post(url, data)
}

const updateLocation = (data, id) => {
  const url = `${API_URL}/${id}`
  return postLogin.put(url, data)
}

const deleteLocation = (id) => {
  const url = `${API_URL}/${id}`
  return postLogin.delete(url)
}

export default {
  getLocationList,
  addLocation,
  getOneLocation,
  updateLocation,
  deleteLocation
}
