import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { COMMON } from '../../../constants'
import { Box } from '@mui/material'
import { useMuiSnackbar } from '../../../hooks'
import { MuiChip, MuiButton, Loader, MuiModal } from '../../../components/'
import JobService from '../../../services/job.service'

import {
  JobRow,
  JobDescription,
  JobRowHeading,
  ChipWrapper
} from './style'

const JobDetail = ({ jobId }) => {
  const { SnackbarError } = useMuiSnackbar()
  const [fieldsShow, setFieldsShow] = useState(false)
  const [gridLoader, setGridLoader] = useState(true)
  const [jobData, setJobData] = useState({})

  const loadJobData = async () => {
    setGridLoader(true)
    try {
      const res = (await JobService.getOneJob(jobId))?.data
      setJobData(res)
    } catch (err) {
      SnackbarError(err)
    } finally {
      setGridLoader(false)
    }
  }

  const showFields = () => {
    setFieldsShow(prev => !prev)
  }

  const currency = COMMON.VAL_TO_LABEL('JOB_CURRENCY', jobData, 'currency')?.currency
  const color = jobData?.publishType === 'draft' ? 'warning' : (jobData?.publishType === 'private' || jobData?.publishType === 'closed') ? 'error' : jobData?.publishType === 'public' ? 'success' : 'primary'

  useEffect(() => {
    loadJobData()
  }, [jobId])

  return (
    <JobRow fieldsShow={fieldsShow}>
      {(!jobData?._id)
        ? <Loader size={20} style={{ padding: '8px' }} showLoader={gridLoader} showMessage={(!gridLoader && !jobData?._id)} message='No data found!' />
        : <Box>
          <JobRowHeading
            primary='Openings'
            secondary={jobData?.jobOpenings || '-'}
          />
          <JobRowHeading
            primary='salary range'
            secondary={`${currency}${jobData?.minSalary} - ${currency}${jobData?.maxSalary}` || '-'}
          />
          <JobRowHeading
            primary='exp'
            secondary={`${jobData?.experience} yr${jobData?.experience > 1 ? 's' : ''}` || '-'}
          />
          <JobRowHeading
            primary='job Type'
            secondary={COMMON.VAL_TO_LABEL('JOB_TYPE', jobData, 'jobType') || '-'}
          />
          <JobRowHeading
            primary='location'
            secondary={jobData?.location?.name}
          />
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: 12
            }}
          >
            <MuiChip
              style={{ marginRight: 6 }}
              size='small'
              color={color}
              label={COMMON.VAL_TO_LABEL('JOB_PUBLISH', jobData, 'publishType')}
            />
            <MuiButton
              style={{
                paddingTop: 5,
                paddingBottom: 5
              }}
              variant='contained'
              type='button'
              color='primary'
              size='chip'
              onClick={showFields}
            >
              view JD
            </MuiButton>
          </Box>
        </Box>
      }
      <MuiModal title='Other Fields' onClose={showFields} open={fieldsShow} width={800}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <JobDescription>
            <Box
              dangerouslySetInnerHTML={{ __html: jobData?.jobDescription || '-' }}
            />
          </JobDescription>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '10px'
            }}
          >
            <JobRowHeading
              primary='skills'
            />

            <ChipWrapper>
              {jobData?.skills?.replaceAll('/', ',').split(',').map((skill, i) => {
                return (
                  <MuiChip color='secondary' label={skill} key={i} variant='contained' size='small' />
                )
              })}
            </ChipWrapper>
          </Box>
        </Box>
      </MuiModal>

      {/* <Box className='otherFields'>
      <JobRowHeading
      primary='job Description'
      secondary={jobData?.jobDescription || '-'}
      />
      <JobRowHeading
      primary='skills'
      secondary={jobData?.skills || '-'}
      />
    </Box> */}
    </JobRow>
  )
}

JobDetail.propTypes = {
  jobId: PropTypes.object
}

JobDetail.displayName = 'Job Detail'

export default JobDetail
