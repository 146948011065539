import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ROUTES, COMMON } from '../../../constants'
import { TableCell, TableRow, Grid, Box } from '@mui/material'
import { LocationOn } from '@mui/icons-material/'
import JobService from '../../../services/job.service'
import { useMuiSnackbar } from '../../../hooks'
import PostLogin from '../../../layout/post-login'
import {
  Title,
  Stats,
  TableWrapper,
  TableAction,
  MuiButton
  , Loader,
  SplitButton,
  MuiSelect
} from '../../../components/'

import {
  CardWrapper,
  PrimaryTitle,
  SecondaryTitle,
  CandidateWrapper,
  BottomText,
  CandidateCount,
  StyledAdd,
  GridWrapper,
  ActionsWrapper,
  CardInnerWrapper,
  EventsWrapper,
  GridButtonsWrapper,
  PreviewButton
} from './style'
import { Wrapper } from '../../common/style'

import departmentService from '../../../services/department.service'

const JobListPage = () => {
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()

  const [gridLoader, setGridLoader] = useState(true)
  const [departmentFilter, setDepartmentFilter] = useState(localStorage.getItem('job-department-filter') || 'all')
  const [publishFilter, setPublishFilter] = useState(localStorage.getItem('job-publish-filter') || 'all')
  const [departmentTabs, setDepartmentTabs] = useState([
    {
      label: 'All',
      value: 'all',
      active: departmentFilter === 'all',
      onClick: () => { departmentFilterHandler('all') }
    }
  ])
  const [selectedRow, setSelectedRow] = useState({})
  const [viewType, setViewType] = useState(localStorage.getItem('job-view') || 'grid')
  const [jobData, setJobData] = useState([])
  const [statsData, setStatsData] = useState([
    {
      // color: ,
      value: 'all',
      title: 'total Jobs'
    },
    {
      // color: ,
      value: 'public',
      title: 'public Jobs'
    },
    {
      // color: ,
      value: 'draft',
      title: 'draft Jobs'
    },
    {
      // color: ,
      value: 'private',
      title: 'private jobs'
    }
  ])

  const filterFunc = (arr) => {
    const filtered = arr
      .filter(f => departmentFilter !== 'all' ? f?.department?._id === departmentFilter : true)
      .filter(f => publishFilter !== 'all' ? f?.publishType === publishFilter : true)
    return filtered
  }

  const tableData = {
    title: 'job list',
    view: ROUTES.ROUTE_JOB_VIEW,
    edit: ROUTES.ROUTE_JOB_FORM,
    cols: [
      { title: 'heading' },
      { title: 'department' },
      { title: 'candidates' },
      { title: 'location' },
      { title: 'job Type' },
      { title: 'status' }
    ],
    srNo: true,
    rows: filterFunc(jobData)
  }

  const loadJobData = () => {
    JobService.getJobList()
      .then(response => {
        setJobData(response?.data)
      })
      .catch(err => {
        SnackbarError(err)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }

  const viewHandler = () => {
    const view = viewType === 'list' ? 'grid' : 'list'

    setViewType(view)
    localStorage.setItem('job-view', view)
  }

  const confirmationDeleteSubmit = () => {
    JobService.deleteJob(selectedRow?._id)
      .then(response => {
        loadJobData()
        SnackbarSuccess(selectedRow?.heading, 'deleted')
      })
      .catch((err) => {
        SnackbarError(err)
      })
  }

  const departmentFilterHandler = (id) => {
    setDepartmentFilter(id)
    setDepartmentTabs(
      prev => prev.map((d) => {
        return {
          ...d,
          active: !!(id === (d?.value || 'all'))
        }
      })
    )
    localStorage.setItem('job-department-filter', id)
  }

  const publishFilterHandler = (id) => {
    setPublishFilter(id)
    localStorage.setItem('job-publish-filter', id)
  }

  const publishChangeHandler = async (id, data) => {
    try {
      const publishType = { publishType: data }
      await JobService.dropdownUpdate(publishType, id, 'publishType')
      SnackbarSuccess('Publish Type', 'updated')
      loadJobData()
    } catch (err) {
      SnackbarError(err)
    } finally {
      // setJobData(resp?.data)
      // setGridLoader(false)
    }
  }

  useEffect(() => {
    departmentService.getDepartmentList()
      .then((resp) => {
        if (resp.data.length === 0) {
          SnackbarError('There are no Departments')
          return
        }
        const menu = resp.data.map(d => {
          return {
            label: d.name,
            value: d._id,
            onClick: () => { departmentFilterHandler(d._id) }
          }
        })
        setDepartmentTabs([...departmentTabs, ...menu])
        departmentFilterHandler(departmentFilter)
      })
      .catch((error) => {
        SnackbarError(error)
      })
  }, [])

  useEffect(() => {
    setStatsData(prev => prev.map(s => {
      return {
        ...s,
        // color:
        //   s?.value === 'all'
        //     ? 'primary'
        //     : s?.value === 'public'
        //       ? 'success'
        //       : s?.value === 'draft'
        //         ? 'warning'
        //         : 'error',
        // active: localStorage.getItem('job-publish-filter') === s?.value,
        // onClick: () => { publishFilterHandler(s?.value) },
        count: s?.value === 'all' ? filterFunc(jobData).length : (departmentFilter !== 'all' ? jobData.filter(d => d.department?._id === departmentFilter).filter(d => d.publishType === s?.value).length : jobData.filter(d => d.publishType === s?.value).length)
      }
    })
    )
  }, [jobData, departmentFilter, publishFilter])

  useEffect(() => {
    loadJobData()
  }, [])

  const previewBtn = (row) => {
    const url = JSON.parse(window.localStorage.getItem('user'))?.companyData?.jobWebsite
    if (!url) return null
    return <MuiButton
      variant='outlined'
      href={url}
      target='_blank'
      size='small'
    >
      Preview
    </MuiButton>
  }

  const EventsComponent = ({ data, fontSize }) => {
    return (
      <>
        <TableAction fontSize={fontSize} size='small' to={`${tableData?.view}/${data?._id}`} title='View' type='view' />
        <TableAction fontSize={fontSize} size='small' to={`${tableData?.edit}/${data?._id}`} title='Edit' type='edit' />
        <TableAction
          fontSize={fontSize}
          size='small'
          title='Delete'
          message={`Are you sure you want to delete this Job : ${selectedRow?.heading}?`}
          row={data}
          onClick={() => { setSelectedRow(data) }}
          onSuccess={confirmationDeleteSubmit}
          type='delete'
        />
      </>
    )
  }

  EventsComponent.propTypes = {
    data: PropTypes.object,
    fontSize: PropTypes.string
  }

  return (
    <PostLogin heading='Job' fixed>
      <Title
        // viewtype={viewType}
        onViewChange={viewHandler}
        tabs={departmentTabs}
        primaryButton={{
          startAdornment: <StyledAdd />,
          title: 'add',
          to: ROUTES.ROUTE_JOB_FORM,
          size: 'small'
        }}
        restbutton={
          <MuiSelect
            // title='Publish Filter'
            sx={{ background: 'white' }}
            placeholder='Publish Filter'
            data={[{ label: 'All', value: 'all' }, ...COMMON.JOB_PUBLISH]}
            selectlabel='label'
            selectvalue='value'
            size='xSmall'
            compact
            minwidth='80px'
            value={publishFilter}
            fullWidth
            required
            variant='outlined'
            onChange={(e) => {
              publishFilterHandler(e.target.value)
            }}
          />
        }
      />

      <Loader showLoader={gridLoader} animate />
      <Wrapper loader={gridLoader}>
        {viewType === 'list' &&
          <Box className='list'>
            <Stats data={statsData} />
            <TableWrapper data={tableData}>
              {tableData.rows.map((row, i) => {
                return (
                  <TableRow hover role='checkbox' key={row?._id}>
                    <TableCell align='center'>{i + 1}</TableCell>
                    <TableCell>{row?.heading}</TableCell>
                    <TableCell>{row?.department?.name}</TableCell>
                    <TableCell>{row?.candidateCountActive} / {row?.candidateCountInactive}</TableCell>
                    <TableCell>{row?.location?.name}</TableCell>
                    <TableCell>
                      {
                        COMMON?.VAL_TO_LABEL('JOB_TYPE', row, 'jobType')
                      }
                    </TableCell>
                    <TableCell>
                      <SplitButton
                        value={row?.publishType}
                        setType={(val) => { publishChangeHandler(row?._id, val) }}
                        options={COMMON.JOB_PUBLISH}
                        type='button'
                        size='chip'
                        variant='contained'
                      />
                    </TableCell>
                    <TableCell align='right'>
                      <EventsComponent data={row} />
                    </TableCell>
                  </TableRow>
                )
              })
              }
            </TableWrapper>
          </Box>
        }
        {viewType === 'grid' &&
          <GridWrapper container className='grid'>
            {tableData.rows.length > 0
              ? tableData.rows.map((row, i) => {
                return (
                  <Grid item key={row?._id} md={3}>
                    <CardWrapper>
                      <CardInnerWrapper>
                        <Box>
                          <CandidateWrapper>
                            <CandidateCount>{row?.candidateCountActive} / {row?.candidateCountInactive}</CandidateCount>
                          </CandidateWrapper>
                          <PrimaryTitle>{row?.heading}</PrimaryTitle>
                        </Box>
                        <Box>
                          <SecondaryTitle>{row?.department?.name}</SecondaryTitle>
                          <BottomText>
                            <LocationOn sx={{ fontSize: 'inherit' }} color='primary' /> {row?.location?.name} | {COMMON?.VAL_TO_LABEL('JOB_TYPE', row, 'jobType') || row?.jobType}
                          </BottomText>
                          <EventsWrapper className='events-wrapper'>
                            <EventsComponent data={row} fontSize='15px' />
                          </EventsWrapper>
                        </Box>
                      </CardInnerWrapper>

                      <Box sx={{ width: '100%' }}>
                        <ActionsWrapper>
                           <PreviewButton>
                          <SplitButton
                            value={row?.publishType}
                            setType={(val) => { publishChangeHandler(row?._id, val) }}
                            options={COMMON.JOB_PUBLISH}
                            type='button'
                            size='chip'
                            variant='contained'
                          />
                          {row?.publishType === 'public' && previewBtn(row)}
                          </PreviewButton>
                          Openings: {row?.jobOpenings || '0'}
                        </ActionsWrapper>
                        <GridButtonsWrapper>
                          <MuiButton
                            variant='outlined'
                            to={`${ROUTES.ROUTE_CANDIDATE_FORM}?departmentId=${row?.department?._id}&jobId=${row?._id}`}
                            fullWidth
                            size='small'
                            style={{ marginBottom: '8px' }}
                          >
                            add candidate
                          </MuiButton>
                          <MuiButton
                            variant='contained'
                            to={`${ROUTES.ROUTE_CANDIDATE_LIST}?departmentId=${row?.department?._id}&jobId=${row?._id}`}
                            fullWidth
                            size='small'
                          >
                            Candidates list
                          </MuiButton>
                        </GridButtonsWrapper>
                      </Box>

                    </CardWrapper>
                  </Grid>
                )
              })
              : <>
                <Loader showLoader={gridLoader} showMessage={!gridLoader && true} message='No data found!' />
              </>
            }
          </GridWrapper>
        }
      </Wrapper>
    </PostLogin>
  )
}

JobListPage.propTypes = {
  setAgentDataPage: PropTypes.func,
  setAgentDataLimit: PropTypes.func,
  agentDataPage: PropTypes.func,
  agentDataLimit: PropTypes.func
}

JobListPage.displayName = 'Job List'

export default JobListPage
