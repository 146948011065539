import React from 'react'
import { ROUTES } from '.'
import {
  RocketLaunchOutlined,
  DoDisturbOnOutlined,
  BusinessCenterOutlined,
  // LocalCafeOutlined,
  LockOutlined,
  // DriveFileRenameOutlineOutlined,
  // DnsOutlined,
  PublicOutlined
} from '@mui/icons-material/'

/* eslint-disable */

export const VALIDATE_TIME = /^([0-9]{2})\:([0-9]{2})$/

export const TASK_STATUS = [
  {
    label: 'Open',
    value: 'open'
  },
  {
    label: 'In Progress',
    value: 'inProgress'
  },
  {
    label: 'Completed',
    value: 'completed'
  }
]

export const CANDIDATE_TYPE = [
  {
    label: 'Active',
    value: 'active'
  },
  {
    label: 'Archive',
    value: 'inactive'
  }
]

export const JOB_CURRENCY = [
  {
    label: 'US Dollar ($)',
    currency: '$',
    value: 'usd'
  },
  {
    label: 'Indian Rupee (₹)',
    currency: '₹',
    value: 'inr'
  },
  {
    label: 'UAE Dirham (د.إ)',
    currency: 'د.إ',
    value: 'aed'
  }
]

export const SOURCE = [
  {
    label: 'On Instagram',
    value: 'instagram'
  },
  {
    label: 'LinkedIn',
    value: 'linkedIn'
  },
  {
    label: 'Job Portal',
    value: 'jobPortal'
  }
]

export const ROLE = [
  {
    label: 'Admin',
    value: 'admin'
  },
  {
    label: 'Employee',
    value: 'employee'
  },
  {
    label: 'User',
    value: 'user'
  }
]

export const JOB_PUBLISH = [
  // {
  //   message: 'visible only on your employee portal, but not saved',
  //   label: 'Draft',
  //   icon: <DriveFileRenameOutlineOutlined color='primary' sx={{ fontSize: 'inherit' }} />,
  //   value: 'draft'
  // },
  // {
  //   message: 'visible only on your employee portal',
  //   label: 'Internal',
  //   icon: <DnsOutlined color='primary' sx={{ fontSize: 'inherit' }} />,
  //   value: 'internal'
  // },
  {
    message: 'visible only to your hiring team and admins',
    label: 'Private',
    icon: <LockOutlined color='primary' sx={{ fontSize: 'inherit' }} />,
    value: 'private'
  },
  {
    message: 'closed, candidates can\'t apply',
    label: 'Closed',
    icon: <DoDisturbOnOutlined color='primary' sx={{ fontSize: 'inherit' }} />,
    value: 'closed'
  },
  {
    message: 'visible on your career and employee portal',
    label: 'Public',
    icon: <PublicOutlined color='primary' sx={{ fontSize: 'inherit' }} />,
    value: 'public'
  }
]

export const BOOLEAN = [
  {
    label: 'True',
    value: 'true'
  },
  {
    label: 'False',
    value: 'false'
  }
]

export const JOB_TYPE = [
  {
    label: 'Seasonal',
    value: 'seasonal'
  },
  {
    label: 'Internship',
    value: 'internship'
  },
  {
    label: 'Fixed Term Contract',
    value: 'fixedTermContract'
  },
  {
    label: 'Full Time',
    value: 'fullTime'
  },
  {
    label: 'Part Time',
    value: 'partTime'
  },
  {
    label: 'Contract',
    value: 'contract'
  },
  {
    label: 'Remote',
    value: 'remote'
  }
]

export const FULL_DATE = (val) => {
  const date = new Date(val)
  return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
}

export const FULL_DATE_TIME = (val) => {
  const MM = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const date = new Date(val)
  return `${date.getDate()} ${MM[date.getMonth()]}, ${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}`
}

export const FULL_TIME = (val) => {
  const date = new Date(val)
  return `${date.getHours()}:${date.getMinutes()}`
}

export const VAL_TO_LABEL = (type, obj, key) => {
  const evaluate = eval(type).find(c => key
    ? c?.value === obj[key]
    : c?.value === obj
  )

  const label = type !== 'JOB_CURRENCY'
    ? evaluate?.label
    : evaluate
  return label
}

export const LHS_ITEMS = [
  {
    // heading: 'directories',
    links: [
      [
        {
          accordion: true,
          icon: <RocketLaunchOutlined sx={{ fontSize: '20px' }} />,
          title: 'ATS'
        },
        {
          href: ROUTES.ROUTE_DASHBOARD,
          title: 'Dashboard'
        },
        {
          href: ROUTES.ROUTE_INBOX_LIST,
          title: 'Inbox'
        },
        {
          href: ROUTES.ROUTE_EMPLOYEE_LIST,
          hrefAdd: ROUTES.ROUTE_EMPLOYEE_FORM,
          title: 'Employee'
        },
        // {
        //   href: ROUTES.ROUTE_TASK_LIST,
        //   hrefAdd: ROUTES.ROUTE_TASK_FORM,
        //   title: 'Task'
        // },
        {
          href: ROUTES.ROUTE_JOB_LIST,
          hrefAdd: ROUTES.ROUTE_JOB_FORM,
          title: 'Job Openings'
        },
        // {
        //   href: ROUTES.ROUTE_CANDIDATE_LIST,
        //   hrefAdd: ROUTES.ROUTE_CANDIDATE_FORM,
        //   title: 'Candidate'
        // }
      ]
    ]
  },
  {
    // heading: 'Settings',
    links: [
      [
        {
          accordion: true,
          icon: <BusinessCenterOutlined sx={{ fontSize: '20px' }} />,
          title: 'Settings'
        },
        {
          href: ROUTES.ROUTE_STAGE_LIST,
          hrefAdd: ROUTES.ROUTE_STAGE_FORM,
          title: 'Stage'
        },
        {
          href: ROUTES.ROUTE_LOCATION_LIST,
          hrefAdd: ROUTES.ROUTE_LOCATION_FORM,
          title: 'Location'
        },
        {
          href: ROUTES.ROUTE_DEPARTMENT_LIST,
          hrefAdd: ROUTES.ROUTE_DEPARTMENT_FORM,
          title: 'Department'
        },
        {
          href: ROUTES.ROUTE_REASON_LIST,
          title: 'Reason'
        },
        {
          href: ROUTES.ROUTE_COMPANY_FORM,
          hrefAdd: ROUTES.ROUTE_COMPANY_FORM,
          title: 'Company'
        }
      ],
      // [
      //   {
      //     accordion: true,
      //     icon: <LocalCafeOutlined sx={{ fontSize: '20px' }} />,
      //     title: 'RND'
      //   },
      //   {
      //     href: ROUTES.ROUTE_TODO_LIST,
      //     title: 'Todo'
      //   },
      //   {
      //     href: ROUTES.ROUTE_TUTORIAL_LIST,
      //     hrefAdd: ROUTES.ROUTE_TUTORIAL_FORM,
      //     title: 'Tutorial'
      //   }
      // ]

      //     ,[
      //       {
      //         icon: <LockOutlined sx={{ fontSize: '20px' }} />,
      //         accordion: true,
      //         title: 'project'
      //       },
      //       {
      //         href: ROUTES.ROUTE_DASHBOARD,
      //         title: 'Dashboard'
      //       },
      //       {
      //         href: ROUTES.ROUTE_EMPLOYEE_LIST,
      //         hrefAdd: ROUTES.ROUTE_EMPLOYEE_FORM,
      //         title: 'Employee'
      //       },
      //       {
      //         href: ROUTES.ROUTE_TUTORIAL_LIST,
      //         hrefAdd: ROUTES.ROUTE_TUTORIAL_FORM,
      //         title: 'Tutorial'
      //       }
      //     ]
    ]
  }
]

export const timePickerArray = (gap=60, range=[0, 24]) => {
  const array = [{label:'select', value:null}]
  for(let i=range[0]; i<range[1]; i++){
    const hours = `${i<10 ? '0'+i: i}`
    if(gap === 60){
      const val = `${hours}:00`
      array.push({label:val, value:val})
    } else {
      for(let j=0; j<(60/gap); j++){
        const min = gap*j
        const val = `${hours}:${min<10 ? '0'+min: min}`
        array.push({label:val, value:val})
      }
    }
  }
  return array
}
