import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { ROUTES } from '../../../constants'
import * as Yup from 'yup'
import PostLogin from '../../../layout/post-login'
import DepartmentService from '../../../services/department.service'
import { useMuiSnackbar } from '../../../hooks'
import {
  MuiTextField,
  Title,
  MuiButton
} from '../../../components/'
import { Grid } from '@mui/material'
import { Form, GridWrapper } from './style'
import { ButtonWrapper } from '../../common/style'

const DepartmentAddPage = () => {
  const form = useRef()
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()

  const [isLoading, setIsLoading] = useState(true)
  const [mode, setMode] = useState('add')
  const [initialVal, setInitialVal] = useState({
    name: ''
  })
  const history = useNavigate()
  const { id } = useParams()

  const validation = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'Kindly Enter Your - name'
    }
    return errors
  }

  const onSubmits = (data) => {
    setIsLoading(true)
    const api = mode === 'add' ? DepartmentService.addDepartment(data) : DepartmentService.updateDepartment(data, id)
    api
      .then((resp) => {
        history(ROUTES.ROUTE_DEPARTMENT_LIST)
        SnackbarSuccess('Department', mode)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: initialVal,
    validationSchema: Yup.object({
      name: Yup.string().max(25).required(),
      description: Yup.string().max(250)
    }),
    validate: values => validation(values),
    onSubmit: values => {
      const updatedData = { ...values }
      onSubmits(updatedData)
    }
  })

  useEffect(() => {
    if (id?.length) {
      setMode('edit')
      DepartmentService.getOneDepartment(id)
        .then((resp) => {
          setInitialVal(
            {
              name: resp?.data?.name,
              description: resp?.data?.description
            }
          )
          // enqueueSnackbar('Tutorial fetched successfully', {
          //   variant: 'success'
          // })
        })
        .catch((error) => {
          SnackbarError(error)
        })
        .finally(() => {
          setIsLoading(false)
        })

      formik.initialValues = initialVal
    } else setIsLoading(false)
  }, [])

  return (
    <PostLogin heading={`Department ${id?.length ? 'Edit' : 'Add'}`} fixed>
      <Title
        primaryButton={{
          title: 'back',
          to: ROUTES.ROUTE_DEPARTMENT_LIST,
          size: 'small'
        }}
      />

      <Form onSubmit={formik.handleSubmit} ref={form}>
        <GridWrapper container>
          <Grid item xs={12}>
            <MuiTextField
              error={Boolean(
                formik.touched.name && formik.errors.name
              )}
              fullWidth
              size='small'
              required
              helperText={formik.touched.name && formik.errors.name}
              margin='normal'
              name='name'
              autoFocus
              placeholder='Name'
              title='Name'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.name}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <MuiTextField
              error={Boolean(
                formik.touched.description && formik.errors.description
              )}
              fullWidth
              size='small'
              helperText={formik.touched.description && formik.errors.description}
              margin='normal'
              name='description'
              placeholder='description'
              title='description'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.description}
              variant='outlined'
            />
          </Grid>
        </GridWrapper>
        <ButtonWrapper>
          <MuiButton
            variant='contained'
            // fullWidth
            size='small'
            type='submit'
            disabled={isLoading}
            onClick={() => formik.handleSubmit()}
          >
            {id?.length ? 'Update' : 'Add'}
          </MuiButton>
        </ButtonWrapper>
      </Form>

    </PostLogin>
  )
}

export default DepartmentAddPage
