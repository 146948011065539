import { styled, Typography, ListItemText, Paper, Grid } from '@mui/material'

export const ListItemTextStyled = styled(ListItemText)(`
  width: 100%;
  > span {
    text-transform: capitalize;
    position: relative;
    width: fit-content;
    &:before{
      content:': ';
      position: absolute;
      right: -5px;
      top: 0;
    }
  }
`)

export const ViewWrapper = styled(Grid)(`

`)

export const Heading = styled(Typography)(`
  font-size: 30px;
  font-weight: 500;
  text-transform: capitalize;
`)

export const ViewBox = styled(Paper)(props => `
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  min-height: 100%;
`)
