import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { FormControlLabel, Paper, Box } from '@mui/material/'

export const PreLoginOuterWrapper = styled(Box)(`
`)

export const PreLoginInnerWrapper = styled(Box)(props => `
    display: flex;
    width: 100%;
    color: ${props?.theme?.palette?.primary?.dark};
    min-height: 100vh;
`)

export const CardWrapper = styled(Paper)(({ theme }) => `
    position: absolute;
    background-color: #fff;
    right: -155px;
    max-width: 350px;
    padding: 20px 40px;
    margin: auto;
    width: 100%;
    max-height: 90vh;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        transition: ease-in-out .5s;
        background: ${theme.palette.primary.main};
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: ${theme.palette.primary.dark};
    }
`)

export const RhsWrapper = styled(Box)(`
    // justify-content: center;
    // flex-direction: column;
    align-items: center;
    display: flex;
    background-color: #f1f1f1;
    width: calc(100% - 400px);
    overflow: hidden;
    transition: ease-in-out 1s;
`)

export const RhsBox = styled(Box)(props => `
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    transition: inherit;
`)

export const LhsWrapper = styled(Box)(({ theme }) => `
    display: flex;
    align-items: center;
    width: 400px;
    background-color: ${theme.palette.background.main};
    position: relative;
`)

export const RhsBgImg = styled('img')(`
    width: 500px;
`)

export const RhsTitle = styled('h4')(`
    margin-top: 24px;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.5rem;
`)

export const RhsPara = styled('p')(`
    font-size: 18px;
    text-align: center;
    max-width: 650px;
    padding:0 30px;
`)

export const BelowButton = styled(Box)(`
    text-align: center;
    margin-top: 20px;
    color: #6c757d;
`)

export const TitleH1 = styled('h1')(`
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 25px;
`)

export const LogoWrapper = styled(Box)(`
    display: flex;
    margin-bottom: 40px;
    justify-content: center;
`)

export const ContentP = styled('p')(`
    margin: 0;
    margin: 0px 0px 16px;
    color: #6c757d;
    line-height: 20px;
    font-size: 16px;
`)

export const TitleH4 = styled('h4')(props => `
color: ${props?.theme?.palette?.primary?.dark};
margin: 0px 0px 16px;
font-weight: 600;
line-height: 1.2;
font-size: 29px;
`)

export const TitleBig = styled('h1')(`
    color: #6c757d;
    margin: 0;
    font-size: 72px;
    font-weight: 100;
    margin: 10px 0px 40px;
`)

export const LinkStyled = styled(Link)(({ theme }) => `
    text-decoration: none;
    color: ${theme.palette.primary.main};
    &:hover {
        color: ${theme.palette.primary.dark};
    }
`)

export const FormControlLabelStyled = styled(FormControlLabel)(`
    margin: 0px 0px 30px;
    text-transform: capitalize;
    span {
        &:first-of-type{
            font-size: 20px;
            padding-left: 0;
        }
    }
`)
