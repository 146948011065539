import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TextDropdown } from '../../../components'
import CandidateService from '../../../services/candidate.service'
import EmployeeService from '../../../services/employee.service'
import { useMuiSnackbar } from '../../../hooks'

const OwnedBy = ({ onChange, row }) => {
  const { SnackbarError, SnackbarSuccess } = useMuiSnackbar()
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(true)
  const candidateId = (row?._id)

  const ownerChange = async (e) => {
    setLoader(true)
    const data = {
      ownedBy: e,
      type: 'ownedBy'
    }
    try {
      const resp = await CandidateService.dropdownUpdate(data, candidateId)
      SnackbarSuccess('Owned By', 'updated')
      onChange(resp?.data)
    } catch (err) {
      SnackbarError(err)
    } finally {
      setLoader(false)
    }
  }

  const loadEmployees = () => {
    EmployeeService.getEmployeeList()
      .then((resp) => {
        const data = resp.data.map((d) => {
          return {
            label: d?.name || d?.firstName,
            isSubStage: false,
            value: d?._id
          }
        })
        setData(data)
      })
      .catch((error) => {
        SnackbarError(error)
      })
  }

  useEffect(() => {
    if (row) {
      setLoader(false)
      loadEmployees()
    }
  }, [row])

  return (
    <TextDropdown
      value={row?.ownedBy?._id || row?.ownedBy}
      name='ownedBy'
      placeholder='Owner'
      disabled={loader}
      data={data}
      onChange={(e) => { ownerChange(e) }}
    />
  )
}

OwnedBy.propTypes = {
  row: PropTypes.object,
  onChange: PropTypes.func,
  loader: PropTypes.bool
}

export default OwnedBy
