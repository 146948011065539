import React from 'react'
import PropTypes from 'prop-types'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import MuiButton from '../muiButton'
import {
  Grow,
  ClickAwayListener,
  Box,
  // Paper,
  Popper,
  MenuItem,
  MenuList
} from '@mui/material'
import { DriveFileRenameOutlineOutlined } from '@mui/icons-material/'

import {
  ButtonGroupStyled,
  ListItemTextStyled,
  PaperStyled
} from './style'

const SplitButton = ({ size, options, onClick, isSubmitting, setType, value, helperText, style, type, color }) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedVal, setSelectedVal] = React.useState(value)

  const defaultStyle = {
    transition: 'ease-in-out .2s'
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedVal(options[index]?.value)
    setType(options[index]?.value)
    setOpen(false)
    onClick()
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  React.useEffect(() => {
    setSelectedVal(value)
  }, [value])

  return (
    <Box style={{ ...defaultStyle, ...style }}>
      <ButtonGroupStyled color={!color ? (selectedVal === 'public' ? 'success' : selectedVal === 'draft' ? 'warning' : (selectedVal === 'private' || selectedVal === 'closed') ? 'error' : 'primary') : color} variant="contained" ref={anchorRef} aria-label="split button" size={size}>
        <MuiButton
          size="small"
          type={type}
          isSubmitting={isSubmitting}
          onClick={onClick}
        >
          {helperText} {selectedVal}
        </MuiButton>
        <MuiButton
          className="arrow"
          size="small"
          disabled={isSubmitting}
          // aria-controls={open ? 'split-button-menu' : undefined}
          // aria-expanded={open ? 'true' : undefined}
          // aria-label="select publish type"
          // aria-haspopup="menu"
          onClick={handleToggle}
          style={{
            paddingLeft: size === 'chip' ? '2px' : '6px',
            paddingRight: size === 'chip' ? '2px' : '6px',
            minWidth: 'auto'
          }}
        >
          <ArrowDropDownIcon
            style={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
            sx={{ fontSize: '18px' }}
          />
        </MuiButton>
      </ButtonGroupStyled>
      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <PaperStyled size={size}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="split-button-menu"
                  autoFocusItem
                >
                  {options?.map((o, index) => {
                    const message = size === 'chip' ? false : o?.message
                    const icon = size === 'chip' ? false : (o?.icon || <DriveFileRenameOutlineOutlined color='primary' sx={{ fontSize: 'inherit' }} />)
                    if (o?.hidden) {
                      return false
                    }
                    return (
                      <MenuItem
                        key={index}
                        value={o?.value}
                        selected={o?.value === selectedVal}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        <ListItemTextStyled
                          primary={
                            <>
                              {icon}
                              {o?.label}
                            </>
                          }
                          secondary={message}
                        />
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </PaperStyled>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

SplitButton.propTypes = {
  helperText: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  setType: PropTypes.func,
  onClick: PropTypes.func,
  isSubmitting: PropTypes.bool,
  options: PropTypes.array
}

export default SplitButton
