import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { useFormik } from 'formik'
import { ROUTES } from '../../../constants'
import * as Yup from 'yup'
import PostLogin from '../../../layout/post-login'
import StageService from '../../../services/stage.service'
import departmentService from '../../../services/department.service'
import { useMuiSnackbar } from '../../../hooks'
import { MuiTextField, MuiCheckbox, Title, MuiButton, MuiSelect, Rte } from '../../../components/'
import { Form, GridWrapper } from './style'
import { ButtonWrapper } from '../../common/style'

const StageAddPage = () => {
  const form = useRef()
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()
  const [departmentData, setDepartmentData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [departmentFilter, setDepartmentFilter] = useState('')
  const [mode, setMode] = useState('add')
  const [initialVal, setInitialVal] = useState({
    name: null,
    order: null,
    department: localStorage.getItem('stage-department-filter') || '',
    isSubStage: false,
    // parentStage: '',
    // description: '',
    // emailBody: '',
    // emailSubject: '',
    sendEmail: false
  })
  const [parentStage, setParentStage] = useState([])
  const [emailBodyData, setEmailBodyData] = useState(null)
  const history = useNavigate()
  const { id } = useParams()

  const validation = (values) => {
    const errors = {}
    // if (values.sendEmail && values.emailBody.length === 0) {
    //   formik.errors.emailBody = 'Kindly Enter Your - Send Email Data'
    // } else formik.errors.emailBody = undefined
    return errors
  }

  const onSubmits = (data) => {
    setIsLoading(true)
    const api = mode === 'add' ? StageService.addStage(data) : StageService.updateStage(data, id)
    api
      .then((resp) => {
        history(ROUTES.ROUTE_STAGE_LIST)
        SnackbarSuccess('Stage', mode)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: initialVal,
    validationSchema: Yup.object({
      name: Yup.string().max(25).required(),
      order: Yup.number().max(10).required(),
      isSubStage: Yup.boolean(),
      department: Yup.string(),
      parentStage: Yup.string(),
      description: Yup.string().max(250),
      sendEmail: Yup.boolean(),
      emailSubject: Yup.string().max(50),
      emailBody: Yup.string().max(500)
    }),
    // validate: values => validation(values),
    validate: values => { validation({ ...values, emailBody: emailBodyData }) },
    onSubmit: values => {
      const updatedData = { ...values, emailBody: emailBodyData }
      onSubmits(updatedData)
    }
  })

  const getStages = () => {
    StageService.getAllStages({ departmentId: (departmentFilter || formik.values.department) })
      .then((resp) => {
        setParentStage(resp?.data)
      })
      .catch((error) => {
        SnackbarError(error)
      })
  }

  const loadData = () => {
    if (id?.length) {
      setMode('edit')
      StageService.getOneStage(id)
        .then((resp) => {
          setEmailBodyData(resp?.data?.emailBody || '')
          setInitialVal(
            {
              name: resp?.data?.name,
              department: resp?.data?.department._id,
              order: resp?.data?.order,
              isSubStage: resp?.data?.isSubStage,
              parentStage: resp?.data?.parentStage,
              description: resp?.data?.description,
              sendEmail: resp?.data?.sendEmail || false,
              emailSubject: resp?.data?.emailSubject,
              emailBody: resp?.data?.emailBody || ''
            }
          )
          setDepartmentFilter(resp?.data?.department)
        })
        .catch((error) => {
          SnackbarError(error)
        })
        .finally(() => {
          setIsLoading(false)
        })

      // formik.initialValues = initialVal
    } else setIsLoading(false)
  }

  useEffect(() => {
    if (departmentData?.length === 0) {
      departmentService.getDepartmentList()
        .then((resp) => {
          if (resp.data.length === 0) {
            SnackbarError('There are no Departments')
            return
          }
          setDepartmentData(resp.data)
          loadData()
        })
        .catch((error) => {
          SnackbarError(error)
        })
    }
  }, [departmentData])

  useEffect(() => {
    getStages()
  }, [formik.values.department])

  return (
    <PostLogin heading={`Stage ${id?.length ? 'Edit' : 'Add'}`} fixed>
      <Title
        primaryButton={{
          title: 'back',
          to: ROUTES.ROUTE_STAGE_LIST,
          size: 'small'
        }}
      />

      <Form onSubmit={formik.handleSubmit} ref={form}>
        <GridWrapper container>

          <Grid item md={12}>
            <MuiSelect
              error={Boolean(
                formik.touched.department && formik.errors.department
              )}
              size='small'
              required
              // helperText={formik.touched.department && formik.errors.department}
              data={departmentData}
              onBlur={formik.handleBlur}
              selectlabel='name'
              selectvalue='_id'
              onChange={formik.handleChange}
              placeholder='Department'
              title='Department'
              name='department'
              fullWidth
              value={formik?.values?.department}
            />
          </Grid>

          {formik.values.department && <Grid item md={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <MuiCheckbox
              error={Boolean(
                formik.touched.isSubStage && formik.errors.isSubStage
              )}
              label='Add Sub-Stage'
              // helperText={formik.touched.isSubStage && formik.errors.isSubStage}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name='isSubStage'
              value={formik.values.isSubStage}
              checked={formik.values.isSubStage}
            />
          </Grid>}

          {(formik.values.isSubStage && formik.values.department) && <Grid item md={12}>
            <MuiSelect
              name='parentStage'
              placeholder='Stage'
              onChange={formik.handleChange}
              fullWidth
              size='small'
              data={parentStage}
              title='Sub Stage'
              selectlabel='name'
              selectvalue='_id'
              error={Boolean(
                formik.touched.parentStage && formik.errors.parentStage
              )}
              value={formik.values.parentStage}
            />
          </Grid>}

          <Grid item md={12}>
            <MuiTextField
              error={Boolean(
                formik.touched.name && formik.errors.name
              )}
              autoFocus
              fullWidth
              size='small'
              required
              // helperText={formik.touched.name && formik.errors.name}
              margin='normal'
              name='name'
              placeholder='Name'
              title='Name'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.name}
              variant='outlined'
            />
          </Grid>

          <Grid item md={12}>
            <MuiTextField
              error={Boolean(
                formik.touched.order && formik.errors.order
              )}
              fullWidth
              size='small'
              required
              // helperText={formik.touched.order && formik.errors.order}
              margin='normal'
              name='order'
              placeholder='Order'
              title='Order'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='number'
              value={`${formik.values.order}`}
              variant='outlined'
            />
          </Grid>

          <Grid item md={12}>
            <MuiTextField
              error={Boolean(
                formik.touched.description && formik.errors.description
              )}
              fullWidth
              size='small'
              // helperText={formik.touched.description && formik.errors.description}
              margin='normal'
              name='description'
              placeholder='description'
              title='description'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.description}
              variant='outlined'
            />
          </Grid>

          <Grid item md={12}>
            <MuiCheckbox
              error={Boolean(
                formik.touched.sendEmail && formik.errors.sendEmail
              )}
              label='Send Email to Candidate on updating stages'
              // helperText={formik.touched.sendEmail && formik.errors.sendEmail}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name='sendEmail'
              value={formik.values.sendEmail}
              checked={formik.values.sendEmail}
            />
          </Grid>

          {(formik.values.sendEmail) && <Grid item md={12}>
            <MuiTextField
              error={Boolean(
                formik.touched.emailSubject && formik.errors.emailSubject
              )}
              fullWidth
              size='small'
              // helperText={formik.touched.emailSubject && formik.errors.emailSubject}
              margin='normal'
              name='emailSubject'
              placeholder='emailSubject'
              title='emailSubject'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.emailSubject}
              variant='outlined'
            />
          </Grid>}

          {(formik.values.sendEmail) && <Grid item md={12}>
            <Rte
              required
              error={formik.errors.emailBody}
              title='Send Email Content'
              placeholder='Send Email Content'
              name='emailBody'
              onBlur={formik.handleBlur}
              value={emailBodyData}
              onChange={e => setEmailBodyData(e)}
              height={350}
            />
          </Grid>}

        </GridWrapper>
        <ButtonWrapper>
          <MuiButton
            variant='contained'
            // fullWidth
            size='small'
            type='submit'
            disabled={isLoading}
            onClick={() => formik.handleSubmit()}
          >
            {id?.length ? 'Update' : 'Add'}
          </MuiButton>
        </ButtonWrapper>
      </Form>

    </PostLogin>
  )
}

export default StageAddPage
