import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

export const LinkStyleSmall = styled(Link)(({ theme }) => `
    text-decoration: none;
    color: ${theme.palette.primary.main};
    &:hover {
        color: ${theme.palette.primary.dark};
    }
    font-size: 11px;
`)
