import { styled, Box, Paper, Typography, ListItemText } from '@mui/material'
import { Add } from '@mui/icons-material'
import { Link } from 'react-router-dom'

export const StyledAdd = styled(Add)(`
  line-height: 1;
  font-size: 15px;
  font-weight: 400;
`)

export const LinkStyled = styled(Link)(props => `
  white-space: nowrap;
  color: inherit;
  text-decoration: ${props?.underline ? 'underline' : 'none'};
  border-bottom: 1px solid ${props?.border ? `${props?.theme?.palette?.primary?.main}` : 'transparent'};
  &:hover{
    text-decoration: ${props?.border ? 'none' : 'underline'};
  }
`)

export const JobDescription = styled(Box)(props => `
  & * {font-size:14px;}
  & p { padding: 0 0 30px 0; }
  & ul {list-style: square; padding-bottom:15px;}
  & ul li {list-style:outside; padding: 0 0 5px 0; margin-left: 20px;}
  & ul li ul {list-style:circle; padding-bottom:8px;}

  & h2 { font-size: 24px; padding-bottom:8px; }
  & h3 { font-size: 18px; padding-bottom:8px; }
  & h4 { font-size: 18px; padding-bottom:8px; }
  & h5 { font-size: 13.5px; padding-bottom:8px; }
  & h6 { font-size: 13.5px; padding-bottom:8px; }
`)

export const JobRow = styled(Paper)(props => `
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  margin-bottom: 30px;
  > div {
    width: 100%;
    display: flex;
    align-items: center;
  }
  >.otherFields {
    position: absolute;
    transition: ease-in-out .5s;
    top: 40px;
    right: 0;
    min-height: 50px;
    box-shadow: ;
    background: white;
    border: 1px red solid;
    z-index: 50;
    border-radius: inherit;
    opacity: ${props?.fieldsShow ? '1' : '0'};
    visibility: ${props?.fieldsShow ? 'visible' : 'hidden'};
  }
`)

export const JobRowHeading = styled(ListItemText)(props => `
  text-transform: capitalize;  
  padding-left: 0;
  margin-top: 0;
  
  &:not(:last-child){
    padding-left: 12px;
  }
  > span {
    position: relative;
    width: fit-content;
    color: #6c757d;
    font-size: 12.8px;
    font-weight: 400;
    &:after {
      position: absolute;
      content: ':';
      right: -5px;
    }
  }
  > p {
    // font-size: 16px;
    font-size: 12.5px;
    color: ${props?.theme?.palette?.primary?.main};
    font-weight: 400;
    line-height: 1.5;
  }
`)

export const MuiSwitchWrapper = styled(Box)(`
  display: inline-block;
  margin-right: 5px;
`)

export const ChipWrapper = styled(Typography)(`
  display: flex;
  & > div {
    margin-right:5px;
  }
`)

export const DropDownLink = styled(Link)(({ theme }) => `
  display: flex;
  align-items: center;
  // justify-content: space-between;
  text-decoration: none;
  // text-transform: capitalize;
  color: #333537;
  font-size: 14px;
  padding: 10px;
  margin: 0 10px;
  font-weight: 400;
  width: auto;
  transition: ease-in-out .5s;
  &:hover{
    background: #e8e9e9;
  }
  >svg{
    margin-right: 8px;
  }
`)
