import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import {
  Button,
  Typography,
  Tooltip,
  Box,
  AppBar
} from '@mui/material'

export const IconButtonStyled = styled(Button)(({ theme }) => `
  color: ${theme.palette.action.text};
  min-width: 0;
  max-width: 38px;
`)

const styling = `
  display: flex;
  align-items: center;
  // justify-content: space-between;
  text-decoration: none;
  text-transform: capitalize;
  color: #333537;
  font-size: 14px;
  padding: 10px;
  margin: 0 10px;
  font-weight: 400;
  width: auto;
  transition: ease-in-out .5s;
  &:hover{
    background: #e8e9e9;
  }
  >svg{
    margin-right: 8px;
  }
`

export const DropDownItem = styled(Box)(styling)

export const DropDownLink = styled(Link)(({ theme }) => styling)

export const BoxStyled = styled(Box)(({ theme }) => `
  border: 0;
  padding: 6px 10px;
  display: flex;
  > svg {
    color: #4d5052;
    transition: ease-in-out .5s;
    &:hover{
      color: ${theme?.palette?.primary?.main};
    }
  }
`)

export const TypographyDrop = styled(Typography)(props => `
  display: flex;
  text-transform: capitalize;
  font-size: 16px;
  line-height: calc(24 / 16);
  color: ${props?.theme?.palette?.primary?.dark};
  align-items: center;
  padding: 6px 10px;
`)

export const TypographyStyled = styled(Typography)(`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-size: 17px;
  line-height: 1.2;
  font-weight: 500;
`)

export const Container = styled(Box)(({ theme }) => `
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 15px;
  padding: 20px 0;
  width: 100%;
  &:after{
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    background: #e8e9e9;
    height: 1px;
    width: 100%;
  }
`)

export const DropBoxWrapper = styled(Box)(({ theme }) => `
  // &:before{
  //   content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9;
  // }
`)

export const DropBox = styled(Box)(({ theme }) => `
  display: flex;
  flex-direction: column;
  position: absolute;
  color: black;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 25%);
  right: 0;
  top: 30px;
  border: 0;
  font-size: 14px;
  border-radius: 4px;
  z-index: 10;
  padding: 8px 0;
  min-width: 192px;
  background: white;
  &:after{
    position: absolute;
    content: '';
    top: 0;
    right: 8px;
    height: 10px;
    width: 10px;
    transform: translate(0%, -100%);
    border: 10px transparent solid;
    border-bottom: 10px white solid;
    // box-shadow: inherit;
  }
`)

export const ToolbarStyled = styled(Box)(({ theme }) => `
  justify-content: space-between;
  display: flex;
  min-height: 70px;
  // padding: 0 35px;
  padding: 0 20px;
  align-items: center;
  background: ${theme.palette.background.index};
`)

export const TooltipStyled = styled(Tooltip)((props) => `
  cursor: pointer;
`)

export const DashboardNavbarRoot = styled(AppBar)((props) => (`
  // overflow-y: scroll;
  background-color: ${props?.theme.palette.background.index};
  transition: inherit;
  box-shadow: none;
  ${props?.fixed
    ? `position: fixed !important;
      // transition: ease-in-out .5s;
      top: 0;
      right: 0;
      width: calc(100% - ${props?.theme?.components?.MuiSideBar?.width});`
    : `position: relative;
      width: 100%;`
  }
  // &::-webkit-scrollbar{
  //   opacity: 0;
  // }
`))

export const BreadcrumbLink = styled(Link)(props => `
  white-space: nowrap;
  color: inherit;
  text-decoration: ${props?.underline ? 'underline' : 'none'};
  border-bottom: 1px solid ${props?.border ? `${props?.theme?.palette?.primary?.main}` : 'transparent'};
  &:hover{
    text-decoration: ${props?.border ? 'none' : 'underline'};
  }
`)
