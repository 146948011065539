import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import { Box } from '@mui/material'
import { useGlobalState } from '../../../../hooks'
import AuthService from '../../../../services/auth.service'
import { Dropdown } from '../../../../components'
import {
  ArrowRightOutlined,
  Person,
  PersonOutlined,
  ArrowDropDownOutlined,
  Notifications,
  LogoutOutlined,
  SettingsOutlined,
  SendOutlined,
  Mail,
  MailOutlined
} from '@mui/icons-material'
import {
  ToolbarStyled,
  TypographyDrop,
  TypographyStyled,
  TooltipStyled,
  Container,
  BoxStyled,
  DropDownLink,
  // DropDownItem,
  DashboardNavbarRoot,
  BreadcrumbLink
} from './style'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../../constants'

export const DashboardNavbar = ({ fixed = true, heading = 'Page', breadcrumbs, ...props }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [dropBox, setDropBox] = useState(false)
  const currentUser = AuthService.getCurrentUser()
  const { user, setUser } = useGlobalState()

  const logOut = () => {
    AuthService.logout()
    enqueueSnackbar('You have logged out', { variant: 'success' })
    setUser(null)
  }

  const dropBoxHandler = (event) => {
    !dropBox ? setDropBox(event.currentTarget) : setDropBox(null)
  }

  const dropBoxClose = () => {
    setDropBox(null)
  }

  const profileLinks = [
    {
      title: 'profile',
      icon: <PersonOutlined sx={{ fontSize: '18px' }} />,
      to: '#'
    },
    {
      title: 'settings',
      icon: <SettingsOutlined sx={{ fontSize: '18px' }} />,
      to: '#'
    },
    {
      title: 'inbox',
      icon: <MailOutlined sx={{ fontSize: '18px' }} />,
      to: '#'
    },
    {
      title: 'message',
      icon: <SendOutlined sx={{ fontSize: '18px' }} />,
      to: '#'
    },
    {
      divider: true
    }
  ]

  return (
    <>
      <DashboardNavbarRoot {...props} fixed={fixed} className={`header ${fixed ? 'fixed' : ''}`}>
        <ToolbarStyled>
          <Container>
            <Box>
              <TypographyStyled variant='h1' color='primary'>
                {breadcrumbs?.length > 0
                  ? breadcrumbs.map((b, i) => {
                    const icon = <ArrowRightOutlined sx={{ fontSize: 'inherit' }} />
                    return b?.title &&
                      <Box key={i} sx={{ display: 'flex', alignItems: 'center', lineHeight: !b?.to ? '1' : 'inherit' }}>
                        {i !== 0 && icon}
                        {b?.to
                          ? <BreadcrumbLink to={b?.to}>{b?.title}</BreadcrumbLink>
                          : <>{b?.title}</>
                        }
                      </Box>
                  })
                  : heading
                }
              </TypographyStyled>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* {['language', 'reports', 'project'].map((d, i) => { */}
              {[].map((d, i) => {
                return (
                  <Box sx={{ display: 'flex' }} key={i}>
                    <TypographyDrop>
                      {d}
                      <ArrowDropDownOutlined sx={{ fontSize: '16px', marginRight: '-4px' }} />
                    </TypographyDrop>
                  </Box>
                )
              })}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Link to={ROUTES?.ROUTE_INBOX_LIST}>
                  <TooltipStyled title='Inbox'>
                    <BoxStyled>
                      <Mail sx={{
                        fontSize: '17px'
                      }} />
                    </BoxStyled>
                  </TooltipStyled>
                </Link>
                <TooltipStyled title='Notification' style={{ display: 'none' }}>
                  <BoxStyled>
                    <Notifications sx={{
                      fontSize: '17px'
                    }} />
                  </BoxStyled>
                </TooltipStyled>
                <Box style={{ position: 'relative' }}>
                  <TooltipStyled title={user?.name || user?.firstName || currentUser?.name || currentUser?.firstName || 'Unauthorized!'} onClick={dropBoxHandler}>
                    <BoxStyled>
                      <Person sx={{
                        fontSize: '17px'
                      }} />
                    </BoxStyled>
                  </TooltipStyled>
                  <Dropdown pos={dropBox} onClose={dropBoxClose} >
                    {profileLinks.map((d, i) => {
                      return (
                        <div key={i} style={{ display: 'none' }}>
                          {d?.divider
                            ? <Box style={{
                              height: 0,
                              margin: '6px 0px',
                              overflow: 'hidden',
                              borderTop: '1px solid #e9ecef'
                            }} />
                            : <DropDownLink key={i} to={d?.to}>
                              {d?.icon || <Person sx={{ fontSize: '16px' }} />}
                              {d?.title}
                            </DropDownLink>
                          }
                        </div>
                      )
                    })}
                    <DropDownLink onClick={logOut} to='/'>
                      <LogoutOutlined sx={{ fontSize: '16px' }} />
                      logout
                    </DropDownLink>
                  </Dropdown>
                </Box>
              </Box>
            </Box>
          </Container>
        </ToolbarStyled>
      </DashboardNavbarRoot>
      {fixed && <Box sx={{ marginTop: '70px' }} />}
    </>
  )
}

DashboardNavbar.propTypes = {
  fixed: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  heading: PropTypes.string || PropTypes.node
}
