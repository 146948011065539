import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Box, ListItemText, Chip, TableCell, TableRow } from '@mui/material'
import PostLogin from '../../../layout/post-login'
import CandidateService from '../../../services/candidate.service'
import { useMuiSnackbar } from '../../../hooks'
import { Loader, Title, Rating, TableWrapper, TableAction, MuiButton, MuiTextField, MuiModal } from '../../../components'
import { ROUTES, COMMON } from '../../../constants'
import Stages from '../components/stage'
import OwnedBy from '../components/ownedBy'
import { KeyboardArrowDown } from '@mui/icons-material/'

import {
  Lhs,
  Rhs,
  Card,
  NameCircle,
  Name,
  CardTitle,
  CardTableWrapper,
  CardTableItem,
  CardTableContent,
  CardHead,
  CardActions,
  LinkStyled,
  TimelineWrapper,
  TimelineList,
  TimelineHeading,
  TimelineDescription,
  TimelineDate,
  TimelineStage,
  TimelineIsActive,
  EmailWrap,
  DescriptionWrapper
} from './style'
import { Wrapper } from '../../common/style'

const CandidateViewPage = () => {
  const { id } = useParams()
  const location = useLocation()
  const { SnackbarError, SnackbarSuccess } = useMuiSnackbar()
  const history = useNavigate()
  const [candidateData, setCandidateData] = useState({})
  const [collapse, setCollapse] = useState(false)
  const [tableCollapse, setTableCollapse] = useState(collapse)
  // const [loader, setLoader] = useState(true)
  const [show, setShow] = useState(false)
  const [feedbackRow, setFeedbackRow] = useState()
  const [feedback, setFeedback] = useState('')
  const [interviewMode, setInterviewMode] = useState('')
  const form = useRef()

  let tabsData = [
    {
      label: 'Summary',
      value: 'summary'
    },
    {
      label: 'Interviews',
      value: 'interviews'
    },
    {
      label: 'Timeline',
      value: 'timeline'
    }
  ]
  const [activeTab, setActiveTab] = useState(location?.hash.slice(1) || 'summary')
  const [gridLoader, setGridLoader] = useState(true)

  tabsData = tabsData.map(tabs => {
    return {
      ...tabs,
      active: activeTab === tabs?.value,
      onClick: () => tabHandler(tabs?.value)
    }
  })

  const infoHandler = () => {
    setCollapse(prev => !prev)

    !tableCollapse
      ? setTimeout(() => {
        setTableCollapse(!tableCollapse)
      }, 500)
      : setTableCollapse(!tableCollapse)
  }

  const handleRatingClicked = async (value, index, row) => {
    try {
      const data = { rating: value, type: 'rating' }
      await CandidateService.dropdownUpdate(data, row?._id)
      SnackbarSuccess('Rating', 'updated')
    } catch (err) {
      SnackbarError(err)
    }
  }

  const loadData = () => {
    CandidateService.getOneCandidate(id)
      .then((resp) => {
        // console.log('resp?.data ----- ', resp?.data)
        setCandidateData(resp?.data)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }
  const tabHandler = (val) => {
    setActiveTab(val)
    history(`${location?.pathname}#${val}`)
    tabsData?.map(m => {
      return {
        ...m,
        active: val === m?.value
      }
    })
  }

  const breadcrumbs = [
    {
      title: 'Job',
      to: ROUTES.ROUTE_JOB_LIST
    },
    {
      title: 'Candidates',
      to: ROUTES.ROUTE_CANDIDATE_LIST
    },
    {
      title: candidateData?.departmentId?.name,
      to: `${ROUTES.ROUTE_CANDIDATE_LIST}?departmentId=${candidateData?.departmentId?._id}`
    },
    {
      title: candidateData?.jobId?.heading,
      to: `${ROUTES.ROUTE_CANDIDATE_LIST}?jobId=${candidateData?.jobId?._id}`
    }
  ]

  const tableData = {
    title: 'Candidate Interview List',
    view: ROUTES.ROUTE_JOB_VIEW,
    edit: ROUTES.ROUTE_JOB_FORM,
    cols: [
      { title: 'owner' },
      { title: 'interview at' },
      { title: 'stage' },
      { title: 'interviewers' }
    ],
    srNo: true,
    rows: candidateData?.scheduleInterview
  }

  const openModal = (row, mode) => {
    setFeedbackRow(row)
    setShow(true)
    setFeedback(row.feedback || '')
    setInterviewMode(mode)
  }

  const closeModal = () => {
    setFeedbackRow(null)
    setShow(false)
    // setValue('')
  }

  const handlerFeedbackEvent = (e) => {
    setFeedback(e.target.value)
  }

  const handlerSubmit = async (e) => {
    e.preventDefault()

    // setLoader(true)
    const data = { feedback, id: feedbackRow._id }
    try {
      await CandidateService.interviewFeedback(data, id)
      SnackbarSuccess('Stage', 'updated')
      // onChange(resp?.data)
    } catch (err) {
      SnackbarError(err)
    } finally {
      // setLoader(false)
      setShow(false)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <PostLogin
      heading='Candidate View'
      breadcrumbs={breadcrumbs}
      fixed
    >
      <Title
        tabs={tabsData}
        primaryButton={{
          title: 'back',
          // to: `${ROUTES.ROUTE_CANDIDATE_LIST}?departmentId=${candidateData?.departmentId?._id}&jobId=${candidateData?.jobId?._id}#${candidateData?.stage?._id}`,
          to: `${ROUTES.ROUTE_CANDIDATE_LIST}?departmentId=${candidateData?.departmentId?._id}&jobId=${candidateData?.jobId?._id}`,
          size: 'small'
        }}
        secondaryButton={
          {
            title: 'edit',
            size: 'small',
            to: `${ROUTES.ROUTE_CANDIDATE_FORM}/${id}`
          }
        }
      />
      <Loader showLoader={gridLoader} animate />
      <Wrapper loader={gridLoader} sx={{ display: 'flex' }}>
        <Lhs width={35}>
          <Card
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-evenly'
            }}
          >
            <NameCircle>
              {candidateData?.firstName?.charAt(0)}
              {candidateData?.lastName?.charAt(0)}
            </NameCircle>
            <Box>
              <Name
                primary={`${candidateData?.firstName} ${candidateData?.lastName}`}
                secondary={<a href={`mailto:${candidateData?.email}`}>{candidateData?.email}</a>}
              />
              <Name
                secondary={candidateData?.mobile}
              />
            </Box>
          </Card>
          <Card>
            <CardTableItem sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <CardTableContent
                primary='stage'
                secondary={
                  candidateData.candidateType === 'active'
                    ? <Stages
                      row={candidateData}
                      onChange={loadData}
                      loader={true}
                      departmentQuery={candidateData?.departmentId}
                    />
                    : candidateData.stage?.name
                }
              />
              <CardTableContent
                primary='owned by'
                secondary={
                  candidateData.candidateType === 'active'
                    ? <OwnedBy
                      row={candidateData}
                      onChange={loadData}
                      loader={true}
                    />
                    : candidateData.ownedBy?.name
                }
              />
            </CardTableItem>
          </Card>
          <Card className={`collapse ${collapse}`}>
            <CardHead>
              <CardTitle>
                Candidate Info
              </CardTitle>
              <CardActions>
                <KeyboardArrowDown
                  sx={{
                    fontSize: '20px',
                    opacity: 0.5,
                    transform: collapse ? 'rotate(0deg)' : 'rotate(180deg)'
                  }}
                  color='primary'
                  onClick={infoHandler}
                />
              </CardActions>
            </CardHead>
            {!tableCollapse && <CardTableWrapper>
              <CardTableItem>
                <CardTableContent
                  primary='Where did you find us?'
                  secondary={candidateData?.find}
                />
              </CardTableItem>
              <CardTableItem>
                <CardTableContent
                  primary='department'
                  secondary={
                    <LinkStyled underline to={`${ROUTES.ROUTE_DEPARTMENT_VIEW}/${candidateData?.departmentId?._id}`}>
                      {candidateData?.departmentId?.name || candidateData?.departmentId?._id || '-'}
                    </LinkStyled>
                  }
                />
                <CardTableContent
                  primary='job'
                  secondary={
                    <LinkStyled underline to={`${ROUTES.ROUTE_JOB_VIEW}/${candidateData?.jobId?._id}`}>
                      {candidateData?.jobId?.heading || candidateData?.jobId?._id || '-'}
                    </LinkStyled>
                  }
                />
              </CardTableItem>
              <CardTableItem>
                <CardTableContent
                  primary='Current Ctc'
                  secondary={candidateData?.currentCtc}
                />
                <CardTableContent
                  primary='Expected Ctc'
                  secondary={candidateData?.expectedCtc}
                />
              </CardTableItem>
              <CardTableItem>
                <CardTableContent
                  primary='rating'
                  secondary={
                    <Rating
                      initialValue={candidateData?.rating}
                      onClick={(value, index) => handleRatingClicked(value, index, candidateData)}
                      // readonly
                    />
                  }
                />
              </CardTableItem>
            </CardTableWrapper>}
          </Card>
        </Lhs>
        <Rhs width={65} activeTab={activeTab}>
          {activeTab === 'summary' && <Box className='summary'>
            <Card>
              <object data={`${process.env.REACT_APP_PDF_URL}${candidateData?.resume?.fileName}`} type={'application/pdf'} width="100%" height='100%'>
                <p>Alternative text - include a link <a href={`${process.env.REACT_APP_API_URL}${candidateData?.resume?.fileName}`} target='_blank' rel='noreferrer'>to the PDF!</a> -wip</p>
              </object>
            </Card>
          </Box>}

          {activeTab === 'timeline' && <Box className='timeline'>
            <Card>
              <TimelineWrapper>
                {candidateData?.timeline?.length > 0
                  ? candidateData?.timeline.map((list, i) => {
                    const condition = list.ownedBy?.name || list.ownedBy?.firstName
                    return (
                      <TimelineList key={i}>
                        {condition && <TimelineHeading>Owner - {condition} ({list.ownedBy?.email})</TimelineHeading>}
                        <TimelineDate>{COMMON?.FULL_DATE_TIME(list?.createTime || list?.createdAt)}</TimelineDate>
                        {list.stage && <TimelineStage>{list.stage?.name}</TimelineStage>}
                        {list.feedback && <TimelineDescription>Feedback - {list.feedback}</TimelineDescription>}
                        {list.candidateType && <TimelineIsActive type={list.candidateType}>{COMMON.VAL_TO_LABEL('CANDIDATE_TYPE', list, 'candidateType') || list?.candidateType} </TimelineIsActive>}
                      </TimelineList>
                    )
                  })
                  : <ListItemText
                    primary='No timeline found!'
                    secondary='Try changing this candidates Stage, or Owner'
                  />
                }
              </TimelineWrapper>
            </Card>
          </Box>}

          {activeTab === 'interviews' && <Box className='interviews'>
            {!!candidateData?.scheduleInterview?.length && <TableWrapper data={tableData}>
              {tableData.rows.map((row, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell align='center'>{i + 1}</TableCell>
                    <TableCell>{row?.ownedBy?.name}</TableCell>
                    <TableCell>{COMMON.FULL_DATE(row.interviewDate)} - {row.interviewTime}</TableCell>
                    <TableCell>{row.stage.name}</TableCell>
                    <TableCell><EmailWrap>{row?.interviewers?.map((e, i) => (<Chip key={i} label={e.email} variant='outlined' size='small' />))}</EmailWrap></TableCell>
                    <TableCell align='right'>
                      <TableAction size='small' title='View Interviews' type='view' onClick={() => openModal(row, 'view')} />
                      <TableAction size='small' title='Edit Interviews' type='edit' onClick={() => openModal(row, 'edit')} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableWrapper>}

            {candidateData?.scheduleInterview?.length === 0 && <ListItemText
              primary='No interview found!'
              secondary='Try scheduling an interview for this candidates.'
            />}

            {feedbackRow && <MuiModal
              title={`Candidate interview feedback form (by: ${feedbackRow?.ownedBy?.name})`}
              onClose={closeModal}
              open={show}
            >
              <form onSubmit={handlerSubmit} ref={form}>
                <DescriptionWrapper>
                  <MuiTextField
                    multiline={true}
                    rows={4}
                    maxRows={6}
                    size='small'
                    required
                    margin='normal'
                    name='feedback'
                    placeholder='Candidate interview feedback!'
                    onChange={handlerFeedbackEvent}
                    type='text'
                    disabled={interviewMode === 'view'}
                    value={feedback}
                    variant='outlined'
                  />
                </DescriptionWrapper>
                <MuiButton style={{ marginRight: '16px' }} size='small' color='primary' variant='outlined' type='button' onClick={closeModal}>Cancel</MuiButton>
                {interviewMode === 'edit' && <MuiButton size='small' color='primary' variant='contained' type='submit'>Submit</MuiButton>}
              </form>
            </MuiModal>}

          </Box>}

        </Rhs>
      </Wrapper>
    </PostLogin>
  )
}

export default CandidateViewPage
