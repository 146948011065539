import React from 'react'
import PropTypes from 'prop-types'
import Loader from '../Loader'
import { TableCell, Table, Box } from '@mui/material'
import {
  Cell,
  PaperStyled,
  TableBodyStyled,
  TableHeadStyled,
  TableRowStyled,
  TableTitle,
  TableTitleWrapper,
  TableContainerStyled
} from './style'

const TableWrapper = ({ data, loader, children, ...props }) => {
  const { tableStyling = '' } = props
  const condition = Array.isArray(data?.rows) ? data?.rows.length === 0 : !data?.rows?._id
  if (data?.cols[0].title !== '#') {
    data?.srNo && data?.cols.unshift({ title: '#', align: 'center', width: '50px' })
  }

  const title = data?.title
  return (
    <PaperStyled paperStyle={tableStyling} {...props}>
      {title &&
        <TableTitleWrapper>
          <TableTitle>
            {title}
          </TableTitle>
        </TableTitleWrapper>
      }
      <TableContainerStyled>
        {data?.cols
          ? <Table stickyHeader aria-label='sticky table'>
            <TableHeadStyled>
              <TableRowStyled>
                {[...data?.cols, { title: 'actions', width: '115px', align: 'right' }]?.map((c, i) => {
                  return (
                    <Cell key={i} align={c?.align || 'left'} width={c?.width}>{c?.title}</Cell>
                  )
                })}
              </TableRowStyled>
            </TableHeadStyled>
            <TableBodyStyled>
              {data?.rows?.length === 0 && <TableRowStyled hover role='checkbox' className='loader-tr'>
                <TableCell colSpan={data?.cols?.length + 1} align='left'>
                  <Loader showLoader={loader} />
                  {(!loader && data?.rows?.length === 0) && <Loader showMessage={true} message='No data found!' />}
                </TableCell>
              </TableRowStyled>}
              {!loader && children}
            </TableBodyStyled>
          </Table>
          : <>
            {condition && <Box sx={{ py: '10px' }}>
              <Loader showLoader={loader} />
              {(!loader && condition) && <Loader showMessage={true} message='No data found!' />}
            </Box>
            }
            {!loader && children}
          </>
        }
      </TableContainerStyled>
    </PaperStyled>
  )
}

TableWrapper.propTypes = {
  data: PropTypes.object,
  srNo: PropTypes.bool,
  loader: PropTypes.bool,
  tableStyling: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  cols: PropTypes.array,
  children: PropTypes.node,
  rows: PropTypes.array
}

export default TableWrapper
