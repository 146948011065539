// import axios from 'axios'
import { postLogin } from '../util/axios'

const API_URL = `${process.env.REACT_APP_API_URL}tasks`

const getTaskList = (id) => {
  const url = `${API_URL}`
  return postLogin.get(url)
}

const getOneTask = (id) => {
  const url = `${API_URL}/${id}`
  return postLogin.get(url)
}

const addTask = (data) => {
  const url = `${API_URL}/`
  return postLogin.post(url, data)
}

const updateTask = (data, id) => {
  const url = `${API_URL}/${id}`
  return postLogin.put(url, data)
}

const getEmployees = () => {
  const url = `${API_URL}/find`
  return postLogin.get(url)
}

const deleteTask = (id) => {
  const url = `${API_URL}/${id}`
  return postLogin.delete(url)
}

export default {
  getTaskList,
  addTask,
  getOneTask,
  getEmployees,
  updateTask,
  deleteTask
}
