
// import axios from 'axios'
import { postLogin } from '../util/axios'

const API_URL = `${process.env.REACT_APP_API_URL}company`

const getCompanyList = (id) => {
  const url = `${API_URL}`
  return postLogin.get(url)
}

const getOneCompany = () => {
  const url = `${API_URL}`
  return postLogin.get(url)
}

const addCompany = (data) => {
  const url = `${API_URL}/`
  return postLogin.post(url, data)
}

const updateCompany = (data) => {
  const url = `${API_URL}`
  return postLogin.put(url, data)
}

const deleteCompany = (id) => {
  const url = `${API_URL}/${id}`
  return postLogin.delete(url)
}

export default {
  getCompanyList,
  addCompany,
  getOneCompany,
  updateCompany,
  deleteCompany
}
