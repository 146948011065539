import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import PostLogin from '../../../layout/post-login'
import { ROUTES, COMMON } from '../../../constants'
import { useMuiSnackbar } from '../../../hooks'
import TutorialService from '../../../services/tutorial.service'
import { Title, TableWrapper } from '../../../components'
import { ListItemTextStyled } from './style'

const TutorialViewPage = () => {
  const [tutorialData, setTutorialData] = useState({})
  const [gridLoader, setGridLoader] = useState(true)
  const { id } = useParams()
  const { SnackbarError } = useMuiSnackbar()

  const tableData = {
    title: 'tutorial view',
    rows: tutorialData
  }

  useEffect(() => {
    TutorialService.getOneTutorial(id)
      .then((resp) => {
        resp.data.published = resp?.data?.published.toString() + ''
        setTutorialData(resp?.data)
        // enqueueSnackbar('Tutorial fetched successfully', {
        //   variant: 'success'
        // })
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }, [])

  return (
    <PostLogin heading='Tutorial View' fixed>
      <Title
        primaryButton={{
          to: ROUTES.ROUTE_TUTORIAL_LIST,
          title: 'back',
          size: 'small'
        }}
      />

      <TableWrapper data={tableData} loader={gridLoader}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <ListItemTextStyled
              primary='title'
              secondary={tutorialData?.title}
            />
          </Grid>
          <Grid item md={4}>
            <ListItemTextStyled
              primary='description'
              secondary={tutorialData?.description}
            />
          </Grid>
          <Grid item md={4}>
            <ListItemTextStyled
              primary='published'
              secondary={
                COMMON?.VAL_TO_LABEL('BOOLEAN', tutorialData, 'published')
              }
            />
          </Grid>
        </Grid>
      </TableWrapper>

    </PostLogin>
  )
}

export default TutorialViewPage
