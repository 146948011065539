import * as React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import { Typography } from '@mui/material'
import { Wrapper } from './style'

const Loader = (props) => {
  const { animate = false, size = 30, showLoader, showMessage, message, color = 'primary', style } = props
  return (
    <Wrapper animate={animate} style={style}>
      {showLoader && <CircularProgress color={color} size={size} />}
      {showMessage && <Typography sx={{ lineHeight: 1, fontSize: '14px' }} variant='p' component='p'>{message}</Typography>}
    </Wrapper>
  )
}

Loader.propTypes = {
  size: PropTypes.number || PropTypes.string,
  animate: PropTypes.bool,
  showLoader: PropTypes.bool,
  showMessage: PropTypes.bool,
  style: PropTypes.object,
  message: PropTypes.string,
  color: PropTypes.string
}

export default Loader
