import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ListItemText, ListItemAvatar, Avatar, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { timeStampToDate } from '../../../util'
import { InboxWrapper, InboxHeading, InboxSubject, InboxBody } from './style'

const InboxViewPage = ({ data }) => {
  const [inboxData, setInboxData] = useState({})

  useEffect(() => {
    setInboxData(data)
  }, [data])

  return (
    <InboxWrapper container>
      <InboxHeading>
        <ListItemAvatar>
          <Avatar alt={inboxData?.emailTo} src='/static/images/avatar/1.jpg' />
        </ListItemAvatar>
        <ListItemText
          primary={`${inboxData?.emailTo} - (${timeStampToDate(inboxData?.emailDate)})`}
          secondary={
            <Typography component='span' variant='body2' color='text.primary'>
              From:hatim@gmail.com,  Cc:fatema@gmail.com, Bcc:idris@gmail.com,
            </Typography>
          }
        />
        <KeyboardArrowDownIcon color='disabled' />
      </InboxHeading>
      <InboxSubject>Subject: {inboxData?.emailSubject || ''}</InboxSubject>
      <InboxBody dangerouslySetInnerHTML={{ __html: inboxData?.emailBody }}></InboxBody>
    </InboxWrapper>
  )
}

InboxViewPage.propTypes = {
  data: PropTypes.object
}

InboxViewPage.displayName = 'ListPageView'

export default InboxViewPage
