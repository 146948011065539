import { styled, Box, Card, Typography, Grid } from '@mui/material'
import { Add } from '@mui/icons-material'

const compensate = '16px'

export const StyledAdd = styled(Add)(`
  line-height: 1;
  font-size: 15px;
  font-weight: 400;
`)

export const PrimaryTitle = styled(Typography)(props => `
  color: ${props?.theme?.palette?.primary?.dark};
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  margin-top: ${compensate};
  margin-bottom: calc(${compensate} / 2);
  line-height: 1.2;
`)

export const SecondaryTitle = styled(Typography)(props => `
  margin-bottom: 8px;
`)

export const OuterWrapper = styled(Box)(props => `
  position: relative;
  transition: all .5s;
  opacity: ${props?.loader ? '0' : '1'};
`)

export const CandidateWrapper = styled(Box)(props => `
  // margin: ${compensate} 0;
  margin-bottom: calc(${compensate} / 2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  line-height: 1;
  border: 2px solid ${props?.theme?.palette?.primary?.main};
  text-transform: capitalize;
`)

export const CandidateCount = styled(Typography)(props => `
`)

export const BottomText = styled(Box)(props => `
  font-size: 12px;
`)

export const EventsWrapper = styled(Box)(props => `
  position: absolute;
  top: 0;
  // right: 0;
  right: -20px;
  display: flex;
  flex-direction: column;
  transition: ease-in-out .2s;
`)

export const ActionsWrapper = styled(Box)(props => `
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  border-top: 2px solid ${props?.theme?.palette?.background?.index};
  padding: 10px 20px;
  text-transform: capitalize;
  font-size: 12px;

  overflow: visible;
  &::-webkit-scrollbar{
    height: 0;
    width: 0;
  }
`)
export const GridButtonsWrapper = styled(Box)(props => `
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px;
  padding-bottom: 20px;
`)

export const GridWrapper = styled(Grid)(props => `
  width: calc(100% + ${compensate});
  margin: 0 calc(${compensate} / -2);
  margin-top: calc(${compensate} / -2);
`)

export const CardWrapper = styled(Card)(props => `
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: calc(${compensate} / 2);
  background: white;
  // transition: ease-in-out .5s;
  border: 1px solid transparent;
  overflow: visible;
  height: calc(100% - ${compensate});
  &:hover {
    // transform: scale(1.05);
    // border-color: ${props?.theme?.palette?.primary?.main};
    // add drop shadow
    .events-wrapper {
      right: 0;
    }
  }
`)

export const CardInnerWrapper = styled(Box)(props => `
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`)

export const PreviewButton = styled(Box)(`
  display:flex;
  & a {
    padding: 1px 3px !important;
    font-size: 11px !important;
    margin-left:10px;
  }
`)
