import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Box } from '@mui/material'
import PostLogin from '../../../layout/post-login'
import { ROUTES } from '../../../constants'
import { useMuiSnackbar } from '../../../hooks'
import StageService from '../../../services/stage.service'
import { Title, TableWrapper } from '../../../components'
import { ListItemTextStyled } from './style'

const StageViewPage = () => {
  const [stageData, setStageData] = useState({})
  const { id } = useParams()
  const [gridLoader, setGridLoader] = useState(true)
  const { SnackbarError } = useMuiSnackbar()

  const tableData = {
    title: 'Stage view',
    rows: stageData
  }

  useEffect(() => {
    StageService.getOneStage(id)
      .then((resp) => {
        setStageData(resp?.data)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }, [])

  return (
    <PostLogin heading='Stage View' fixed>
      <Title
        primaryButton={{
          to: ROUTES.ROUTE_STAGE_LIST,
          title: 'back',
          size: 'small'
        }}
      />

      <TableWrapper data={tableData} loader={gridLoader}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <ListItemTextStyled
              primary='name'
              secondary={stageData?.name}
            />
          </Grid>
          <Grid item md={6}>
            <ListItemTextStyled
              primary='order'
              secondary={stageData?.order}
            />
          </Grid>
          <Grid item md={6}>
            <ListItemTextStyled
              primary='department'
              secondary={stageData?.department?.name}
            />
          </Grid>
          <Grid item md={12}>
            <ListItemTextStyled
              primary='description'
              secondary={stageData?.description || '-'}
            />
          </Grid>
          {stageData?.sendEmail && <>
            <Grid item md={12}>
              <ListItemTextStyled
                primary='Email Subject'
                secondary={stageData?.emailSubject}
              />
            </Grid>
            <Grid item md={12}>
              <ListItemTextStyled
                primary='Email Body'
                secondary={<Box dangerouslySetInnerHTML={{ __html: stageData?.emailBody }}></Box>}
              />
            </Grid>
          </>}
        </Grid>
      </TableWrapper>

    </PostLogin>
  )
}

export default StageViewPage
