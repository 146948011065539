import React from 'react'
import PropTypes from 'prop-types'
import Loader from '../Loader'
import {
  Button,
  StartAdornmentBox,
  EndAdornmentBox,
  Link
} from './style'

const MuiButton = props => {
  let toUrl
  let object = {}

  const _delete = () => {
    const keys = Object.keys(props)
    keys.map(k => {
      if (k !== 'to') object[k] = props[k]
      return k
    })
  }

  if (props?.to) {
    toUrl = props.to; _delete()
  } else {
    object = props
  }

  const ButtonHolder = (prop) => {
    return (
      <Button {...prop} className='primary-button' disabled={(prop?.isSubmitting || prop?.disabled) && true} >
        {(prop?.isSubmitting) ? <Loader showLoader={true} size={15} style={{ marginRight: '10px' }} /> : (prop?.startAdornment) && <StartAdornmentBox >{prop?.startAdornment}</StartAdornmentBox>}
        {prop?.children}
        {prop?.endAdornment && <EndAdornmentBox>{prop?.endAdornment}</EndAdornmentBox>}
      </Button>
    )
  }

  return (
    <>
      {toUrl
        ? <Link to={toUrl}>
          <ButtonHolder {...props} />
        </Link>
        : <ButtonHolder {...props} />
      }
    </>
  )
}

MuiButton.propTypes = {
  to: PropTypes.string,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  children: PropTypes.node || PropTypes.string,
  startAdornment: PropTypes.node || PropTypes.string,
  endAdornment: PropTypes.node || PropTypes.string
}

export default MuiButton
