import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Confirmation from '../Confirmation'
import { DeleteOutlined, EditOutlined, RemoveRedEyeOutlined } from '@mui/icons-material'
import {
  IconButton,
  Tooltip
} from '@mui/material'

const IconType = (props) => {
  return (
    <>{
      typeof props?.type === 'string'
        ? (props?.type === 'edit'
            ? <EditOutlined sx={{ fontSize: props?.fontSize || '15px' }} />
            : props?.type === 'delete'
              ? <DeleteOutlined sx={{ fontSize: props?.fontSize || '15px' }} />
              : <RemoveRedEyeOutlined sx={{ fontSize: props?.fontSize || '15px' }} />)
        : props?.type
    }
      {props?.children}
    </>
  )
}

IconType.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  fontSize: PropTypes.string
}

const TableAction = (props) => {
  const [openConfirmation, setConfirmation] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})

  const handleDelete = (event, row) => {
    event.stopPropagation()
    setSelectedRow(row)
    setConfirmation(true)
    props.onClick()
  }

  const handleClose = () => {
    setConfirmation(false)
    setSelectedRow({})
  }

  const confirmationDeleteSubmit = () => {
    props?.onSuccess(selectedRow?._id)
    setConfirmation(false)
  }

  return (
    <>
      <Tooltip title={props?.title || null}>
        {props?.to
          ? <Link to={props?.to}>
            <IconButton size={props?.size || 'small'} >
              <IconType type={props?.type} fontSize={props?.fontSize}>{props?.children}</IconType>
            </IconButton>
          </Link>
          : <IconButton size={props?.size || 'small'} onClick={props?.type === 'delete' ? event => handleDelete(event, props?.row) : props?.onClick}>
            <IconType type={props?.type} fontSize={props?.fontSize}>{props?.children}</IconType>
          </IconButton>
        }
      </Tooltip>
      {
        openConfirmation && (
          <Confirmation
            open={openConfirmation}
            title={props?.message || 'Are you sure you want to delete this?'}
            submitMsg={'Delete'}
            onSubmit={confirmationDeleteSubmit}
            onClose={handleClose}
          />
        )
      }
    </>
  )
}

TableAction.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  fontSize: PropTypes.string,
  size: PropTypes.string,
  message: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  onSuccess: PropTypes.func,
  row: PropTypes.object
}

export default TableAction
