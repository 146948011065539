import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { Grid } from '@mui/material'
import * as Yup from 'yup'
import PostLogin from '../../../layout/post-login'
import JobService from '../../../services/job.service'
import departmentService from '../../../services/department.service'
import locationService from '../../../services/location.service'
import { ROUTES, COMMON } from '../../../constants'
import { useMuiSnackbar } from '../../../hooks'
import {
  MuiTextField,
  MuiButton,
  Title,
  SplitButton,
  Rte,
  MuiSelect
  // MuiCheckbox
} from '../../../components/'

import {
  Form,
  GridWrapper
} from './style'
import { ButtonWrapper } from '../../common/style'

const JobAddPage = () => {
  const form = useRef()
  const [mode, setMode] = useState('add')
  const [departmentData, setDepartmentData] = useState([])
  const [locationData, setLocationData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [jobDescriptionData, setJobDescriptionData] = useState('')
  const [publishType, setPublishType] = useState('public')
  const [initialVal, setInitialVal] = useState({
    heading: '',
    department: localStorage.getItem('job-department-filter') !== 'all' ? localStorage.getItem('job-department-filter') : '',
    jobType: '',
    location: '',
    publishType: '',
    skills: '',
    minSalary: '',
    maxSalary: '',
    jobOpenings: '',
    experience: '',
    jobDescription: '',
    currency: '',
    allowEmployeesToApply: true
  })
  const history = useNavigate()
  const { id } = useParams()
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()

  const validation = (values) => {
    const errors = {}
    // if (values.jobDescription.length === 0) {
    //   formik.errors.jobDescription = 'Kindly Enter Your - job description'
    // } else formik.errors.jobDescription = undefined
    return errors
  }

  const onSubmits = (data) => {
    setIsLoading(true)
    const api = mode === 'add'
      ? JobService.addJob(data)
      : JobService.updateJob(data, id)
    api
      .then((resp) => {
        history(ROUTES.ROUTE_JOB_LIST)
        SnackbarSuccess('Job', mode)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: initialVal,
    validationSchema: Yup.object({
      heading: Yup.string().max(50).required(),
      department: Yup.string().required(),
      jobType: Yup.string().required(),
      location: Yup.string().required(),
      // publishType: Yup.string().required(),
      // jobDescription: Yup.string().max(100),
      skills: Yup.string().max(100),
      minSalary: Yup.number(),
      maxSalary: Yup.number(),
      currency: Yup.string().required(),
      jobOpenings: Yup.number(),
      experience: Yup.string(),
      allowEmployeesToApply: Yup.boolean()
    }),
    validate: values => { validation({ ...values, jobDescription: jobDescriptionData, publishType }) },
    onSubmit: values => {
      const updatedData = { ...values, jobDescription: jobDescriptionData, publishType }
      onSubmits(updatedData)
    }
  })

  const publishTypeOptions = COMMON.JOB_PUBLISH.map((j, i) => {
    return {
      ...j,
      hidden: !!((j?.value === 'draft' || j?.value === 'closed'))
    }
  })

  useEffect(() => {
    if (id?.length) {
      setMode('edit')
      JobService.getOneJob(id)
        .then((resp) => {
          setPublishType(resp.data.publishType)
          setInitialVal(
            {
              heading: resp.data.heading,
              department: resp.data.department?._id,
              jobType: resp.data.jobType,
              location: resp.data.location?._id,
              currency: resp.data.currency,
              jobDescription: resp.data.jobDescription,
              skills: resp.data.skills,
              minSalary: resp.data.minSalary,
              maxSalary: resp.data.maxSalary,
              jobOpenings: resp.data.jobOpenings,
              experience: resp.data.experience,
              allowEmployeesToApply: resp.data.allowEmployeesToApply
            }
          )
          setJobDescriptionData(resp.data.jobDescription)
          setIsLoading(false)
        })
        .catch((error) => {
          SnackbarError(error)
          setIsLoading(true)
        })

      formik.initialValues = initialVal
    } else setIsLoading(false)
  }, [])

  useEffect(() => {
    if (departmentData?.length === 0) {
      departmentService.getDepartmentList()
        .then((resp) => {
          if (resp.data.length === 0) {
            SnackbarError('There are no Departments')
            return
          }
          setDepartmentData(resp.data)
        })
        .catch((error) => {
          SnackbarError(error)
        })
    }
  }, [departmentData])

  useEffect(() => {
    if (locationData?.length === 0) {
      locationService.getLocationList()
        .then((resp) => {
          if (resp.data.length === 0) {
            SnackbarError('There are no Locations')
            return
          }
          setLocationData(resp.data)
        })
        .catch((error) => {
          SnackbarError(error)
        })
    }
  }, [locationData])

  return (
    <PostLogin heading={`Job ${id?.length ? 'Edit' : 'Add'}`} fixed>
      <Title
        primaryButton={{
          title: 'back',
          to: ROUTES.ROUTE_JOB_LIST,
          size: 'small'
        }}
      />

      <Form onSubmit={formik.handleSubmit} ref={form}>
        <GridWrapper container>
          <Grid item md={12}>
            <MuiTextField
              error={Boolean(
                formik.touched.heading && formik.errors.heading
              )}
              title='Whats the job you are hiring for?'
              fullWidth
              size='small'
              required
              helperText={formik.touched.heading && formik.errors.heading}
              margin='normal'
              name='heading'
              autoFocus
              placeholder='heading'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik?.values?.heading}
              variant='outlined'
            />
          </Grid>

          <Grid item md={4}>
            <MuiSelect
              required
              error={
                formik.touched.department && formik.errors.department
              }
              size='small'
              helperText={formik.touched.department && formik.errors.department}
              data={departmentData}
              onBlur={formik.handleBlur}
              selectlabel='name'
              selectvalue='_id'
              onChange={formik.handleChange}
              placeholder='Department'
              title='Department'
              name='department'
              fullWidth
              value={formik?.values?.department && formik?.values?.department}
            />
          </Grid>

          <Grid item md={4}>
            <MuiSelect
              error={
                formik.touched.jobType && formik.errors.jobType
              }
              required
              size='small'
              helperText={formik.touched.jobType && formik.errors.jobType}
              data={COMMON.JOB_TYPE}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder='job Type'
              title='job Type'
              name='jobType'
              fullWidth
              value={formik?.values?.jobType && formik?.values?.jobType}
            />
          </Grid>

          <Grid item md={4}>
            <MuiSelect
              error={
                formik.touched.location && formik.errors.location
              }
              required
              size='small'
              helperText={formik.touched.location && formik.errors.location}
              data={locationData}
              selectlabel='name'
              selectvalue='_id'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder='location'
              title='location'
              name='location'
              fullWidth
              value={formik?.values?.location && formik?.values?.location}
            />
          </Grid>

          <Grid item md={12}>
            <Rte
              error={formik.errors.jobDescription}
              // error={Boolean(
              //   formik.touched.jobDescription && formik.errors.jobDescription
              // )}
              title='job description'
              required
              placeholder='Job Description'
              name='jobDescription'
              onBlur={formik.handleBlur}
              value={jobDescriptionData}
              onChange={(e) => setJobDescriptionData(e)}
              height={350}
            />
          </Grid>

          <Grid item md={4}>
            <MuiSelect
              required
              error={
                formik.touched.currency && formik.errors.currency
              }
              size='small'
              helperText={formik.touched.currency && formik.errors.currency}
              data={COMMON.JOB_CURRENCY}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder='currency'
              title='currency'
              name='currency'
              fullWidth
              value={formik?.values?.currency && formik?.values?.currency}
            />
          </Grid>

          <Grid item md={4}>
            <MuiTextField
              error={Boolean(
                formik.touched.minSalary && formik.errors.minSalary
              )}
              fullWidth
              size='small'
              helperText={formik.touched.minSalary && formik.errors.minSalary}
              margin='normal'
              name='minSalary'
              placeholder='min Salary'
              title='min Salary'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='number'
              value={formik?.values?.minSalary}
              variant='outlined'
            />
          </Grid>

          <Grid item md={4}>
            <MuiTextField
              error={Boolean(
                formik.touched.maxSalary && formik.errors.maxSalary
              )}
              fullWidth
              size='small'
              helperText={formik.touched.maxSalary && formik.errors.maxSalary}
              margin='normal'
              name='maxSalary'
              placeholder='max Salary'
              title='max Salary'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='number'
              value={formik?.values?.maxSalary}
              variant='outlined'
            />
          </Grid>

          <Grid item md={4}>
            <MuiTextField
              error={Boolean(
                formik.touched.jobOpenings && formik.errors.jobOpenings
              )}
              fullWidth
              size='small'
              helperText={formik.touched.jobOpenings && formik.errors.jobOpenings}
              margin='normal'
              name='jobOpenings'
              placeholder='job Openings'
              title='job Openings'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='number'
              value={formik?.values?.jobOpenings}
              variant='outlined'
            />
          </Grid>

          <Grid item md={4}>
            <MuiTextField
              error={Boolean(
                formik.touched.experience && formik.errors.experience
              )}
              fullWidth
              size='small'
              helperText={formik.touched.experience && formik.errors.experience}
              margin='normal'
              name='experience'
              placeholder='experience (yrs)'
              title='experience'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik?.values?.experience}
              variant='outlined'
            />
          </Grid>

          <Grid item md={4}>
            <MuiTextField
              error={Boolean(
                formik.touched.skills && formik.errors.skills
              )}
              fullWidth
              size='small'
              helperText={formik.touched.skills && formik.errors.skills}
              margin='normal'
              name='skills'
              placeholder='skills'
              title='skills'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik?.values?.skills}
              variant='outlined'
            />
          </Grid>

          {/* <Grid item md={4}>
            <MuiCheckbox sx={{ display: 'flex', alignItems: 'center' }}
              error={Boolean(
                formik.touched.allowEmployeesToApply && formik.errors.allowEmployeesToApply
              )}
              label='allow employees to apply'
              helperText={formik.touched.allowEmployeesToApply && formik.errors.allowEmployeesToApply}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name='allowEmployeesToApply'
              value={formik.values.allowEmployeesToApply && formik.values.allowEmployeesToApply}
              options={COMMON.BOOLEAN}
            />
          </Grid> */}

        </GridWrapper>

        <ButtonWrapper>

          <MuiButton
            size='small'
            type='button'
            variant='outlined'
            style={{ marginRight: '16px' }}
            to={ROUTES.ROUTE_JOB_LIST}
          >
            Cancel
          </MuiButton>

          <MuiButton
            size='small'
            type='button'
            variant='outlined'
            style={{ marginRight: '16px' }}
            isSubmitting={isLoading}
            onClick={() => { setPublishType('draft'); formik.submitForm() }}
          >
            Save and continue later
          </MuiButton>

          <SplitButton
            setType={(val) => { setPublishType(val) }}
            size='small'
            color='primary'
            type='submit'
            options={publishTypeOptions}
            isSubmitting={isLoading}
            onClick={() => formik.handleSubmit()}
            helperText='publish as'
            value={publishType}
          />

        </ButtonWrapper>
      </Form>

    </PostLogin>
  )
}

export default JobAddPage
