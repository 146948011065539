import React from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton } from '@mui/material'
import {
  Link,
  useLocation
} from 'react-router-dom'
import {
  AddOutlined,
  MoreHoriz,
  ArrowForwardOutlined,
  RocketLaunchOutlined
} from '@mui/icons-material/'

import {
  ListWrapper,
  IconBox,
  ListTitle,
  Accordion,
  LinkWrapper,
  LinkStyled
} from './style'

const NavItem = (props) => {
  const location = useLocation()
  return (
    <>
      {
        props?.data.map((d, i) => {
          return (
            <Box key={`l1_${i}`}>
              {d?.heading && <ListTitle>{d?.heading}</ListTitle>}
              {d?.links?.map((inD, j) => {
                return (
                  <ListWrapper key={`l2_${j}`}>
                    {
                      inD?.map((link, k) => {
                        // const quer = window.location.href.match(link?.href)
                        // link.active = (quer?.[0] === link?.href)
                        link.active = (location?.pathname === link?.href)
                        return (
                          <LinkWrapper key={`l3_${k}`}>
                            {link?.accordion
                              ? <Accordion className={link?.active && 'active'}>
                                  <IconBox style={{ position: 'absolute', left: 0 }}>
                                    {link?.icon ? link?.icon : <RocketLaunchOutlined sx={{ fontSize: '20px' }} />}
                                  </IconBox>
                                  {link?.title}
                                </Accordion>
                              : <>
                                  <LinkStyled to={link?.href} onClick={link?.onClick} className={link?.active && 'active'}>
                                    <IconBox>
                                      {link?.active
                                        ? <ArrowForwardOutlined color='primary' sx={{ fontSize: '16px' }} />
                                        : <MoreHoriz sx={{ fontSize: '16px' }} />}
                                    </IconBox>
                                    {link?.title}
                                  </LinkStyled>
                                  {(link?.hrefAdd && props?.addHover) &&
                                    <IconButton className='add-button'>
                                      <Link to={link?.hrefAdd}>
                                        <AddOutlined sx={{ fontSize: '16px' }} color='primary' />
                                      </Link>
                                    </IconButton>
                                  }
                                </>
                            }
                          </LinkWrapper>
                        )
                      })
                    }
                  </ListWrapper>
                )
              })}
            </Box>
          )
        })
      }
    </>
  )
}

NavItem.propTypes = {
  data: PropTypes.array,
  addHover: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string
}

NavItem.displayName = 'NavItem'

export default NavItem
