import React from 'react'
import PropTypes from 'prop-types'
import { RatingStyled } from './style'

const Rating = (props) => {
  const ratingStyle = {
    iconsCount: props?.iconsCount || 5,
    transition: props?.transition || true,
    size: props?.size || 18,
    fillColor: props?.fillColor || '#e4bd51',
    emptyColor: props?.emptyColor || '#212529',
    // showTooltip: props?.showTooltip || true,
    // tooltipDefaultText: props?.tooltipDefaultText || 0,
    allowHover: props?.allowHover || true
  }

  return (
    <RatingStyled
      {...props}
      {...ratingStyle}
    />
  )
}

Rating.propTypes = {
  iconsCount: PropTypes.number,
  transition: PropTypes.bool,
  size: PropTypes.number,
  fillColor: PropTypes.string,
  emptyColor: PropTypes.string,
  allowHover: PropTypes.bool
}

export default Rating
