import { Link } from 'react-router-dom'
import {
  styled,
  Typography,
  Box
} from '@mui/material'

export const TabsWrapper = styled('ul')(props => `
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  list-style: none;
  >li .badge {
    position: relative;
  }
  >li .badge span {
    position: relative;
    right: 4px;
    top: -5px;
    transform: scale(0.8) translate(0, 0);
    background: #d3d3d3;
    color:#333;
  }
  >li{
    display: flex;
    &:not(:last-child){
      margin-right: 30px;
    }
  }
`)

export const styling = props => `
  position: relative;
  color: #9aa0ac;
  font-weight: 400;
  padding-top: 16px;
  font-size: 15px;
  transition: ease-in-out .5s;
  text-decoration: none;
  margin-bottom: -1px;
  &::before{
    position: absolute;
    content: '';
    width: 100%;
    transition: inherit;
    top: 0;
    left: 0;
    height: 2px;
    background: transparent;
    z-index: 20;
  }
  &:hover{
    cursor: pointer;
  }
  &:hover,
  &.active{
    color: ${props?.theme?.palette?.primary?.main};
    &::before{
      background: ${props?.theme?.palette?.primary?.main};
    }
  }
`

export const TabItems = styled(Box)(props => styling(props))

export const TabLinks = styled(Link)(props => styling(props))

export const HeadingWrapper = styled(Box)(props => `
  padding: 0 20px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`)

export const LinksWrapper = styled(Box)(props => `
  position: relative;
  padding-left: 40px;
  display: flex;
  align-items: center;
`)

export const ViewTypeWrapper = styled(Box)(props => `
  position: absolute;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 10px;
`)

export const HeadingTitle = styled(Typography)(props => `
  text-transform: capitalize;
  font-size: 24px;
  margin-top: 6px;
  margin-bottom: 8px;
  line-height: 1.2;
  font-weight: 500;
  color: ${props?.theme?.palette?.primary?.dark};
`)

export const HeadingSubtitle = styled(Typography)(props => `
  text-transform: capitalize;
  font-size: 12.8px;
  font-weight: 400;
  color: ${props?.theme?.palette?.primary?.dark};
`)

export const LinkStyled = styled(Link)(props => `
  text-transform: capitalize;
  text-decoration: none;
`)

export const TitleWrapper = styled(Box)(props => `
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 13px;
  padding-bottom: 26px;
  justify-content: ${(props?.heading && (props?.primaryButton || props?.secondaryButton)) ? 'space-between' : 'flex-end'};
  justify-content: ${(props?.heading) && 'flex-start'};
`)
