// import axios from 'axios'
import { postLogin } from '../util/axios'

const API_URL = `${process.env.REACT_APP_API_URL}stages`

const getStageList = (id) => {
  const url = `${API_URL}`
  return postLogin.get(url)
}

const getAllStages = (data) => {
  const url = `${API_URL}/findAllStages`
  return postLogin.get(url, { params: data })
}

const getOneStage = (id) => {
  const url = `${API_URL}/${id}`
  return postLogin.get(url)
}

const addStage = (data) => {
  const url = `${API_URL}/`
  return postLogin.post(url, data)
}

const updateStage = (data, id) => {
  const url = `${API_URL}/${id}`
  return postLogin.put(url, data)
}

const deleteStage = (id) => {
  const url = `${API_URL}/${id}`
  return postLogin.delete(url)
}

export default {
  getStageList,
  getAllStages,
  addStage,
  getOneStage,
  updateStage,
  deleteStage
}
