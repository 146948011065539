import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMuiSnackbar } from '../../../hooks'
import PostLogin from '../../../layout/post-login'
import DepartmentService from '../../../services/department.service'
import { ROUTES } from '../../../constants'
import { TableCell, TableRow } from '@mui/material'
import { Title, TableWrapper, TableAction, Loader } from '../../../components/'
import { StyledAdd } from './style'
import { Wrapper } from '../../common/style'

const DepartmentListPage = () => {
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()
  const [gridLoader, setGridLoader] = useState(true)
  const [selectedRow, setSelectedRow] = useState({})
  // const [openConfirmation, setConfirmation] = useState(false)
  const [departmentData, setDepartmentData] = useState([])

  const tableData = {
    title: 'Department list',
    view: ROUTES.ROUTE_DEPARTMENT_VIEW,
    edit: ROUTES.ROUTE_DEPARTMENT_FORM,
    cols: [
      { title: 'name' },
      { title: 'description' }
    ],
    rows: departmentData,
    srNo: true
  }

  const loadTutorialData = () => {
    DepartmentService?.getDepartmentList()
      .then(response => {
        setDepartmentData(response?.data?.reverse())
      })
      .catch(err => {
        SnackbarError(err)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }

  const confirmationDeleteSubmit = () => {
    DepartmentService?.deleteDepartment(selectedRow?._id)
      .then(response => {
        loadTutorialData()
        SnackbarSuccess(selectedRow?.firstName || selectedRow?.name, 'deleted')
      })
      .catch((err) => {
        SnackbarError(err)
      })
  }

  useEffect(() => {
    loadTutorialData()
  }, [])

  return (
    <PostLogin heading='Department' fixed>
      <Title
        primaryButton={{
          startAdornment: <StyledAdd />,
          title: 'add',
          to: ROUTES.ROUTE_DEPARTMENT_FORM,
          size: 'small'
        }}
      />

      <Loader showLoader={gridLoader} animate/>
      <Wrapper loader={gridLoader}>
        <TableWrapper data={tableData} loader={gridLoader}>
          {tableData.rows.map((row, i) => {
            return (
              <TableRow hover role='checkbox' key={row?._id}>
                <TableCell align='center'>{i + 1}</TableCell>
                <TableCell>{row?.name}</TableCell>
                <TableCell>{row?.description || '-'}</TableCell>
                <TableCell align='center'>
                  <TableAction size='small' to={`${tableData?.view}/${row._id}`} title='View' type='view' />
                  <TableAction size='small' to={`${tableData?.edit}/${row._id}`} title='Edit' type='edit' />
                  <TableAction
                    size='small'
                    title='Delete'
                    message={`Are you sure you want to delete this Department : ${selectedRow?.firstName || selectedRow?.name}?`}
                    row={row}
                    onClick={() => { setSelectedRow(row) }}
                    onSuccess={confirmationDeleteSubmit}
                    type='delete'
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableWrapper>
      </Wrapper>
    </PostLogin>
  )
}

DepartmentListPage.propTypes = {
  setAgentDataPage: PropTypes.func,
  setAgentDataLimit: PropTypes.func,
  agentDataPage: PropTypes.func,
  agentDataLimit: PropTypes.func
}

DepartmentListPage.displayName = 'ListPage'

export default DepartmentListPage
