import { CheckBox, Add } from '@mui/icons-material'
import { styled, TableContainer, TableCell, TableHead, TableRow, Paper, Box, Typography } from '@mui/material'
import { TableWrapper } from '../../../components'

export const Checkboxed = styled(CheckBox)(`
  padding: 0px;
`)

export const TableWrapperStyled = styled(TableWrapper)(props => `
  ${props?.theme?.animate && `opacity: ${props?.loader ? '0' : '1'}`};
  transition: all .5s;
`)

export const TableContainerStyled = styled(TableContainer)(`
  padding-top: 18px;
`)

export const TableTitleWrapper = styled(Box)(`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 10px 0px 20px;
  padding: 7px 0px 20px;
  border: 0;
  background: none;
`)

export const StyledAdd = styled(Add)(`
  line-height: 1;
  font-size: 15px;
  font-weight: 400;
`)

export const TableTitle = styled(Typography)(`
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
`)

export const Upside = styled(Typography)(`
  text-transform: capitaliz;
  display: flex;
  align-items: center;
`)

export const TableHeadStyled = styled(TableHead)(`
  background: white;
`)

export const TableRowStyled = styled(TableRow)(`
  &:nth-of-type(even){
    background: rgba(0,0,0,.04);
  }
  &:last-child{
    >td{
      border-bottom: none !important;
    }
  }
`)

export const PaperStyled = styled(Paper)(`
  border-radius: 10px;
  padding: 20px;
`)

export const Cell = styled(TableCell)(props => `
  text-align: left;
  background: none !important;
  border-bottom: ${props?.borWid ? props?.borWid : '1px'} solid #dee2e6 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding-top: 8px;
  padding-right: 12px;
  // padding-bottom: 8px;
  padding-bottom: 12px;
  padding-left: 12px;
`)
