import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import {
  Box,
  Typography
} from '@mui/material'

export const ListTitle = styled(Typography)(({ theme }) => `
  font-size: 11px;
  margin-top: 20px;
  text-transform: uppercase;
  padding-left: 0;
  color: ${theme?.palette?.secondary?.main};
`)

export const IconBox = styled(Box)(({ theme }) => `
  padding: 5px;
  margin-left: -5px;
  margin-right: 10px;
  display: flex;
`)

export const ListWrapper = styled(Box)(({ theme }) => `
  // padding: 0px 4px;
  width: 100%;
  display: flex;
  flex-direction: column;

  position:relative;
`)

const styling = (theme) => `
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  text-transform: capitalize;
  transition: all .4s ease;
  color: #4d5052;
  font-size: 14px;
  line-height: calc(21 / 14);
  border-right: 2px solid transparent;
  font-weight: 400;

  &.active,
  &.active:hover,
  &:hover {
    letter-spacing: 1px;
    border-radius: 0;
    border-right: 2px solid ${theme?.palette?.primary?.main};
    color: ${theme.palette.primary.main};
  }
  &.active{
    letter-spacing: 0px;
    font-weight: 600;
  }
`

export const Accordion = styled(Box)(({ theme }) => `
  ${styling(theme)}
  padding: 10px 0;
  padding-left: 30px;
`)

export const LinkWrapper = styled(Box)(`
  display: flex;
  flex-direction: column;
  position: relative;
  &:last-child{
    margin-bottom: 20px;
  }
    
  & .add-button {
    display: flex;
    position: absolute;
    right: -25px;
    top:-7px;
    transition: inherit;
  }
  &:hover .add-button {
    right: 8px;
  }
`)

export const LinkStyled = styled(Link)(({ theme }) => `
  ${styling(theme)}
  padding: 2.5px 0;
  overflow: hidden;
  &.active {
    &:hover {
      > a {
        display: none;
      }
    }
  }

  // &:last-child{
  //   margin-bottom: 20px;
  // }
`)
