import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PostLogin from '../../../layout/post-login'
import TaskService from '../../../services/task.service'
import { ROUTES, COMMON } from '../../../constants'
import { useMuiSnackbar } from '../../../hooks'
import {
  Title,
  MuiDatePicker,
  MuiSelect,
  MuiTextField,
  MuiButton
} from '../../../components/'
import { Grid } from '@mui/material'
import { Form, GridWrapper } from './style'
import { ButtonWrapper } from '../../common/style'

const TaskAddPage = () => {
  const form = useRef()
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()
  const [isLoading, setIsLoading] = useState(true)
  const [mode, setMode] = useState('add')
  const [assignedTo, setAssignedTo] = useState([])
  const [initialVal, setInitialVal] = useState({
    taskName: '',
    description: '',
    assignedTo: '',
    dueDate: '',
    dueTime: '',
    status: ''
  })
  const history = useNavigate()
  const { id } = useParams()

  const timeRange = [
    {
      label: '5 mins',
      value: '5mins'
    },
    {
      label: '10 mins',
      value: '10mins'
    },
    {
      label: '15 mins',
      value: '15mins'
    },
    {
      label: '30 mins',
      value: '30mins'
    },
    {
      label: '1 hour',
      value: '1hours'
    },
    {
      label: '2 hours',
      value: '2hours'
    }
  ]

  const validation = (values) => {
    const errors = {}
    if (!values.taskName) {
      errors.taskName = 'Kindly Enter Your - taskName'
    }
    return errors
  }

  const onSubmits = (data) => {
    setIsLoading(true)
    const api = mode === 'add'
      ? TaskService.addTask(data)
      : TaskService.updateTask(data, id)
    api
      .then((resp) => {
        history(ROUTES.ROUTE_TASK_LIST)
        SnackbarSuccess('Task', mode)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: initialVal,
    validationSchema: Yup.object({
      taskName: Yup.string().max(25).required(),
      description: Yup.string().max(50),
      assignedTo: Yup.string().max(25).required(),
      dueDate: Yup.string().max(30).required(),
      dueTime: Yup.string().max(30).required()/* .matches(COMMON.VALIDATE_TIME) */,
      remindBefore: Yup.string().max(30),
      relatedTo: Yup.string().max(30),
      status: Yup.string().max(30).required()
    }),
    validate: values => validation(values),
    onSubmit: values => {
      const updatedData = { ...values }
      onSubmits(updatedData)
    }
  })

  useEffect(() => {
    TaskService.getEmployees()
      .then((resp) => {
        setAssignedTo(
          resp?.data?.map((d) => {
            return {
              label: d?.firstName || d?.name,
              value: d?._id
            }
          })
        )
      })
      .catch((error) => {
        SnackbarError(error)
      })
  }, [])

  useEffect(() => {
    if (id?.length) {
      setMode('edit')
      TaskService.getOneTask(id)
        .then((resp) => {
          setIsLoading(false)
          setInitialVal(
            {
              taskName: resp?.data?.taskName,
              description: resp?.data?.description,
              assignedTo: resp?.data?.assignedTo?._id,
              dueDate: resp?.data?.dueDate,
              dueTime: resp?.data?.dueTime,
              remindBefore: resp?.data?.remindBefore,
              relatedTo: resp?.data?.relatedTo?._id,
              status: resp?.data?.status
            }
          )
          // enqueueSnackbar('Task fetched successfully', {
          //   variant: 'success'
          // })
        })
        .catch((error) => {
          SnackbarError(error)
        })
        .finally(() => {
          setIsLoading(false)
        })

      formik.initialValues = initialVal
    } else setIsLoading(false)
  }, [])

  return (
    <PostLogin heading='Task' fixed>
      <Title
        primaryButton={{
          title: 'back',
          to: ROUTES.ROUTE_TASK_LIST,
          size: 'small'
        }}
        // tabs={[
        //   {
        //     label: 'List',
        //     to: ROUTES.ROUTE_TASK_LIST
        //   },
        //   {
        //     label: 'Add New',
        //     active: true,
        //     to: ''
        //   }
        // ]}
      />

      <Form onSubmit={formik.handleSubmit} ref={form}>
        <GridWrapper container>
          <Grid item xs={6}>
            <MuiTextField
              required
              error={Boolean(
                formik.touched.taskName && formik.errors.taskName
              )}
              fullWidth
              size='small'
              helperText={formik.touched.taskName && formik.errors.taskName}
              margin='normal'
              autoFocus
              name='taskName'
              placeholder='Task Name'
              title='Task Name'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.taskName}
              variant='outlined'
            />
          </Grid>

          <Grid item xs={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.description && formik.errors.description
              )}
              fullWidth
              size='small'
              helperText={formik.touched.description && formik.errors.description}
              margin='normal'
              name='description'
              placeholder='Description'
              title='Description'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.description}
              variant='outlined'
            />
          </Grid>

          <Grid item xs={4}>
            <MuiSelect
              required
              name='assignedTo'
              placeholder={'Assigned To'}
              title='Assigned To'
              onChange={formik.handleChange}
              fullWidth
              size='small'
              data={assignedTo}
              selectlabel='label'
              selectvalue='value'
              error={formik.errors.assignedTo}
              value={formik.values.assignedTo || ''}
            />
          </Grid>

          <Grid item xs={4}>
            <MuiDatePicker
              // error={Boolean(formik.touched.dueDate && formik.errors.dueDate)}
              fullWidth
              name='dueDate'
              value={formik?.values?.dueDate ? new Date(formik.values.dueDate) : ''}
              // onChange={formik.handleChange}
              onChange={(value) => formik.setFieldValue('dueDate', value.toISOString())}
              placeholder='Due Date'
              title='Due Date'
              label=''
            />
          </Grid>

          <Grid item xs={4}>
            <MuiSelect
              data={timeRange}
              fullWidth
              margin='normal'
              type='text'
              variant='outlined'
              selectlabel='label'
              selectvalue='value'
              required
              error={Boolean(formik.touched.dueTime && formik.errors.dueTime)}
              helperText={formik.touched.dueTime && formik.errors.dueTime}
              onChange={formik.handleChange}
              placeholder='Due Time'
              title='Due Time'
              name='dueTime'
              value={formik.values.dueTime}
              size='small'
              onBlur={formik.handleBlur}
            />
          </Grid>

          <Grid item xs={4}>
            <MuiDatePicker
              error={Boolean(
                formik.touched.remindBefore && formik.errors.remindBefore
              )}
              fullWidth
              name='remindBefore'
              value={formik?.values?.remindBefore ? new Date(formik.values.remindBefore) : ''}
              // onChange={formik.handleChange}
              onChange={(value) => formik.setFieldValue('remindBefore', value.toISOString())}
              placeholder='Remind Before'
              title='Remind Before'
              label=''
              helperText={formik.touched.remindBefore && formik.errors.remindBefore}
              margin='normal'
              onBlur={formik.handleBlur}
              type='text'
              variant='outlined'
            />
          </Grid>

          <Grid item xs={4}>
            <MuiSelect
              data={assignedTo}
              error={Boolean(
                formik.touched.relatedTo && formik.errors.relatedTo
              )}
              fullWidth
              size='small'
              helperText={formik.touched.relatedTo && formik.errors.relatedTo}
              margin='normal'
              name='relatedTo'
              placeholder='Related To'
              title='Related To'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.relatedTo}
              variant='outlined'
              selectlabel='label'
              selectvalue='value'
            />
          </Grid>

          <Grid item xs={4}>
            <MuiSelect
              required
              name='status'
              placeholder={formik.values.status || 'Status'}
              title='Status'
              onChange={formik.handleChange}
              fullWidth
              size='small'
              data={COMMON.TASK_STATUS}
              selectlabel='label'
              selectvalue='value'
              error={formik.errors.status}
              value={formik.values.status || ''}
            />
          </Grid>

        </GridWrapper>
        <ButtonWrapper>
          <MuiButton
            variant='contained'
            size='small'
            type='submit'
            disabled={isLoading}
            onClick={() => formik.handleSubmit()}
          >
            {id?.length ? 'Update' : 'Add'}
          </MuiButton>
        </ButtonWrapper>
      </Form>

    </PostLogin>
  )
}

export default TaskAddPage
