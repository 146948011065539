import React from 'react'
import { Radio, FormControlLabel } from '@mui/material'
import PropTypes from 'prop-types'
import { FormLabel, FormError, RadioGroupWrap, RadioWrapper } from './style'

const MuiRadio = (props) => {
  return (
    <RadioWrapper>
      {props?.title && <FormLabel>{props.title}</FormLabel>}
      {props?.options && <RadioButton {...props} />}
      {props?.error && <FormError>{props?.error}</FormError>}
    </RadioWrapper>
  )
}

export default MuiRadio

const RadioButton = (props) => {
  const {
    options,
    name,
    onChange,
    selectlabel,
    selectvalue,
    size,
    defaultValue
  } = props

  const handleChange = (event) => {
    const selectedData = { value: event.target.value, label: event.target.name }
    onChange(event, selectedData)
  }

  const defaultVal = options[0][selectvalue || 'value']

  return (
    <RadioGroupWrap row {...props} name={name} onChange={handleChange} size={size} defaultValue={defaultValue || defaultVal}>
      {options.map((s, i) =>
        <FormControlLabel key={i} value={(s.value) || s[selectvalue]} control={<Radio />} label={(s.label) || s[selectlabel]} />
      )}
    </RadioGroupWrap>
  )
}

MuiRadio.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
  error: PropTypes.string
}

RadioButton.propTypes = {
  options: PropTypes.array, // need to complete
  name: PropTypes.string, // need to complete
  onChange: PropTypes.func, // need to complete
  selectlabel: PropTypes.string, // need to complete
  selectvalue: PropTypes.string, // need to complete
  size: PropTypes.string, // need to complete
  defaultValue: PropTypes.string // need to complete
}
