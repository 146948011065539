
// import axios from 'axios'
import { postLogin } from '../util/axios'

const API_URL = `${process.env.REACT_APP_API_URL}`
const apiName = 'candidates'

const getCandidateList = (data) => {
  const url = `${API_URL}${apiName}?type=${data.candidateType}&department=${data.department}&job=${data.job}`
  return postLogin.get(url, data)
}

const getOneCandidate = (id) => {
  const url = `${API_URL}${apiName}/${id}`
  return postLogin.get(url)
}

const addCandidate = (data) => {
  const url = `${API_URL}${apiName}/`
  return postLogin.post(url, data)
}

const updateCandidate = (data, id) => {
  const url = `${API_URL}${apiName}/${id}`
  return postLogin.put(url, data)
}

const getJobOpening = (id) => {
  const url = `${API_URL}${apiName}/jobs/${id}`
  return postLogin.get(url)
}

const getAllDepartments = (data) => {
  const url = `${API_URL}${apiName}/departments`
  return postLogin.get(url, data)
}

const dropdownUpdate = (data, id) => {
  const url = `${API_URL}${apiName}/dropdown/${id}`
  return postLogin.put(url, data)
}

const scheduleInterview = (data, id) => {
  const url = `${API_URL}${apiName}/interview/${id}`
  return postLogin.put(url, data)
}

const interviewFeedback = (data, id) => {
  const url = `${API_URL}${apiName}/interview-feedback/${id}`
  return postLogin.put(url, data)
}

const getStages = (data) => {
  const url = `${API_URL}${apiName}/stages`
  return postLogin.get(url, data)
}

const deleteCandidate = (id) => {
  const url = `${API_URL}${apiName}/${id}`
  return postLogin.delete(url)
}

export default {
  getCandidateList,
  getJobOpening,
  getStages,
  getAllDepartments,
  addCandidate,
  getOneCandidate,
  updateCandidate,
  dropdownUpdate,
  deleteCandidate,
  scheduleInterview,
  interviewFeedback
}
