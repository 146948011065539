import { styled, Box } from '@mui/material'

export const ButtonWrapper = styled(Box)(props => ({
  ...props?.theme?.components?.FormSubmitWrapper
}))

export const Wrapper = styled(Box)(props => `
  position: relative;
  width: 100%;
  min-height: calc(100vh - 170px);
  transition: all .5s;
  ${props?.theme?.animate && `opacity: ${props?.loader ? '0' : '1'}`};
`)
