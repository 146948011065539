import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMuiSnackbar } from '../../../hooks'
import PostLogin from '../../../layout/post-login'
import ReasonService from '../../../services/reason.service'
// import { ROUTES } from '../../../constants'
import { TableCell, TableRow } from '@mui/material'
import { Title, TableWrapper, TableAction, Loader } from '../../../components/'
import ReasonAddPage from '../add/'
import { Wrapper } from '../../common/style'

const ReasonListPage = () => {
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()
  const [gridLoader, setGridLoader] = useState(true)
  const [selectedRow, setSelectedRow] = useState({})
  // const [openConfirmation, setConfirmation] = useState(false)
  const [reasonFilter, setReasonFilter] = useState(null)
  const [reasonData, setReasonData] = useState([])
  const [reasonTabs, setReasonTabs] = useState([])

  const tableData = {
    title: 'Reason list',
    cols: [
      { title: 'description' }
    ],
    rows: reasonData,
    srNo: true
  }

  const reasonFilterHandler = (id) => {
    setReasonFilter(id)
    setReasonTabs(
      prev => prev.map((d) => {
        return {
          ...d,
          active: !!(id === (d?.value))
        }
      })
    )
  }

  const loadReasonData = () => {
    ReasonService?.getReasonList()
      .then(resp => {
        setReasonData(resp?.data)
        const typeArr = []
        const menu = []
        for (let m = 0; m < resp?.data?.length; m++) {
          const d = resp?.data[m]
          if (typeArr.indexOf(d.type) === -1) {
            typeArr.push(d.type.trim())
            menu.push({
              label: d.type,
              value: d.type.trim(),
              onClick: () => { reasonFilterHandler(d.type.trim()) }
            })
          }
        }

        setReasonTabs(menu)
        reasonFilterHandler(!reasonFilter ? menu[0].value : reasonFilter)
      })
      .catch(err => {
        SnackbarError(err)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }

  const reasonEditHandler = (row) => {
    setSelectedRow(row)
  }

  const confirmationDeleteSubmit = () => {
    ReasonService?.deleteReason(selectedRow?._id)
      .then(response => {
        loadReasonData()
        SnackbarSuccess(selectedRow?.description, 'deleted')
      })
      .catch((err) => {
        SnackbarError(err)
      })
  }

  useEffect(() => {
    loadReasonData()
  }, [])

  return (
    <PostLogin heading='Reason' fixed>
      <Title
        tabs={reasonTabs}
        // primaryButton={{
        //   startAdornment: <StyledAdd />,
        //   title: 'add',
        //   to: ROUTES.ROUTE_REASON_FORM,
        //   size: 'small'
        // }}
      />
      <Loader showLoader={gridLoader} animate/>
      <Wrapper loader={gridLoader}>
        {reasonFilter && <ReasonAddPage reasonType={reasonFilter} onCancel={() => { setSelectedRow({}) }} onSuccess={loadReasonData} editData={selectedRow} />}
        <TableWrapper data={tableData} loader={gridLoader}>
          {tableData.rows.filter(r => r.type === reasonFilter).map((row, i) => {
            return (
              <TableRow hover role='checkbox' key={row?._id}>
                <TableCell align='center'>{i + 1}</TableCell>
                <TableCell>{row?.description || '-'}</TableCell>
                <TableCell align='center'>
                  <TableAction size='small' onClick={() => { reasonEditHandler(row) }} title='Edit' type='edit' />
                  <TableAction
                    size='small'
                    title='Delete'
                    message={`Are you sure you want to delete this Reason : ${selectedRow?.description}?`}
                    row={row}
                    onClick={() => { setSelectedRow(row) }}
                    onSuccess={confirmationDeleteSubmit}
                    type='delete'
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableWrapper>
      </Wrapper>
    </PostLogin>
  )
}

ReasonListPage.propTypes = {
  setAgentDataPage: PropTypes.func,
  setAgentDataLimit: PropTypes.func,
  agentDataPage: PropTypes.func,
  agentDataLimit: PropTypes.func
}

ReasonListPage.displayName = 'ListPage'

export default ReasonListPage
