import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ListItem, Divider, ListItemText, ListItemAvatar, Avatar, Typography, Chip } from '@mui/material'
import { useMuiSnackbar } from '../../../hooks'
import PostLogin from '../../../layout/post-login'
import InboxService from '../../../services/inbox.service'
import { ROUTES } from '../../../constants'
import { Title, Loader } from '../../../components/'
import { stringToColor, timeStampToDate } from '../../../util'
import departmentService from '../../../services/department.service'
import InboxViewPage from '../view'
import InboxAddPage from '../add'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Wrapper } from '../../common/style'
import { TableOuterWrapper, TableWrapperList, TableWrapperDetail, InboxListWrapper, ListItemTextWrap } from './style'

const InboxListPage = () => {
  const { SnackbarError } = useMuiSnackbar()
  const [gridLoader, setGridLoader] = useState(true)
  const [inboxData, setInboxData] = useState([])
  const [inboxViewData, setInboxViewData] = useState(null)
  const [departmentFilter, setDepartmentFilter] = useState(localStorage.getItem('inbox-department-filter'))
  const [departmentTabs, setDepartmentTabs] = useState([])

  const filterFunc = (arr) => {
    const filtered = arr.filter(f => departmentFilter !== 'all' ? f?.department?._id === departmentFilter : true)
    return filtered
  }

  const tableData = {
    view: ROUTES.ROUTE_INBOX_VIEW,
    edit: ROUTES.ROUTE_INBOX_FORM,
    cols: [
      { title: 'name' },
      { title: 'order' },
      { title: 'description' }
    ],
    rows: filterFunc(inboxData),
    srNo: true
  }

  const departmentFilterHandler = (id) => {
    setDepartmentFilter(id)
    setDepartmentTabs(
      prev => prev.map((d) => {
        setInboxViewData(null)
        return {
          ...d,
          active: !!(id === (d?.value || 'all'))
        }
      })
    )
    localStorage.setItem('inbox-department-filter', id)
  }

  const loadInboxData = (e) => {
    InboxService.getInboxList()
      .then(response => {
        // const finalData = response?.data.map((d) => {
        //   const tp = (new Date(d.emailDate).getTime() / 1000)
        //   return { ...d, timestamp: tp }
        // }).sort((a, b) => a.timestamp - b.timestamp)
        // console.log('getInboxList >> finalData', finalData)

        setInboxData(response?.data)
      })
      .catch(err => {
        SnackbarError(err)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }

  // const confirmationDeleteSubmit = () => {
  //   InboxService.deleteInbox(selectedRow?._id)
  //     .then(response => {
  //       loadInboxData()
  //       SnackbarSuccess(selectedRow?.firstName || selectedRow?.name, 'deleted')
  //     })
  //     .catch((err) => {
  //       SnackbarError(err)
  //     })
  // }

  useEffect(() => {
    departmentService.getDepartmentList()
      .then((resp) => {
        if (resp.data.length === 0) {
          SnackbarError('There are no Departments')
          return
        }
        const menu = resp.data.map((d, i) => {
          return {
            label: d.name,
            value: d._id,
            onClick: () => { departmentFilterHandler(d._id) }
          }
        })
        setDepartmentTabs(menu)
        departmentFilterHandler(!departmentFilter ? resp.data[0]?._id : departmentFilter)
      })
      .catch((error) => {
        SnackbarError(error)
      })
  }, [])

  useEffect(() => {
    loadInboxData()
  }, [])

  return (
    <PostLogin heading='Inbox' fixed>
      <Title
        tabs={departmentTabs}
        restbutton={<InboxAddPage onSuccess={loadInboxData} />}
      />
      <Loader showLoader={gridLoader} animate />
      <Wrapper loader={gridLoader}>
        <TableOuterWrapper>
          <TableWrapperList>
            <InboxList data={tableData.rows} setInboxViewData={setInboxViewData} inboxViewData={inboxViewData} />
            {inboxViewData && <TableWrapperDetail><InboxViewPage data={inboxViewData} /></TableWrapperDetail>}
          </TableWrapperList>
        </TableOuterWrapper>
      </Wrapper>
    </PostLogin >
  )
}

InboxListPage.propTypes = {
  setAgentDataPage: PropTypes.func,
  setAgentDataLimit: PropTypes.func,
  agentDataPage: PropTypes.func,
  agentDataLimit: PropTypes.func
}

InboxListPage.displayName = 'ListPage'

export default InboxListPage

const InboxList = ({ data, inboxViewData, setInboxViewData }) => {
  return (
    <InboxListWrapper sx={{ bgcolor: 'background.paper' }}>
      {data.map((row, i) => {
        return (
          <React.Fragment key={i}>
            <ListItem alignItems='flex-start' onClick={() => setInboxViewData(row)} className={`${row._id === inboxViewData?._id ? 'active' : 'inactive'}`}>
              <ListItemAvatar>
                <Avatar alt={row?.emailTo || 'n'} sx={{ bgcolor: stringToColor(row?.emailTo + row._id || row._id) }} src='/static/images/avatar/1.jpg' />
              </ListItemAvatar>
              {/* <ListDate></ListDate> */}
              <ListItemText
                primary={`${row?.emailTo} - (${timeStampToDate(row?.emailDate)})`}
                secondary={
                  <ListItemTextWrap>
                    <Typography sx={{ display: 'inline' }} component='span' variant='body2' color='text.primary'>
                      {row?.emailSubject || 'Subject: will come here'}
                    </Typography>
                    <Chip className='chip' variant="outlined" color="primary" size="small" label={row?.emailType || 'manual....'} />
                  </ListItemTextWrap>
                }
              />
              <KeyboardArrowRightIcon color='disabled' />
            </ListItem>
            {i < (data.length - 1) && <Divider variant='inset' component='li' />}
          </React.Fragment>
        )
      })}

    </InboxListWrapper>
  )
}

InboxList.propTypes = {
  data: PropTypes.object,
  inboxViewData: PropTypes.object,
  setInboxViewData: PropTypes.func
}
