import React from 'react'
import PostLogin from '../../layout/post-login'
// import { useSnackbar } from 'notistack'
import {
  // Stats,
  Title
} from '../../components/'
import { useGlobalState } from '../../hooks'
// import PaymentService from '../../services/payment.service'
import AuthService from '../../services/auth.service'
import { ROUTES } from '../../constants'
// import { Wrapper } from './style'

const Dashboard = () => {
  const { user } = useGlobalState()
  const currentUser = AuthService.getCurrentUser()
  // const statsData = [
  //   {
  //     title: 'total Jobs',
  //     count: 120
  //   },
  //   {
  //     title: 'new Jobs',
  //     count: 120
  //   },
  //   {
  //     title: 'Dashboard',
  //     count: 120
  //   },
  //   {
  //     title: 'Female Jobs',
  //     count: 120
  //   }
  // ]

  const hour = new Date().getHours()
  const welcomeTypes = ['Good morning', 'Good afternoon', 'Good evening']
  const welcomeText = (hour < 12)
    ? welcomeTypes[0]
    : (hour < 18)
        ? welcomeTypes[1]
        : welcomeTypes[2]
  return (
    <PostLogin heading='ATS Dashboard' fixed>
      <Title
        heading={
          {
            title: `${welcomeText} ${user?.name || user?.firstName || currentUser?.name || currentUser?.firstName || 'Unauthorized!'}!`,
            subtitle: 'measure How Fast You\'re Growing Monthly Recurring Revenue.',
            to: ROUTES.ROUTE_JOB_LIST,
            link: 'learn More'
          }
        }
      />
      {/* <Stats data={statsData} /> */}
    </PostLogin>
  )
}

export default Dashboard
