// import axios from 'axios'
import { postLogin } from '../util/axios'

const API_URL = `${process.env.REACT_APP_API_URL}departments`

const getDepartmentList = (id) => {
  const url = `${API_URL}`
  return postLogin.get(url)
}

const getOneDepartment = (id) => {
  const url = `${API_URL}/${id}`
  return postLogin.get(url)
}

const addDepartment = (data) => {
  const url = `${API_URL}/`
  return postLogin.post(url, data)
}

const updateDepartment = (data, id) => {
  const url = `${API_URL}/${id}`
  return postLogin.put(url, data)
}

const deleteDepartment = (id) => {
  const url = `${API_URL}/${id}`
  return postLogin.delete(url)
}

export default {
  getDepartmentList,
  addDepartment,
  getOneDepartment,
  updateDepartment,
  deleteDepartment
}
