
// import axios from 'axios'
import { postLogin } from '../util/axios'

const API_URL = `${process.env.REACT_APP_API_URL}`

const getTutorialList = (id) => {
  const url = `${API_URL}tutorials`
  return postLogin.get(url)
}

const getOneTutorial = (id) => {
  const url = `${API_URL}tutorials/${id}`
  return postLogin.get(url)
}

const addTutorial = (data) => {
  const url = `${API_URL}tutorials/`
  return postLogin.post(url, data)
}

const updateTutorial = (data, id) => {
  const url = `${API_URL}tutorials/${id}`
  return postLogin.put(url, data)
}

const deleteTutorial = (id) => {
  const url = `${API_URL}tutorials/${id}`
  return postLogin.delete(url)
}

export default {
  getTutorialList,
  addTutorial,
  getOneTutorial,
  updateTutorial,
  deleteTutorial
}
