import React from 'react'
import PropTypes from 'prop-types'
import Fade from '@mui/material/Fade'
import { DropBox } from './style'

const Dropdown = props => {
  const [anchorEl, setAnchorEl] = React.useState(props?.pos)
  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
    props?.onClose()
  }

  React.useEffect(() => {
    setAnchorEl(props?.pos)
  }, [props?.pos])

  return (
    <DropBox
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id="fade-menu"
      MenuListProps={{
        'aria-labelledby': 'fade-button'
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      {props?.children}
    </DropBox>
  )
}

Dropdown.propTypes = {
  onClose: PropTypes.func,
  pos: PropTypes.string,
  open: PropTypes.bool,
  children: PropTypes.node
}

export default Dropdown
