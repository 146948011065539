import { styled } from '@mui/material/styles'
import { Box, IconButton, Typography } from '@mui/material'
import { Settings } from '@mui/icons-material/'
import { Link } from 'react-router-dom'

export const LhsWrapper = styled(Box)(props => `
  transition: ease-in-out .5s;
  background: inherit;
  display: flex;
  // flex-direction: column;
  height: 100%;
`)

export const IconButtonStyled = styled(IconButton)(({ theme }) => `
  background: ${theme.palette.primary.main};
  padding: 9px;
  &:hover{
    opacity: .8;
    background: ${theme.palette.primary.main} !important;
  }
`)

export const SettingsAnim = styled(Settings)(`
  @keyframes spinFull {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  &.spinForever{
    animation: spinFull 4s infinite linear;
  }
`)

export const LogoTitle = styled(Typography)(`
  font-weight: 500;
  font-size: 20px;
  line-height: calc(24 / 20);

  margin-top: -2px;
  margin-bottom:10px;
  font-size: 16px;
  line-height: 16px;
`)

export const SideBarContainer = styled(Box)(`
  padding: 0;
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
`)

export const ItemsContainer = styled(Box)(`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`)

export const LogoWrapper = styled(Box)(`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 17px 0;
`)

export const SideBarWrapper = styled(Box)(({ theme }) => `
  width: ${theme?.components?.MuiSideBar?.side};
  background: inherit;
  z-index: 5;
  height: 100%;
`)

export const ItemsWrapper = styled(Box)(({ theme }) => `
  transition: ease-in-out .5s;
  padding: 20px 0px 0px 75px;
  position: fixed;
  top: 0;
  left: 0;
  width: ${theme?.components?.MuiSideBar?.width};
  height: 100%;
`)

export const LowSide = styled(Box)(`
  overflow: auto;
  padding-top: 0px !important;
  &::-webkit-scrollbar{
    width: 0;
    opacity: 0;
  }
`)

export const LogoLinkStyled = styled(Link)(({ theme }) => `
  margin-top: 10px;
  margin-bottom: 15px;
`)

export const IconsWrapper = styled(Box)(`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  > button {
    // margin: 12px;
    // margin-bottom: 20px;
  }
`)

export const TitleBox = styled(Box)(`
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 8px;
`)
