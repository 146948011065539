import React from 'react'
import PropTypes from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Modal } from './style'

const MuiModal = ({
  title,
  onSubmit,
  submitMsg = 'Yes',
  cancelMsg = 'Cancel',
  onClose,
  open,
  width,
  onCancel,
  children
}) => {
  return (
    <Modal open={open} onClose={onClose} width={width}>
      <DialogTitle id='alert-dialog-title'>
        {title}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Modal>
  )
}

MuiModal.propTypes = {
  width: PropTypes.number || PropTypes.string,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  submitMsg: PropTypes.string,
  cancelMsg: PropTypes.string,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.node
}

export default MuiModal
