import { styled, Typography, Paper, Box, ListItemText } from '@mui/material'
import { Link } from 'react-router-dom'

export const NameCircle = styled(Box)(props => `
  display: flex;
  border-radius: 50%;
  font-size: 30px;
  color: white;
  background: ${props?.theme?.palette?.primary?.main};
  padding: 20px;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  text-transform: uppercase;
`)

export const CardActions = styled(Box)(props => `
  display: flex;
  align-items: center;
  transition: ease-in-out .5s;
  button,
  svg {
    transition: inherit;
  }
`)

export const CardHead = styled(Box)(props => `
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  margin-top: 5px;
`)

export const CardTitle = styled(Typography)(props => `
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2;
`)

export const CardTableWrapper = styled(Box)(props => `
  width: 100%;
  border: 1px solid #e6e9ed;
  border-radius: 4px;
`)

export const CardTableItem = styled(Box)(props => `
  display: flex;
  justify-content:space-between;
  padding: 12px 20px;
  border-bottom: 1px solid #e6e9ed;
  &:last-child {
    border-bottom: none;
  }
  
  & > div {
    flex:auto;
    min-width:50%;
  }

`)

export const CardTableContent = styled(ListItemText)(props => `
  text-transform: capitalize;
  > span {
    position: relative;
    width: fit-content;
    color: #6c757d;
    font-size: 12.8px;
    font-weight: 400;
    &:after {
      position: absolute;
      content: ':';
      right: -5px;
    }
  }
  > p {
    font-size: 16px;
    color: ${props?.theme?.palette?.primary?.main};
    font-weight: 400;
    line-height: 1.5;
  }
`)

export const LinkStyled = styled(Link)(props => `
  text-decoration: none;
  color: inherit;
  border-bottom: ${props?.underline ? '1px' : '0'} solid ${props?.theme?.palette?.primary?.main};
  ${!props?.underline &&
  `&:hover{
      text-decoration: underline;
    }`
  }
`)

export const Name = styled(ListItemText)(props => `
  text-align: center;
  color: ${props?.theme?.palette?.primary?.main};
  font-size: 16px;
  > span {
    margin-top: 16px;
    font-weight: 500;
    line-height: 1.2;
  }
  > p {
    font-weight: 400;
    line-height: 1.5;
    > a {
      color: inherit;
    }
  }
`)

export const Card = styled(Paper)(props => `
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 20px;
  min-height: 50px;
  width: 100%;
  transition: ease-in-out .5s;
  margin-bottom: 16px;
  overflow: ${props?.collapse ? 'hidden' : 'auto'};

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track {
    opacity: 0;
    width: 0;
  }
  &.collapse {
    max-height: 800px;
  }
  &.collapse.true {
    max-height: 70px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`)

const styling = (props) => `
  display: flex;
  flex-direction: column;
  width: ${props?.width}%;
`
export const Lhs = styled(Box)(props => `
  ${styling(props)};
  margin-right: 16px;
`)

export const Rhs = styled(Box)(props => `
  ${styling(props)};
  position: relative;
  > div {
    position: absolute;
    top: 0;
    width: 100%;
    transition: ease-in-out .25s;
    visibility: hidden;
    opacity: 0;
    // height: calc(100vh - 165px);
    height: 100%;
    > div {
      overflow: auto;
      height: 100%;
    }
  }
  >.${props?.activeTab}{
    visibility: visible;
    opacity: 1;
  }
`)

export const TimelineWrapper = styled(Box)(props => `
  width: 90%;
  display: flex;
  flex-direction: column-reverse;
`)

export const TimelineList = styled(Box)(props => `
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #5a5278;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  padding: 20px;
  margin-left: 20px;
  &:after {
    content: '';
    position: absolute;
    background: #5a5278;
    width: 1px;
    height: calc(100% + 40px);
    left: 0;
    top: -20px;
    // transform: translate(-50%, 0);
  }
  &:before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #5a5278;
    position: absolute;
    top: 25px;
    left: -7.5px;
  }
`)

export const TimelineHeading = styled(Box)(props => `
`)

export const TimelineDescription = styled(Box)(props => `
`)

export const TimelineDate = styled(Box)(props => `
  font-size:13px;
`)

export const TimelineStage = styled(Box)(props => `
`)

export const TimelineIsActive = styled(Box)(props => `
`)

export const DescriptionWrapper = styled(Box)(`
  width: 100%;
  min-width:450px;
  & > div {
    padding:0;
  }
`)

export const EmailWrap = styled(Box)(`
  display:inline-grid;
`)
