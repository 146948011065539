
// import axios from 'axios'
import { postLogin } from '../util/axios'

const API_URL = `${process.env.REACT_APP_API_URL}`
const apiName = 'jobs'

const getJobList = (id) => {
  const url = `${API_URL}${apiName}`
  return postLogin.get(url)
}

const getOneJob = (id) => {
  const url = `${API_URL}${apiName}/${id}`
  return postLogin.get(url)
}

const addJob = (data) => {
  const url = `${API_URL}${apiName}/`
  return postLogin.post(url, data)
}

const dropdownUpdate = (data, id, type) => {
  const url = `${API_URL}${apiName}/dropdown/${id}?type=${type}`
  return postLogin.put(url, data)
}

const updateJob = (data, id) => {
  const url = `${API_URL}${apiName}/${id}`
  return postLogin.put(url, data)
}

const deleteJob = (id) => {
  const url = `${API_URL}${apiName}/${id}`
  return postLogin.delete(url)
}

export default {
  getJobList,
  addJob,
  getOneJob,
  dropdownUpdate,
  updateJob,
  deleteJob
}
