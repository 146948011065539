import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { SelectStyled, ListItem } from './style'

const TextDropdown = ({ data, value, onChange, ...props }) => {
  const [selectedVal, setSelectedVal] = useState(value || '')
  const handleChange = (event) => {
    setSelectedVal(event.target.value)
    onChange(event.target.value)
  }

  const finalData = []

  for (let i = 0; i < data.length; i++) {
    finalData?.push(data[i])
    for (let j = 0; j < data[i]?.options?.length; j++) {
      finalData.push(data[i]?.options[j])
    }
  }

  useEffect(() => {
    setSelectedVal(value)
  }, [value])

  return (
    <SelectStyled
      {...props}
      labelId="demo-select-small"
      id="demo-select-small"
      value={selectedVal}
      data={finalData}
      required
      compact
      size='small'
      onChange={handleChange}
    >
      {finalData?.map((d, i) => {
        const opt = d?.options
        const sub = d?.isSubStage
        return (
          <ListItem key={i} value={opt?.length > 0 ? '' : d?.value} className={`${!sub ? '' : 'l2'} ${opt?.length > 0 && 'disabled'}`}>
            {d?.label}
          </ListItem>
        )
      })}
    </SelectStyled>
  )
}

TextDropdown.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.string
}

export default TextDropdown
