import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ROUTES, COMMON } from '../../../constants'
import { TableCell, TableRow } from '@mui/material'
// import Checkbox from '@mui/material/Checkbox'
import TutorialService from '../../../services/tutorial.service'
import { useMuiSnackbar } from '../../../hooks'
import PostLogin from '../../../layout/post-login'
import { StyledAdd } from './style'
import {
  Title,
  Stats,
  MuiChip,
  TableWrapper,
  TableAction,
  Loader
} from '../../../components/'
import { Wrapper } from '../../common/style'

const TutorialListPage = () => {
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()

  const [gridLoader, setGridLoader] = useState(true)
  const [selectedRow, setSelectedRow] = useState({})
  // const [openConfirmation, setConfirmation] = useState(false)

  const [tutorialData, setTutorialData] = useState([])

  const statsData = [
    {
      title: 'total tutorials',
      count: tutorialData?.length
    },
    {
      title: 'published tutorials',
      count: tutorialData.filter(d => d.published).length
    },
    {
      title: 'Dashboard',
      count: 120,
      to: ROUTES.ROUTE_DASHBOARD
    },
    {
      title: 'Female tutorials',
      count: 120
    }
  ]

  const tableData = {
    title: 'tutorial list',
    view: ROUTES.ROUTE_TUTORIAL_VIEW,
    edit: ROUTES.ROUTE_TUTORIAL_FORM,
    cols: [
      { title: 'title' },
      { title: 'DESCRIPTION' },
      { title: 'PUBLISHED' }
    ],
    rows: tutorialData
  }

  const loadTutorialData = () => {
    TutorialService.getTutorialList()
      .then(response => {
        setTutorialData(response?.data?.reverse())
      })
      .catch(err => {
        SnackbarError(err)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }

  const confirmationDeleteSubmit = () => {
    TutorialService.deleteTutorial(selectedRow?._id)
      .then(response => {
        loadTutorialData()
        SnackbarSuccess(selectedRow?.title || selectedRow?.name, 'deleted')
      })
      .catch((err) => {
        SnackbarError(err)
      })
  }

  useEffect(() => {
    loadTutorialData()
  }, [])

  return (
    <PostLogin heading='Tutorial' fixed>
      <Title
        tabs={[
          {
            label: 'List',
            active: true,
            to: ''
          },
          {
            label: 'Add New',
            to: ROUTES.ROUTE_TUTORIAL_FORM
          }
        ]}
        primaryButton={{
          startAdornment: <StyledAdd />,
          title: 'add',
          to: ROUTES.ROUTE_TUTORIAL_FORM,
          size: 'small'
        }}
      />

      <Loader showLoader={gridLoader} animate/>
      <Wrapper loader={gridLoader}>
        <Stats data={statsData} loader={gridLoader} />
        <TableWrapper data={tableData} loader={gridLoader}>
          {tableData.rows.map((row, i) => {
            row.published = row.published.toString()
            return (
              <TableRow hover role='checkbox' key={row?._id}>
                <TableCell>{row?.title}</TableCell>
                <TableCell>{row?.description}</TableCell>
                <TableCell>
                  <MuiChip
                    label={
                      COMMON?.VAL_TO_LABEL('BOOLEAN', row, 'published')
                    }
                    size='small'
                    color={row?.published.toString() === 'true' ? 'success' : row?.published.toString() === 'false' ? 'error' : 'primary'}
                  />
                </TableCell>
                <TableCell align='right'>
                  <TableAction size='small' to={`${tableData?.view}/${row._id}`} title='View' type='view' />
                  <TableAction size='small' to={`${tableData?.edit}/${row._id}`} title='Edit' type='edit' />
                  <TableAction
                    size='small'
                    title='Delete'
                    message={`Are you sure you want to delete this Tutorial : ${selectedRow?.title || selectedRow?.name}?`}
                    row={row}
                    onClick={() => { setSelectedRow(row) }}
                    onSuccess={confirmationDeleteSubmit}
                    type='delete'
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableWrapper>
      </Wrapper>
    </PostLogin>
  )
}

TutorialListPage.propTypes = {
  setAgentDataPage: PropTypes.func,
  setAgentDataLimit: PropTypes.func,
  agentDataPage: PropTypes.func,
  agentDataLimit: PropTypes.func
}

TutorialListPage.displayName = 'Tutorial List'

export default TutorialListPage
