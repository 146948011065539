import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import PostLogin from '../../../layout/post-login'
import { ROUTES, COMMON } from '../../../constants'
import { useMuiSnackbar } from '../../../hooks'
import EmployeeService from '../../../services/employee.service'
import { Title, TableWrapper } from '../../../components'
import { ListItemTextStyled } from './style'

const EmployeeViewPage = () => {
  const [employeeData, setEmployeeData] = useState({})
  const { id } = useParams()
  const [gridLoader, setGridLoader] = useState(true)
  const { SnackbarError } = useMuiSnackbar()

  const tableData = {
    title: 'employee view',
    rows: employeeData
  }

  useEffect(() => {
    EmployeeService.getOneEmployee(id)
      .then((resp) => {
        setEmployeeData(resp?.data)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setGridLoader(false)
      })
  }, [])

  return (
    <PostLogin heading='Employee View' fixed>
      <Title
        primaryButton={{
          title: 'back',
          to: ROUTES.ROUTE_EMPLOYEE_LIST,
          size: 'small'
        }}
      />

      <TableWrapper data={tableData} loader={gridLoader}>
        {(employeeData && !gridLoader) && <Grid container spacing={2}>
          {employeeData?.firstName && <Grid item md={4}>
            <ListItemTextStyled
              primary='First Name'
              secondary={employeeData?.firstName}
            />
          </Grid>}
          {employeeData?.name && <Grid item md={4}>
            <ListItemTextStyled
              primary='Name'
              secondary={employeeData?.name}
            />
          </Grid>}
          {employeeData?.lastName && <Grid item md={4}>
            <ListItemTextStyled
              primary='Last Name'
              secondary={employeeData?.lastName}
            />
          </Grid>}
          <Grid item md={4}>
            <ListItemTextStyled
              primary='Email'
              secondary={employeeData?.email}
            />
          </Grid>
          <Grid item md={4}>
            <ListItemTextStyled
              primary='Role'
              secondary={COMMON?.VAL_TO_LABEL('ROLE', employeeData, 'role')}
            />
          </Grid>
        </Grid>}
      </TableWrapper>

    </PostLogin>
  )
}

export default EmployeeViewPage
