import React from 'react'
import { Chip } from '@mui/material'
import moment from 'moment'
import cookie from 'js-cookie'

export const toLocaleString = num => {
  return !isNaN(num) ? Number(num)?.toLocaleString('en-ID') : 0
}

export const customDate = dat => {
  return moment(new Date(dat)).format('DD/MM/YYYY')
}

export const paymentStatusFormat = status => {
  if (status === 'COMPLETE') { return (<Chip label={status} size='small' color='success' />) } else if (status === 'WAITING_PAYMENT') { return (<Chip label={status} size='small' color='warning' />) } else if (status === 'PAYMENT_DETECTED') { return (<Chip label={status} size='small' color='primary' />) } else if (status === 'EXPIRED') { return (<Chip label={status} size='small' color='error' />) } else if (status === 'STATIC_TRX_EXPIRED') { return (<Chip label={status} size='small' color='error' />) } else { return status }
}

export const policyStatusFormat = (status) => {
  if (status === 'SUCCESS') { return (<Chip label={status} size='small' color='success' />) } else { return status }
}

export const stringToColor = (string) => {
  let hash = 0
  let color = '#'
  let i
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  return color
}

export const setCurrency = (val) => {
  return val.toLocaleString('en-US', { currency: 'USD' })
}

export const timeStampToDate = (str) => {
  return moment(str).format('MMMM DD, YYYY')
}

export const removeRememberMe = () => {
  cookie.remove('userEmail')
  cookie.remove('userPassword')
}

export const rememberMe = (data) => {
  cookie.set('userEmail', data.email)
  cookie.set('userPassword', data.password)
}

export const getRememberUser = () => {
  const userName = cookie.get('userEmail')
  const password = cookie.get('userPassword')
  return ({
    userName,
    password
  })
}

export const removeUserDetails = () => {
  window.localStorage.removeItem('user')
}
