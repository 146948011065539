export const ROUTE_INDEX = '/'
export const ROUTE_LOGIN = '/login'
export const ROUTE_SIGNUP = '/signup'
export const ROUTE_FORGOT_PASSWORD = '/forgotpassword'
export const ROUTE_NOTFOUND = '/404'
export const ROUTE_SERVER = '/500'
export const ROUTE_NOTFOUND_404 = '/*'

export const ROUTE_DASHBOARD = '/dashboard'

export const ROUTE_TODO_LIST = '/todos/list'
export const ROUTE_TODO_FORM = '/todos/form'

export const ROUTE_EMPLOYEE_LIST = '/employees/list'
export const ROUTE_EMPLOYEE_FORM = '/employees/form'
export const ROUTE_EMPLOYEE_VIEW = '/employees/view'

export const ROUTE_TUTORIAL_LIST = '/tutorials/list'
export const ROUTE_TUTORIAL_FORM = '/tutorials/form'
export const ROUTE_TUTORIAL_VIEW = '/tutorials/view'

export const ROUTE_TASK_LIST = '/tasks/list'
export const ROUTE_TASK_FORM = '/tasks/form'
export const ROUTE_TASK_VIEW = '/tasks/view'

export const ROUTE_DEPARTMENT_LIST = '/departments/list'
export const ROUTE_DEPARTMENT_FORM = '/departments/form'
export const ROUTE_DEPARTMENT_VIEW = '/departments/view'

export const ROUTE_STAGE_LIST = '/stages/list'
export const ROUTE_STAGE_FORM = '/stages/form'
export const ROUTE_STAGE_VIEW = '/stages/view'

export const ROUTE_LOCATION_LIST = '/locations/list'
export const ROUTE_LOCATION_FORM = '/locations/form'
export const ROUTE_LOCATION_VIEW = '/locations/view'

export const ROUTE_JOB_LIST = '/jobs/list'
export const ROUTE_JOB_FORM = '/jobs/form'
export const ROUTE_JOB_VIEW = '/jobs/view'

export const ROUTE_CANDIDATE_LIST = '/candidates/list'
export const ROUTE_CANDIDATE_FORM = '/candidates/form'
export const ROUTE_CANDIDATE_VIEW = '/candidates/view'

export const ROUTE_INBOX_LIST = '/inbox/list'
export const ROUTE_INBOX_FORM = '/inbox/form'
export const ROUTE_INBOX_VIEW = '/inbox/view'

export const ROUTE_REASON_LIST = '/reasons/list'
export const ROUTE_REASON_FORM = '/reasons/form'

export const ROUTE_COMPANY_FORM = '/company/form'
