import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import AuthService from '../../../../services/auth.service'
import EventBus from '../../../../common/EventBus'
import NavItem from '../nav-items/nav-item'
import { theme } from '../../../../theme'
import {
  COMMON
} from '../../../../constants'
import {
  KeyboardCommandKey,
  SubjectOutlined
} from '@mui/icons-material/'

import {
  Box,
  Drawer,
  IconButton,
  useMediaQuery
} from '@mui/material'

import {
  LhsWrapper,
  TitleBox,
  SideBarContainer,
  LogoWrapper,
  ItemsContainer,
  SideBarWrapper,
  IconsWrapper,
  SettingsAnim,
  IconButtonStyled,
  ItemsWrapper,
  LogoLinkStyled,
  LogoTitle,
  LowSide
} from './style'

const items = COMMON.LHS_ITEMS

const Lhs = (props) => {
  const { open, onClose } = props
  const { enqueueSnackbar } = useSnackbar()
  const logOut = () => {
    AuthService.logout()
    enqueueSnackbar('You have logged out', { variant: 'success' })
  }

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  })

  const rootClasslist = document.getElementById('root').classList

  const collapseSidebar = () => {
    rootClasslist.contains('close') ? rootClasslist.remove('close') : rootClasslist.add('close')
    localStorage.setItem('lhs-hidden', rootClasslist.contains('close'))
  }

  const buttonVariant = () => {
    const holder = localStorage.getItem('button-style')
    localStorage.setItem('button-style', holder === 'gradient' ? 'solid' : 'gradient')
    localStorage.setItem('theme', !(localStorage.getItem('theme')) * 1)
  }

  JSON.parse(localStorage?.getItem('lhs-hidden'))
    ? rootClasslist.add('close')
    : rootClasslist.remove('close')

  useEffect(() => {
    // const user = AuthService.getCurrentUser()
    // if (user) {
    //   setShowAdminBoard(user.roles.includes('ROLE_ADMIN'));
    // }

    EventBus.on('logout', () => {
      logOut()
    })

    return () => {
      EventBus.remove('logout')
    }
  }, [])

  const content = (
    <LhsWrapper className='lhs-content' fixed>
      <SideBarWrapper>
        <SideBarContainer>
          <Box>
            <LogoWrapper>
              <LogoLinkStyled to='/dashboard'>
                <IconButtonStyled>
                  <KeyboardCommandKey sx={{
                    color: 'background.paper',
                    fontSize: '18px'
                  }} />
                </IconButtonStyled>
              </LogoLinkStyled>
            </LogoWrapper>
          </Box>
          <Box>
            <IconsWrapper>
              <IconButton onClick={buttonVariant} style={{ display: 'none' }}>
                <SettingsAnim className='spinForever' color='primary' />
              </IconButton>
              <IconButton onClick={collapseSidebar}>
                <SubjectOutlined color='primary' />
              </IconButton>
            </IconsWrapper>
          </Box>
        </SideBarContainer>
      </SideBarWrapper>

      <ItemsWrapper className='lhs-items'>
        <ItemsContainer>
          <TitleBox >
            {/* <LogoTitle>Applicant Tracking System</LogoTitle> */}
            <LogoTitle>Applicant Tracking System</LogoTitle>
          </TitleBox>
          <LowSide sx={{ py: 3 }}>
            <NavItem data={items} addHover />
          </LowSide>
        </ItemsContainer>
      </ItemsWrapper>
    </LhsWrapper >
  )
  if (lgUp) {
    return (
      <Drawer
        anchor='left'
        open
        className='lhs-inner-wrapper'
        PaperProps={{
          sx: {
            // backgroundColor: 'primary.contrastText',
            width: theme?.components?.MuiSideBar?.width || 280
          }
        }}
        variant='permanent'>
        {content}
      </Drawer>
    )
  }
  return (
    <Drawer
      className='lhs'
      anchor='left'
      onClose={
        onClose
      }
      open={
        open
      }
      PaperProps={{
        sx: {
          // backgroundColor: 'primary.main',
          // color: '#FFFFFF',
          width: theme?.components?.MuiSideBar?.width || 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant='temporary'
    >
      {content}
    </Drawer>
  )
}

Lhs.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

Lhs.displayName = 'Lhs'

export default Lhs
