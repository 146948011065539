import { styled, ListItemText, Grid } from '@mui/material'

export const ListItemTextStyled = styled(ListItemText)(`
> span {
  text-transform: capitalize;
  position: relative;
  width: fit-content;
  &:before{
    content:': ';
    position: absolute;
    right: -5px;
    top: 0;
  }
}
`)

export const InboxWrapper = styled(Grid)(`
  margin-left:15px;
  width:calc(100% - 15px);
  overflow-y:auto;
  height:100%;
  flex-direction:column;
  flex-wrap:nowrap;
`)

export const InboxHeading = styled(Grid)(`
  display:flex;
  width:100%;
  & > div.MuiListItemAvatar-root {
    padding-top: 10px;
  }
  & svg {
    display: flex;
    align-self: center;
  }
`)

export const InboxSubject = styled(Grid)(`
  width:100%;
  border-bottom:1px #f1f1f1 solid;
  padding:5px 0;
`)

export const InboxBody = styled(Grid)(`
  width:100%;
  padding:20px 0;
`)
