import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const FormLabel = styled('label')(`
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  color: #2c2945;
`)

export const RteWrapper = styled(Box)(props => `
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  border: ${props?.errorflag ? `1px solid ${props?.theme?.palette?.error?.main};` : ''};
  border-radius: 6px;
  & .tox-statusbar__text-container {
    display: none !important;
    opacity: 0 !important;
  }
  & .tox-tinymce {
    border-radius: 4px;
    border: 1px #E6E8F0 solid;
  }
`)

export const FormReq = styled('span')(`
  font-size: 12px;
  color: #DA2A52;
  padding-left:5px;
`)

export const FormError = styled(Box)(`
  width:100%;
  display:block;
  line-height: 20px;

  font-size: 0.75rem;
  font-weight: 400;
  margin: 4px 14px 0 14px;
  color: #D14343;
  background-color: transparent !important;
`)
