import React from 'react'
import PropTypes from 'prop-types'
import { Dialog as Modal } from '@mui/material'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

const Confirmation = ({
  title,
  onSubmit,
  submitMsg = 'Yes',
  cancelMsg = 'Cancel',
  onClose,
  onCancel,
  ...rest
}) => {
  return (
    <Modal {...rest} >
      <DialogTitle id='alert-dialog-title'>
        Confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {title}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel || onClose}
        >
          {cancelMsg}
        </Button>
        <Button
          color='error'
          variant='contained'
          onClick={onSubmit}
          autoFocus
        >
          {submitMsg}
        </Button>

      </DialogActions>
    </Modal>
  )
}

Confirmation.propTypes = {
  title: PropTypes.string, // need to complete
  onSubmit: PropTypes.func, // need to complete
  submitMsg: PropTypes.string, // need to complete
  cancelMsg: PropTypes.string, // need to complete
  onClose: PropTypes.func, // need to complete
  onCancel: PropTypes.func // need to complete
}

export default Confirmation
