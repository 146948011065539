import { CheckBox, Add } from '@mui/icons-material'
import { styled, TableContainer, TableCell, TableHead, TableRow, Paper, Box, Typography, List } from '@mui/material'
// import { TableWrapper } from '../../../components'

export const Checkboxed = styled(CheckBox)(`
  padding: 0px;
`)

export const TableOuterWrapper = styled(Box)(`
  display:flex;
  justify-content:space-between;
`)

export const TableWrapperList = styled(Box)(props => `
  ${props?.theme?.animate && `opacity: ${props?.loader ? '0' : '1'}`};
  background-color: #FFFFFF;
  color: #121828;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
  border-radius: 10px;
  padding: 20px;
  transition: all .5s;
  width:100%;
  height: calc(100vh - 180px);
  display:flex;
`)

export const TableWrapperDetail = styled(Box)(props => `
  min-width:calc(100% - 480px);
  height: calc(100vh - 220px);
  border-left:1px #f1f1f1 solid;
`)

export const TableContainerStyled = styled(TableContainer)(`
  padding-top: 18px;
`)

export const TableTitleWrapper = styled(Box)(`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 0px 20px;
  border: 0;
  background: none;
`)

export const StyledAdd = styled(Add)(`
  line-height: 1;
  font-size: 15px;
  font-weight: 400;
`)

export const TableTitle = styled(Typography)(`
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
`)

export const Upside = styled(Typography)(`
  text-transform: capitaliz;
  display: flex;
  align-items: center;
`)

export const TableHeadStyled = styled(TableHead)(`
  background: white;
`)

export const TableRowStyled = styled(TableRow)(`
  &:nth-of-type(even){
    background: rgba(0,0,0,.04);
  }
  &:last-child{
    >td{
      border-bottom: none !important;
    }
  }
`)

export const PaperStyled = styled(Paper)(`
  border-radius: 10px;
  padding: 20px;
`)

export const Cell = styled(TableCell)(props => `
  text-align: left;
  background: none !important;
  border-bottom: ${props?.borWid ? props?.borWid : '1px'} solid #dee2e6 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding:8px 12px 12px 12px;
`)

export const InboxListWrapper = styled(List)(`
  overflow:auto;
  min-width:480px;
  width:100%;
  height:100%;
  padding:0;
  & li {
    position:relative;
    cursor:pointer;
  }
  & li.active {
    background:#f1f1f1;
  }
  & span.MuiListItemText-primary {
    font-size:13px;
  }
  & li svg {
    display: flex;
    align-self: center;
  }
  & .chip {
    line-height:1;
    height:20px;
  }
`)

export const ListItemWrapper = styled(Box)(`
`)

export const ListDate = styled(Typography)(`
  font-size:13px;
`)

export const ListItemTextWrap = styled(Box)(`
  display:flex;
  flex-wrap:wrap;
  & > span {
    width:100%;
    padding-bottom:5px;
  }
`)
