import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DashboardNavbar } from './components/dashboard-navbar/dashboard-navbar'
import Lhs from './components/lhs/'
import {
  PostLoginOuterWrapper,
  LhsWrapper,
  RhsWrapper,
  ContentWrapper
} from './style'

const PostLogin = ({
  children,
  sidefixed = false,
  heading,
  breadcrumbs,
  fixed = true
}) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true)
  // const rootClasslist = document.getElementById('root').classList

  return (
    <PostLoginOuterWrapper className='postlogin-wrapper'>
      <LhsWrapper className={`lhs-wrapper ${sidefixed ? 'fixed' : ''}`}>
        <Lhs
          onClose={() => {
            setSidebarOpen(false)
            localStorage?.setItem('lhs-hidden', 'true')
          }}
          open={isSidebarOpen}
        />
        {/* {(!JSON.parse(localStorage.getItem('lhs-hidden')) && sidefixed) &&
          <Box
            sx={{
              position: 'fixed',
              top: '0',
              left: '0',
              height: '100vh',
              width: '100vw',
              background: 'black',
              zIndex: '1150'
            }}
            onClick={() => {
              rootClasslist.add('close')
              localStorage.setItem('lhs-hidden', 'true')
            }}
          />
        } */}
      </LhsWrapper>
      <RhsWrapper className='rhs-wrapper'>
        <DashboardNavbar heading={heading} breadcrumbs={breadcrumbs} fixed={fixed} />
        <ContentWrapper>
          {children}
        </ContentWrapper>
      </RhsWrapper>
    </PostLoginOuterWrapper>
  )
}

PostLogin.propTypes = {
  heading: PropTypes.string || PropTypes.node,
  sidefixed: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  fixed: PropTypes.bool,
  children: PropTypes.node
}

PostLogin.displayName = 'PostLogin'

export default PostLogin
