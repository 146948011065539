import React, { useState, useRef, useEffect } from 'react'
import * as Yup from 'yup'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { CANDIDATE_TYPE } from '../../../constants/common'
import InboxService from '../../../services/inbox.service'
import departmentService from '../../../services/department.service'
// import AuthService from '../../../services/department.service'
import jobService from '../../../services/job.service'
import EmployeeService from '../../../services/employee.service'
import candidateService from '../../../services/candidate.service'
import { useMuiSnackbar, useGlobalState } from '../../../hooks'
import { MuiTextField, MuiButton, MuiSelect, Rte, MuiModal } from '../../../components'
import { Form, GridWrapper } from './style'
import { ButtonWrapper } from '../../common/style'

const InboxAddPage = ({ onSuccess, data = {}, buttonType = 'default', showOnload = false, onClose }) => {
  const form = useRef()
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()
  const [departmentData, setDepartmentData] = useState([])
  const [jobData, setJobData] = useState([])
  const [jobFilteredData, setJobFilteredData] = useState([])
  const [candidateData, setCandidateData] = useState([])
  const [selectedCandidate, setSelectedCandidate] = useState()
  // const [isLoading, setIsLoading] = useState(!showOnload)
  const [closeButton, setCloseButton] = useState(true)
  const [addPopup, setAddPopup] = useState(showOnload)
  const [emailBodyData, setEmailBodyData] = useState()
  const [signature, setSignature] = useState()
  // const currentUser = AuthService.getCurrentUser()
  const { user } = useGlobalState()
  // const [byPass, setByPass] = useState(!showOnload)
  const byPass = !showOnload
  // const [addPopup, setAddPopup] = useState(showOnload)
  // const [mode, setMode] = useState('add')
  // const [initialVal, setInitialVal] = useState({
  //   emailSubject: '',
  //   emailTo: '',
  //   department: showOnload ? data?.departmentId?._id : '',
  //   job: showOnload ? data?.jobId?._id : '',
  //   candidate: showOnload ? (data?.candidateId?._id || data?._id) : ''
  // })
  const initialVal = {
    emailSubject: '',
    emailTo: '',
    department: showOnload ? data?.departmentId?._id : '',
    job: showOnload ? data?.jobId?._id : '',
    candidate: showOnload ? (data?.candidateId?._id || data?._id) : ''
  }

  const validation = (values) => {
    const errors = {}
    return errors
  }

  const onSubmits = (data) => {
    // setIsLoading(true)
    InboxService.addInbox(data)
      .then((resp) => {
        SnackbarSuccess('Inbox', 'add')
        setCloseButton(false)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        // setIsLoading(false)
      })
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: initialVal,
    validationSchema: Yup.object({
      emailSubject: Yup.string().max(50).required(),
      emailTo: Yup.string().max(50).required(),
      department: Yup.string().required(),
      job: Yup.string().required(),
      candidate: Yup.string().required()
    }),
    // validate: values => validation(values),
    validate: values => { validation({ ...values, emailBody: emailBodyData }) },
    onSubmit: values => {
      const updatedData = { ...values, emailBody: emailBodyData, emailDate: new Date(), emailType: 'manual' }
      onSubmits(updatedData)
      onSuccess('inbox reloaded')
    }
  })

  // useEffect(() => {
  //   if (id?.length) {
  //     setMode('edit')
  //     InboxService.getOneInbox(id)
  //       .then((resp) => {
  //         setInitialVal(
  //           {
  //             emailSubject: resp?.data?.emailSubject,
  //             emailTo: resp?.data?.emailTo,
  //             emailBody: resp?.data?.emailBody || '',
  //             department: resp?.data?.department,
  //             job: resp?.data?.job,
  //             candidate: resp?.data?.candidate
  //           }
  //         )
  //       })
  //       .catch((error) => {
  //         SnackbarError(error)
  //       })
  //       .finally(() => {
  //         setIsLoading(false)
  //       })

  //     formik.initialValues = initialVal
  //   } else setIsLoading(false)
  // }, [])

  useEffect(() => {
    if (byPass) {
      if (departmentData?.length === 0) {
        departmentService.getDepartmentList()
          .then((resp) => {
            if (resp.data.length === 0) {
              SnackbarError('There are no Departments')
              return
            }
            setDepartmentData(resp.data)
          })
          .catch((error) => {
            SnackbarError(error)
          })
      }
    }
  }, [departmentData])

  useEffect(() => {
    if (byPass) {
      if (!!formik?.values?.department && jobData?.length === 0) {
        jobService.getJobList()
          .then((resp) => {
            if (resp.data.length === 0) {
              SnackbarError('There are no Jobs')
              return
            }
            setJobData(resp.data)
            setJobFilteredData(resp.data.filter(f => f.department._id === formik?.values?.department))
          })
          .catch((error) => {
            SnackbarError(error)
          })
      }
      if (!!formik?.values?.department && jobData?.length > 0) {
        setJobFilteredData(jobData.filter(f => f.department._id === formik?.values?.department))
      }
    }
  }, [formik?.values?.department])

  useEffect(() => {
    if (byPass) {
      if (!!formik?.values?.job && candidateData?.length === 0) {
        const data = {
          candidateType: CANDIDATE_TYPE[0].value,
          department: formik?.values?.department,
          job: formik?.values?.job
        }
        candidateService.getCandidateList(data)
          .then((resp) => {
            if (resp.data.length === 0) {
              SnackbarError('There are no Candidates')
              return
            }
            setCandidateData(resp.data)
          })
          .catch((error) => {
            SnackbarError(error)
          })
      }
    }
  }, [formik?.values?.job])

  useEffect(() => {
    EmployeeService.getOneEmployee(user?.id)
      .then((resp) => {
        setSignature(resp.data?.signature)
      })
      .catch((error) => {
        SnackbarError(error)
      })
  }, [])

  useEffect(() => {
    const template = data => `
      <p>Hi ${data.firstName} ${data.lastName},
        <br/><br/><br/><br/>
        ${data?.signature}
      </p>
    `
    if (byPass) {
      if (!!formik?.values?.candidate && jobData?.length > 0) {
        const selCandidate = candidateData.find((c) => c._id === formik.values.candidate)
        formik.setFieldValue('emailTo', selCandidate.email)
        setSelectedCandidate(selCandidate)
        selCandidate.signature = signature
        setEmailBodyData(template(selCandidate))
      }
    } else {
      formik.setFieldValue('emailTo', data.email)
      setSelectedCandidate(data)
      setEmailBodyData(template(data))
    }
  }, [formik?.values?.candidate])

  const closeModalFunc = () => {
    if (byPass) {
      setAddPopup(false)
    } else {
      onClose()
    }
    // setCloseButton(false)
  }

  return (
    <>
      {!showOnload && buttonType === 'default' && <MuiButton variant='contained' size='small' onClick={() => setAddPopup(true)}>Compose mail</MuiButton >}
      <MuiModal
        title='Compose mail & send to candidate.'
        onClose={() => closeModalFunc()}
        open={addPopup} width={800}
      >
        <Form onSubmit={formik.handleSubmit} ref={form}>
          {closeButton && <>
            <GridWrapper container>
              <Grid item md={12}>
                <MuiTextField
                  error={Boolean(
                    formik.touched.emailSubject && formik.errors.emailSubject
                  )}
                  autoFocus
                  fullWidth
                  size='small'
                  required
                  // helperText={formik.touched.emailSubject && formik.errors.emailSubject}
                  margin='normal'
                  name='emailSubject'
                  placeholder='Email Subject'
                  title='Email Subject'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type='text'
                  value={formik.values.emailSubject}
                  variant='outlined'
                />
              </Grid>

              {byPass && <>
                <Grid item md={6}>
                  <MuiSelect
                    error={Boolean(
                      formik.touched.department && formik.errors.department
                    )}
                    size='small'
                    required
                    // helperText={formik.touched.department && formik.errors.department}
                    data={departmentData}
                    onBlur={formik.handleBlur}
                    selectlabel='name'
                    selectvalue='_id'
                    onChange={formik.handleChange}
                    placeholder='Department'
                    title='Department'
                    name='department'
                    fullWidth
                    value={formik?.values?.department}
                  />
                </Grid>
                {formik?.values?.department && <Grid item md={6}>
                  <MuiSelect
                    error={Boolean(
                      formik.touched.job && formik.errors.job
                    )}
                    size='small'
                    required
                    // helperText={formik.touched.job && formik.errors.job}
                    data={jobFilteredData}
                    onBlur={formik.handleBlur}
                    selectlabel='heading'
                    selectvalue='_id'
                    onChange={formik.handleChange}
                    placeholder='Job'
                    title='Job'
                    name='job'
                    fullWidth
                    value={formik?.values?.job}
                  />
                </Grid>}
                {formik?.values?.department && formik?.values?.job && <Grid item md={6}>
                  <MuiSelect
                    error={Boolean(
                      formik.touched.candidate && formik.errors.candidate
                    )}
                    size='small'
                    required
                    // helperText={formik.touched.candidate && formik.errors.candidate}
                    data={candidateData}
                    onBlur={formik.handleBlur}
                    selectlabel='firstName'
                    selectvalue='_id'
                    onChange={formik.handleChange}
                    placeholder='Candidate'
                    title='Candidate'
                    name='candidate'
                    fullWidth
                    value={formik?.values?.candidate}
                  />
                </Grid>}
                {formik?.values?.candidate && selectedCandidate && <Grid item md={6}>
                  <MuiTextField
                    error={Boolean(
                      formik.touched.emailTo && formik.errors.emailTo
                    )}
                    autoFocus
                    fullWidth
                    size='small'
                    required
                    margin='normal'
                    name='emailTo'
                    placeholder='Email To'
                    title='Email To'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type='text'
                    value={formik.values.emailTo}
                    variant='outlined'
                  />
                </Grid>}
              </>}

              <Grid item md={12}>
                <Rte
                  required
                  error={formik.errors.emailBody}
                  title='Email Body Content'
                  placeholder='Email Body Content'
                  name='emailBody'
                  onBlur={formik.handleBlur}
                  value={emailBodyData}
                  onChange={(e) => setEmailBodyData(e)}
                  height={250}
                />
              </Grid>

            </GridWrapper>
            <ButtonWrapper>
              <MuiButton
                style={{ marginRight: '16px' }}
                variant='outlined'
                size='small'
                onClick={() => closeModalFunc()}
              >
                Close
              </MuiButton>
              <MuiButton
                variant='contained'
                size='small'
                type='submit'
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </MuiButton>
            </ButtonWrapper>
          </>}

          {!closeButton && <>
            <GridWrapper container>
              Thankyou email Successfuly sent to {formik?.values?.candidate || 'the candidate'} at {formik?.values?.emailTo}
            </GridWrapper>
            <ButtonWrapper>
              <MuiButton
                variant='contained'
                size='small'
                onClick={() => closeModalFunc()}
              >
                Close
              </MuiButton>
            </ButtonWrapper>
          </>}

        </Form>
      </MuiModal>
    </>
  )
}

InboxAddPage.propTypes = {
  data: PropTypes.object,
  buttonType: PropTypes.string,
  showOnload: PropTypes.booleon,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func
}

InboxAddPage.displayName = 'Inbox Add Page'

export default InboxAddPage
