import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { Grid } from '@mui/material'
import * as Yup from 'yup'
import PostLogin from '../../../layout/post-login'
import TutorialService from '../../../services/tutorial.service'
import { ROUTES, COMMON } from '../../../constants'
import { useMuiSnackbar } from '../../../hooks'
import {
  MuiTextField,
  MuiButton,
  Title,
  MuiRadio,
  MuiCheckbox,
  UploadFile,
  Rte
} from '../../../components/'
import { Form, GridWrapper } from './style'
import { ButtonWrapper } from '../../common/style'

const TutorialAddPage = () => {
  const form = useRef()
  const [mode, setMode] = useState('add')
  const [isLoading, setIsLoading] = useState(true)
  const [initialVal, setInitialVal] = useState({
    title: '',
    published: 'true'
  })
  const history = useNavigate()
  const { id } = useParams()
  const { SnackbarSuccess, SnackbarError } = useMuiSnackbar()

  const validation = (values) => {
    const errors = {}
    if (!values.title) {
      errors.title = 'Kindly Enter Your - title'
    }
    return errors
  }

  const onSubmits = (data) => {
    setIsLoading(true)
    const api = mode === 'add'
      ? TutorialService.addTutorial(data)
      : TutorialService.updateTutorial(data, id)
    api
      .then((resp) => {
        history(ROUTES.ROUTE_TUTORIAL_LIST)
        SnackbarSuccess('Tutorial', mode)
      })
      .catch((error) => {
        SnackbarError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: initialVal,
    validationSchema: Yup.object({
      title: Yup.string().max(25).required(),
      description: Yup.string().max(250),
      published: Yup.boolean()
    }),
    validate: values => { validation(values) },
    onSubmit: values => {
      const updatedData = { ...values }
      onSubmits(updatedData)
    }
  })

  const onChangeResume = (e) => {
    if (e?.details) {
      const data = {
        extension: e.details.mimetype,
        size: e.details.size,
        fileName: e.details.filename,
        originalFileName: e.details.originalname
      }
      formik.setFieldValue('resume', data)
    } else {
      formik.setFieldValue('resume', null)
    }
  }

  useEffect(() => {
    if (id?.length) {
      setMode('edit')
      TutorialService.getOneTutorial(id)
        .then((resp) => {
          setInitialVal(
            {
              title: resp.data.title,
              description: resp.data.description,
              published: resp.data.published
            }
          )
        })
        .catch((error) => {
          SnackbarError(error)
        })
        .finally(() => {
          setIsLoading(false)
        })

      formik.initialValues = initialVal
    } else setIsLoading(false)
  }, [])

  return (
    <PostLogin heading='Tutorial' fixed>
      <Title
        tabs={[
          {
            label: 'List',
            to: ROUTES.ROUTE_TUTORIAL_LIST
          },
          {
            label: 'Add New',
            active: true,
            to: ''
          }
        ]}
        primaryButton={{
          title: 'back',
          to: ROUTES.ROUTE_TUTORIAL_LIST,
          size: 'small'
        }}
      />
      {/* <MuiSnackbar open={snack.open} duration={5000} message={snack.message} variant={snack.variant} onClose={() => { setSnack({ open: false }) }} />
      <SnackbarSuccess name='tutorial' mode={mode} /> */}

      <Form onSubmit={formik.handleSubmit} ref={form}>
        <GridWrapper container>
          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.title && formik.errors.title
              )}
              fullWidth
              size='small'
              required
              helperText={formik.touched.title && formik.errors.title}
              margin='normal'
              name='title'
              placeholder='title'
              title='title'
              onBlur={formik.handleBlur}
              autoFocus
              onChange={formik.handleChange}
              // disabled={disabled}
              type='text'
              value={formik.values.title}
              variant='outlined'
            />
          </Grid>

          <Grid item md={6}>
            <MuiTextField
              error={Boolean(
                formik.touched.description && formik.errors.description
              )}
              fullWidth
              size='small'
              helperText={formik.touched.description && formik.errors.description}
              margin='normal'
              name='description'
              placeholder='description'
              title='description'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='text'
              value={formik.values.description}
              // disabled={disabled}
              variant='outlined'
            />
          </Grid>

          <Grid item md={4}>
            <MuiRadio
              error={Boolean(
                formik.touched.published && formik.errors.published
              )}
              helperText={formik.touched.published && formik.errors.published}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name='published'
              value={formik.values.published && formik.values.published}
              // disabled={disabled}
              options={COMMON.BOOLEAN}
            />
          </Grid>

          <Grid item md={4}>
            <MuiCheckbox
              error={Boolean(
                formik.touched.published && formik.errors.published
              )}
              label='Published'
              helperText={formik.touched.published && formik.errors.published}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name='published'
              value={formik.values.published && formik.values.published}
              options={COMMON.BOOLEAN}
            />
          </Grid>

          <Grid item md={4}>
            <UploadFile
              fullWidth
              size='small'
              margin='normal'
              name='resume'
              placeholder='Kindly upload a PDF file, first browse it and then upload the file to complete the process'
              onChange={(d) => onChangeResume(d)}
              type='text'
              error={
                formik.touched.resume && 'Resume is required!'
              }
              value={formik.values.resume}
              variant='outlined'
            />
          </Grid>

          <Grid item md={4}>
            <Rte
              title={'Heading'}
              value={formik.values.comments}
              onChange={formik.handleChange}
              // onChange={(e) => setHeadingValue(e)}
              height={170}
              customToolbar={'bold italic underline strikethrough | subscript superscript | removeformat undo redo | link'}
            />
          </Grid>
        </GridWrapper>
        <ButtonWrapper>
          <MuiButton
            variant='contained'
            size='small'
            type='submit'
            isSubmitting={isLoading}
            onClick={() => formik.handleSubmit()}
          >
            {id?.length ? 'Update' : 'Add'}
          </MuiButton>
        </ButtonWrapper>
      </Form>

    </PostLogin>
  )
}

export default TutorialAddPage
